import { roles } from 'projects/SFO/modules/auth/constants/roles.constants'

export const defaultSdkOptions = {
  promiseType: 'native',
  flow: 'hybrid'
}

export const airmapAuthScopes = {
  SFO: 'sfo'
}

export const airmapAuthStates = {
  IDLE: 'idle',
  INITIALIZED: 'initialized'
}

export const airmapAuthChangeStateEventKey = 'airmap-auth-change-state'
export const airmapAuthPermissionsChangedEventKey = 'airmap-auth-permissions-changed'

export const airmapAuthSessionStorageKeys = {
  TOKEN: 'airmap-auth-token',
  REFRESH_TOKEN: 'airmap-auth-refresh-token',
  CURRENT_ROLE: 'airmap-selected-role'
}

export const airmapAuthEvents = {
  TOKEN_HAS_EXPIRED: 'token-has-expired'
}

export const airmapAuthNonExpireTokens = [roles.CONTACT, roles.OBSERVER, roles.MONITOR]
