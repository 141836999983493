import { formatKeys } from './formatKeys.constants'

export const en = {
  [formatKeys.MONTH_DAY]: 'MMMM D',
  [formatKeys.TIME]: 'HH:mm',
  [formatKeys.TIME_WITH_TZ]: 'HH:mm Z',
  [formatKeys.DATE]: 'YYYY-MM-DD',
  [formatKeys.DATE_TIME]: 'MMMM Do, YYYY, H:mm',
  [formatKeys.DATE_TIME_WITHOUT_YEAR]: 'MMMM Do, HH:mm',
  [formatKeys.DATE_SLASHED]: 'YYYY/MM/DD',
  [formatKeys.DATE_SLASHED_TIME]: 'MM/DD/YYYY, HH:mm'
}
