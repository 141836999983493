import React from 'react'

const Drizzle = () => (
  <path
    fillRule="evenodd"
    d="M86.9451109,38.9996651 C84.0142225,38.9996651 81.0833333,39.3631256 78.3333333,40.2840558 C72.0975558,26.8458418 58.3475556,18 43.5,18 C22.2357778,18 5,35.3159442 5,56.6790697 C5,73.6192741 16.1808889,88.7298978 32.3191111,93.698009 C33.7882222,94.0639254 35.25,93.3345492 35.8048889,91.8561492 C36.1666667,90.3802044 35.4406667,88.9116281 33.9715556,88.3541584 C20.0284444,84.1252465 10.5,71.2322235 10.5,56.6790697 C10.5,38.4421953 25.3475556,23.5255814 43.5,23.5255814 C56.3333333,23.5255814 68.0691109,31.0747535 73.3882225,42.6833861 L74.4857775,44.888707 C75.0382225,46.1755535 76.5,46.7305674 77.7882225,46.367107 L80.1666667,45.627907 C82.3617775,44.888707 84.75,44.5252465 87.1382225,44.5252465 C99.6073333,44.5252465 109.690667,54.6554791 109.690667,67.1825861 C109.690667,79.7023254 99.4166667,89.8325584 86.9451109,89.8325584 C85.476,89.8325584 84.1951109,91.1169492 84.1951109,92.5953492 C84.1951109,94.0737492 85.476,95.3581392 86.9451109,95.3581392 C102.347556,95.3581392 115,82.6468465 115,67.1727628 C115,51.7109582 102.347556,38.9996651 86.9451109,38.9996651 Z M46.8,69.572093 L46.8,94.437209 C46.8,94.9885392 47.6829333,95.3581392 49,95.3581392 C50.3170667,95.3581392 51.2,94.9885392 51.2,94.437209 L51.2,69.572093 C51.2,69.0207628 50.3170667,68.6511628 49,68.6511628 C47.6829333,68.6511628 46.8,69.0207628 46.8,69.572093 Z M59.2666667,76.9054266 L59.2666667,101.770542 C59.2666667,102.321873 60.1496,102.691473 61.4666667,102.691473 C62.7837333,102.691473 63.6666667,102.321873 63.6666667,101.770542 L63.6666667,76.9054266 C63.6666667,76.3540963 62.7837333,75.9844963 61.4666667,75.9844963 C60.1496,75.9844963 59.2666667,76.3540963 59.2666667,76.9054266 Z"
  />
)

export default Drizzle
