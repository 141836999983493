export const controlsClassName = 'js-plugin-ctrl'

export const mapboxClassName = 'mapboxgl-ctrl'

export const mapThemes = {
  STANDARD: 'standard',
  LIGHT: 'light',
  DARK: 'dark',
  SATELLITE: 'satellite',
}

export const mapAllowedThemes = [mapThemes.STANDARD, mapThemes.LIGHT, mapThemes.DARK, mapThemes.SATELLITE]

export const controlPositions = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
}

export const mapAllowedControls = {
  NAVIGATION_CONTROL: 'NavigationControl',
  CHANGE_THEME_CONTROL: 'ChangeThemeControl',
  LAYERS_CONTROL: 'LayersControl',
  MEASURE_RULE_CONTROL: 'MeasureRuleControl',
  FIT_GEOMETRY_CONTROL: 'FitGeometryControl',
}

export const measureControlID = 'measure-rule-control'
export const measureControlMapThemeChangeID = 'measure-rule-control-map-theme-change'
export const measureControlEvent = new Event(measureControlID)
