import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const Filter = ({ color }) => {
  return (
    <svg className={icon} width="24px" height="24px" viewBox="0 0 24 24">
      <path
        d="M14,12 L14,19.88 C14.04,20.18 13.94,20.5 13.71,20.71 C13.32,21.1 12.69,21.1 12.3,20.71 L10.29,18.7 C10.06,18.47 9.96,18.16 10,17.87 L10,12 L9.97,12 L4.21,4.62 C3.87,4.19 3.95,3.56 4.38,3.22 C4.57,3.08 4.78,3 5,3 L5,3 L19,3 L19,3 C19.22,3 19.43,3.08 19.62,3.22 C20.05,3.56 20.13,4.19 19.79,4.62 L14.03,12 L14,12 Z"
        id="path-1"
        fill={color}
      />
    </svg>
  )
}

Filter.defaultProps = {
  color: 'currentColor'
}

Filter.propTypes = {
  color: PropTypes.string
}
