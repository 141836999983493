export function encodeJWT(JWT) {
  const splitedJWT = JWT.split('.')[1]

  let base64JWT = splitedJWT.replace(/-/g, '+').replace(/_/g, '/')

  const pad = base64JWT.length % 4

  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding')
    }
    base64JWT += new Array(5 - pad).join('=')
  }

  return base64JWT
}
