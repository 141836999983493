import moment from 'moment'

export const defaultTimeRangeFilterValues = {
  start: moment(),
  end: moment()
}

export const predefinedRanges = {
  NEXT_1H: 1,
  NEXT_4H: 4,
  NEXT_8H: 8,
  NEXT_12H: 12,
  CUSTOM: 'custom'
}
