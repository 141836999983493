import React from 'react'
import styles, {
  rectangleButton,
  rectangleButtonIcon,
  rectangleButtonDisabled,
  rectangleButtonIconWithMargin,
  rectangleButtonRightIcon,
  rectangleButtonFullWidth,
  boldText
} from './RectangleButton.module.scss'
import { buttonPropTypes, buttonDefaultPropTypes } from '../constants/buttons.prop-types'

export const RectangleButton = ({
  id,
  disabled,
  onClick,
  onMouseDown,
  buttonText,
  variant,
  icon,
  iconPosition,
  fullWidth,
  size,
  className,
  bold,
  intent
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={`
        ${rectangleButton}
        ${disabled ? rectangleButtonDisabled : ''}
        ${styles[`rectangle-button--${variant}`]}
        ${styles[`rectangle-button--${variant}-${intent}`]}
        ${styles[`rectangle-button--${size}`]}
        ${fullWidth ? rectangleButtonFullWidth : ''}
        ${iconPosition === 'right' ? rectangleButtonRightIcon : ''}
        ${bold ? boldText : ''}
        ${className}
      `}
    >
      {icon && (
        <span className={`${rectangleButtonIcon} ${buttonText ? rectangleButtonIconWithMargin : ''}`}>{icon}</span>
      )}
      <span>{buttonText}</span>
    </button>
  )
}

RectangleButton.defaultProps = buttonDefaultPropTypes

RectangleButton.propTypes = buttonPropTypes
