export const templateStatuses = {
  ACCEPTED: 'accepted',
  SUBMITTED: 'submitted',
  IN_PROGRESS: 'review_in_progress',
  WORK_IN_PROGRESS: 'work_in_progress',
  REJECTED: 'rejected',
  COMPLETED: 'completed',
  RESCINDED: 'rescinded',
  UNDER_CONSULTATION: 'under_consultation',
  UNDER_CONSULTATION_APPROVED: 'under_consultation_accepted',
  UNDER_CONSULTATION_REJECTED: 'under_consultation_rejected',
  EXPIRED: 'expired',
  CANCELLED: 'canceled',
  CLOSED: 'closed',
  TERMINATED: 'terminated',
} as const

export type TemplateStatus = typeof templateStatuses[keyof typeof templateStatuses]

export const readStatuses = {
  READ: 'read',
  UNREAD: 'unread',
  ALL: 'read,unread',
} as const

export const statusActions = {
  ACCEPT: 'accept',
  CANCEL: 'cancel',
  CLOSE: 'close',
  CONSULT: 'consult',
  POSTPONE: 'postpone',
  REJECT: 'reject',
  RESCIND: 'rescind',
  START_REVIEW: 'start_review',
  TERMINATE: 'terminate',
  ACCEPT_CONSULT: 'accept_consult',
  DENY_CONSULT: 'deny_consult',
} as const

export type StatusAction = typeof statusActions[keyof typeof statusActions]

export const statusTransitions = {
  [statusActions.ACCEPT]: templateStatuses.ACCEPTED,
  [statusActions.CANCEL]: templateStatuses.CANCELLED,
  [statusActions.CLOSE]: templateStatuses.CLOSED,
  [statusActions.CONSULT]: templateStatuses.UNDER_CONSULTATION,
  [statusActions.POSTPONE]: templateStatuses.IN_PROGRESS,
  [statusActions.REJECT]: templateStatuses.REJECTED,
  [statusActions.RESCIND]: templateStatuses.RESCINDED,
  [statusActions.START_REVIEW]: templateStatuses.IN_PROGRESS,
  [statusActions.TERMINATE]: templateStatuses.TERMINATED,
  [statusActions.ACCEPT_CONSULT]: templateStatuses.ACCEPTED,
  [statusActions.DENY_CONSULT]: templateStatuses.REJECTED,
} as const

export const templateCoordinationInformationAvailableStates = [templateStatuses.ACCEPTED, templateStatuses.RESCINDED]
export const planCoordinationInformationAvailableStates = [
  templateStatuses.ACCEPTED,
  templateStatuses.RESCINDED,
  templateStatuses.IN_PROGRESS,
]

export const forceProcessSwitchDisabledStates = [
  templateStatuses.RESCINDED,
  templateStatuses.ACCEPTED,
  templateStatuses.CLOSED,
  templateStatuses.TERMINATED,
  templateStatuses.REJECTED,
  templateStatuses.CANCELLED,
]

export const lateAnnouncementSwitchDisabledStates = [
  templateStatuses.RESCINDED,
  templateStatuses.CLOSED,
  templateStatuses.TERMINATED,
  templateStatuses.REJECTED,
  templateStatuses.CANCELLED,
]

// FIXME: Currently 'TERMINATED' is in Positive Notes due to the lack of a third color. Check VoteConfirmationComponent for more info.
export const positiveVotes = [templateStatuses.ACCEPTED, templateStatuses.IN_PROGRESS, templateStatuses.TERMINATED]

export const daysUntilHumanParse = 1

export const defaultDatesFormat = 'YYYY-MM-DD HH:mm'
export const isoDatesFormat = 'YYYY-MM-DD'

export const evaluationWorkflows = {
  NEED_MORE_INFO: 'need_more_info',
  DESCRIBE_PLAN_FLY: 'describe_plan_fly',
  FLY: 'fly',
  PLAN_FLY: 'plan_fly',
  AUTO_ACCEPTED: 'auto_accepted',
  NOT_APPLICABLE: 'not_applicable',
}

export const activityTypes = {
  COMMENT: 'comment',
  PRIVATE_COMMENT: 'private_comment',
  CONSULTATION_REQUEST: 'consultation_request',
  CONSULTATION_ACTION: 'consultation_action',
  MODIFICATION: 'modification',
  RECOMMENDATION: 'recommendation',
  ASSIGNMENT: 'assignment',
  INTERNAL_INSTRUCTION: 'internal_instruction',
  EXTERNAL_INSTRUCTION: 'external_instruction',
  STATE_TRANSITION: 'state_transition',
  CONSULTATION_UPDATE: 'consultation_update',
  CONSULTATION_CLOSURE: 'consultation_closure',
  UNASSIGNMENT: 'unassignment',
}
type ActivityTypeKeys = keyof typeof activityTypes
export type ActivityTypeValues = typeof activityTypes[ActivityTypeKeys]

export const chatActivityType = [activityTypes.COMMENT, activityTypes.PRIVATE_COMMENT]

export const reviewTypes = {
  REQUEST: 'request',
  CONSULTATION: 'consultation',
  ACTIVITY: 'activity',
}

export const allowedStatusesForFilteringDefault = [
  templateStatuses.SUBMITTED,
  templateStatuses.IN_PROGRESS,
  templateStatuses.UNDER_CONSULTATION,
  templateStatuses.ACCEPTED,
  templateStatuses.REJECTED,
  templateStatuses.RESCINDED,
  templateStatuses.CANCELLED,
  templateStatuses.TERMINATED,
]

export const preferences = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
} as const

export const allowedPreferencesForFilteringDefault = [
  preferences.A,
  preferences.B,
  preferences.C,
  preferences.D,
  preferences.E,
]

export const readStatesToFiltering = [readStatuses.READ, readStatuses.UNREAD]

export const restrictedStatusesForFilteringInTemplates = [templateStatuses.TERMINATED]

export const commentTypes = {
  PRIVATE: 'private',
  PUBLIC: 'public',
}

export const windowOfHoursToShowAcceptButton = 6

export const dateTimeFilteringKeys = {
  MAXIMUM: 'maximum',
  MINIMUM: 'minimum',
}

export const prioritiesList = {
  PRIORITY1: 'PRIO1',
  PRIORITY2: 'PRIO2',
  PRIORITY3: 'PRIO3',
  PRIORITY4: 'PRIO4',
} as const

export type PrioritiesListKey = keyof typeof prioritiesList
export type PrioritiesListValue = typeof prioritiesList[PrioritiesListKey]
