import { WEB_APP_PUBLIC_PATH, AIRMAP_API_KEY, AUTH_HOST, AUTH_REALM, AUTH0_CLIENT_ID, MAPBOX_ACCESS_TOKEN, AIRMAP_SERVICES_REGISTRY_URL } from "@env"

const { protocol, host } = window.location
const basePathURL = `${protocol}//${host}`
const publicPath = WEB_APP_PUBLIC_PATH || ''

export type Api = {
  version: {
    aircraft: string,
    pilot: string,
    flight: string,
    rules: string
  },
  config: {
    airmap: {
      api_key: string | null
    },
    auth0: {
      host: string,
      realm: string,
      client_id: string | null,
      callback_url: string
    },
    mapbox: {
      access_token: string | null
    }
  },
  protocols: {
    HTTPS: string,
    WSS: string
  },
  services: {
    API: string,
    AUTH: string,
    MQTT: string
  },
  airmapServices: {
    registry: string
  }
}

const api: Api = {
  version: {
    aircraft: 'v2',
    pilot: 'v2',
    flight: 'v2',
    rules: 'v2'
  },
  config: {
    airmap: {
      api_key: AIRMAP_API_KEY || null
    },
    auth0: {
      host: AUTH_HOST,
      realm: AUTH_REALM || 'airmap',
      client_id: AUTH0_CLIENT_ID || null,
      callback_url: basePathURL + publicPath
    },
    mapbox: {
      access_token: MAPBOX_ACCESS_TOKEN || null
    }
  },
  protocols: {
    HTTPS: 'https',
    WSS: 'wss'
  },
  services: {
    API: 'api',
    AUTH: 'auth',
    MQTT: 'mqtt'
  },
  airmapServices: {
    registry: AIRMAP_SERVICES_REGISTRY_URL || ''
  }
}

export default api
