import {
  setOperationTypeInView,
  setUserRoleInView,
  setShouldRedirectUser,
  setPreviousContactPage
} from '../modules/core/store/core.actions'
import { operationTypes } from '../modules/core/constants/operations.constants'
import { operationRouteTypes } from '../modules/core/constants/routes.constants'
import { roles } from '../modules/auth/constants/roles.constants'

export const routeMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const isOperationTypeTemplate = location.href.includes(operationRouteTypes.REQUESTS)
    const operationTypeInView = isOperationTypeTemplate ? operationTypes.TEMPLATE : operationTypes.PLAN
    store.dispatch(setOperationTypeInView(operationTypeInView))

    const userRoleInView = Object.values(roles).find(roleName => location.href.includes(roleName))

    if (userRoleInView) {
      store.dispatch(setUserRoleInView(userRoleInView))
    }

    const doesIncludeOperationType =
      location.href.includes(operationRouteTypes.REQUESTS) || location.href.includes(operationRouteTypes.ACTIVITIES)

    store.dispatch(setShouldRedirectUser(!doesIncludeOperationType))

    const previousContactPage =
      location.href.includes('day-of-flight') || location.href.includes('day-1') || location.href.includes('any-day')

    if (previousContactPage) {
      store.dispatch(setPreviousContactPage(location.pathname))
    }
  }

  return next(action)
}
