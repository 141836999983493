import { MAP_STYLES_VERSION, CDN_HOST, MAPBOX_USERNAME } from "@env"

export type MapStyles = {
  url: string
}

export  type BaseMapStyles = {
  standard: string,
  light: string,
  dark: string,
  satellite: string
}

export const mapStyles: MapStyles = {
  url: `${CDN_HOST}/static/map-styles/${MAP_STYLES_VERSION}`
}

export const baseMapStyles: BaseMapStyles = {
  standard: `${mapStyles.url}/standard.json`,
  light: `${mapStyles.url}/light.json`,
  dark: `${mapStyles.url}/dark.json`,
  satellite: `${mapStyles.url}/satellite.json`
}

export const mapboxStylesUsername = MAPBOX_USERNAME || 'mapbox'
