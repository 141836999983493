import { formatKeys } from './formatKeys.constants'

export const localizedTimeServer = (time, spaced = false) => (spaced ? `${time} Z` : `${time}Z`)
export const localizedTimeClient = (time, spaced = false) => (spaced ? `${time} LT` : `${time}LT`)

export const commons = {
  [formatKeys.MONTH_YEAR]: 'MMMM YYYY',
  [formatKeys.MONTH_DAY]: 'D MMMM',
  [formatKeys.MONTH_DAY_YEAR]: 'MMM D, YYYY',
  [formatKeys.FULL_MONTH_DAY_YEAR]: 'MMMM D, YYYY',
  [formatKeys.DATE_SLASHED]: 'YYYY/MM/DD',
  [formatKeys.DATE]: 'YYYY-MM-DD',
  [formatKeys.DATE_TIME]: 'MMMM Do, YYYY, H:mm',
  [formatKeys.DEFAULT_DATE_TIME]: 'YYYY-MM-DDTHH:mm:ss[Z]',
  [formatKeys.DATE_TIME_WITHOUT_TIMEZONE]: 'YYYY-MM-DDTHH:mm:ss',
  [formatKeys.DATE_TIME_WITHOUT_YEAR]: 'MMMM Do, HH:mm',
  [formatKeys.DATE_TIME_DASHED]: 'YYYY-MM-DD - HH:mm',
  [formatKeys.DATE_SLASHED_TIME]: 'YYYY-MM-DD, HH:mm',
  [formatKeys.TIME]: 'hh:mm',
  [formatKeys.TIME_WITH_TZ]: 'hh:mm Z',
  [formatKeys.TIME_AM_PM]: 'hh:mm A',
  [formatKeys.TIME_023_HOURS]: 'HH:mm',
  [formatKeys.TIME_124_HOURS]: 'kk:mm',
  [formatKeys.LOCALE_TIME]: 'LT',
  [formatKeys.LOCALIZED_DATE_TIME_SERVER]: (date, time) => `${localizedTimeServer(time)} ${date}`,
  [formatKeys.LOCALIZED_DATE_TIME_CLIENT]: (date, time) => `(${localizedTimeClient(time)} ${date})`,
  [formatKeys.LOCALIZED_TIME_SERVER]: localizedTimeServer,
  [formatKeys.LOCALIZED_TIME_CLIENT]: localizedTimeClient
}
