import { columnNames } from '../../table/constants/columns.constants'
import { formatDateRange, formatDate } from 'modules/core/helpers/date-time.helpers'
import AirmapDate, { airmapDate, formatKeys } from 'libs/airmap-date'
import { timestampFormatting } from 'modules/core/constants/date-time.constants'
import { readStatuses, templateStatuses } from '../../core/constants/templates.constants'
import { inputIDs, inputValues } from '../../core/constants/scopes.constants'
import { roleAccessibility } from '../../auth/services/roles.service'
import { localizedTimeClient, localizedTimeServer } from 'libs/airmap-date/constants/commons.date-format.constants'
import { airmapIntl } from 'airmap-intl'
import { TemplateListMessages } from 'projects/SFO/modules/template-list/TemplateList.messages'
import { SFOCoreMessages } from 'projects/SFO/modules/core/core.messages'
import { rolesToFilter } from 'projects/SFO/modules/auth/constants/roles.constants'
import moment from 'moment/moment.js'

// Get the original value for a field if it exists, otherwise get the value from the field
const getOriginalValueForField = (operation, fieldId) => {
  const fieldValue = operation.fields[fieldId]?.value
  if ('original_fields' in operation) {
    return operation.original_fields[fieldId]?.value || fieldValue
  }
  return fieldValue
}

// Format the activity time to show both Zulu and local time.
const formatActivityTime = (time) => {
  const [formattedServerActivityTime, formattedLocalActivityTime] = time
    ? airmapDate.getServerAndLocalDateTimeFormatted({
        dateTime: time,
        serverDateTimeDecorator: localizedTimeServer,
        clientDateFormat: formatKeys.TIME_023_HOURS,
        clientDateTimeDecorator: localizedTimeClient,
      })
    : []
  return `${formattedServerActivityTime} (${formattedLocalActivityTime})`
}

export const parseOperationsToList = (operations, userId) => {
  return Promise.all(
    operations.map(async (operation) => {
      const fields = operation.fields
      const startTime = fields[inputIDs.START_TIME]?.value
      const endTime = fields[inputIDs.END_TIME]?.value
      const geometry = fields[inputIDs.GEOMETRY]?.value.footprint
      const dateFrom = fields[inputIDs.DATE_FROM]?.value
      const dateTo = fields[inputIDs.DATE_TO]?.value
      const activityType = fields[inputIDs.ACTIVITY_TYPE]?.value?.display_text
      const aircraftCategory = fields[inputIDs.AIRCRAFT_CATEGORY]?.value?.display_text
      const applicantFirstName = fields[inputIDs.APPLICANT_FIRST_NAME]?.value
      const applicantLastName = fields[inputIDs.APPLICANT_LAST_NAME]?.value
      const location = fields[inputIDs.LOCATION]?.value || ''
      const company = fields[inputIDs.COMPANY]?.value || ''
      const altitudeMaximum = fields[inputIDs.ALTITUDE_MAXIMUM]?.value || ''
      const altitudeMaximumReference = fields[inputIDs.ALTITUDE_MAXIMUM_REFERENCE]?.value.display_text || ''
      const altitudeMinimum = fields[inputIDs.ALTITUDE_MINIMUM]?.value || ''
      const altitudeMinimumReference = fields[inputIDs.ALTITUDE_MINIMUM_REFERENCE]?.value.display_text || ''

      const activityPreference = fields[inputIDs.PREFERENCE]?.value?.display_text || ''

      const activityStartTime = fields[inputIDs.ACTIVITY_START_TIME]?.value
      const activityEndTime = fields[inputIDs.ACTIVITY_END_TIME]?.value

      const attachedFile1 = fields[inputIDs.ATTACHED_FILE_1]?.value.reference || ''
      const attachedFile2 = fields[inputIDs.ATTACHED_FILE_2]?.value.reference || ''
      const attachedFile3 = fields[inputIDs.ATTACHED_FILE_3]?.value.reference || ''
      const attachedFile4 = fields[inputIDs.ATTACHED_FILE_4]?.value.reference || ''
      const attachedFile5 = fields[inputIDs.ATTACHED_FILE_5]?.value.reference || ''

      const filesAttached = [attachedFile1, attachedFile2, attachedFile3, attachedFile4, attachedFile5]

      const minimumAltitude = fullAltitudeToDisplay(fields, altitudeMinimumReference, altitudeMinimum || 0, false)
      const maximumAltitude = fullAltitudeToDisplay(fields, altitudeMaximumReference, altitudeMaximum, true)
      const altitudeRange = `${minimumAltitude} - ${maximumAltitude}`
      const altitude = {
        maximum: {
          value: altitudeMaximum,
          unit: altitudeMaximumReference,
        },
        minimum: {
          value: altitudeMinimum,
          unit: altitudeMinimumReference,
        },
      }

      const duration = fields[inputIDs.DURATION]?.value

      // Get activity wished start/end times from original values
      const originalActivityStartTime = getOriginalValueForField(operation, inputIDs.ACTIVITY_START_TIME)
      const originalActivityEndTime = getOriginalValueForField(operation, inputIDs.ACTIVITY_END_TIME)

      const userRole = operation.assignments?.find(
        ({ id, role }) => id === userId && rolesToFilter.includes(role)
      )?.role

      const today = moment()
      const isBeforeDoF = today.isBefore(moment(activityStartTime), 'day')

      // Add consultant history to state
      const shouldUseConsultantHistory =
        operation.state === templateStatuses.UNDER_CONSULTATION && Boolean(operation.consultant_history)
      const stateWithHistory = shouldUseConsultantHistory
        ? `${operation.state}_${operation.consultant_history}`
        : operation.state

      return {
        ...operation,
        geometry,
        altitude,
        altitudeRange,
        maxAltitude: fullAltitudeToDisplay(fields, altitudeMaximumReference, altitudeMaximum, true),
        time: {
          from: startTime,
          to: endTime,
        },
        date: {
          from: dateFrom,
          to: dateTo,
        },
        applicant: {
          first_name: applicantFirstName,
          last_name: applicantLastName,
        },
        activityStartTime: formatActivityTime(activityStartTime),
        activityEndTime: formatActivityTime(activityEndTime),
        originalActivityStartTime: formatActivityTime(originalActivityStartTime),
        originalActivityEndTime: formatActivityTime(originalActivityEndTime),
        location,
        company,
        type: activityType,
        aircraftCategory,
        filesAttached: parseFileFieldsForList(filesAttached),
        activityPreference,
        duration,
        userRole,
        isBeforeDoF,
        state: stateWithHistory,
      }
    })
  )
}

export const fullAltitudeToDisplay = (fields, altitudeReference, altitudvalue, isMaxValue = false) => {
  const { FLIGHT_LEVEL } = inputValues
  let altitude = `${altitudvalue} ${altitudeReference ? altitudeReference : ''}`
  const arrayFlightLevel = airmapIntl.translateMessage(SFOCoreMessages.flight_level).split(' ')

  if (fields[inputIDs.ALTITUDE_MAXIMUM_REFERENCE]?.value.value == FLIGHT_LEVEL && isMaxValue) {
    altitude = `${arrayFlightLevel[0]}${altitudvalue} ${arrayFlightLevel[1]} ${arrayFlightLevel[2]}`
  } else if (fields[inputIDs.ALTITUDE_MINIMUM_REFERENCE]?.value.value == FLIGHT_LEVEL) {
    altitude = `${arrayFlightLevel[0]}${altitudvalue}`
  }

  return altitude
}
export const removeTemplateItem = (state, { id }) => {
  return {
    ...state,
    allTemplatesData: state.allTemplatesData.filter((template) => template.id !== id),
  }
}

const formatOperationDTO = ({
  id,
  state,
  read_state,
  priority,
  type,
  reference_id,
  location,
  date,
  time,
  applicant,
  submitted,
  altitude,
  activityStartTime,
  activityEndTime,
  aircraftCategory,
  maxAltitude,
  company,
  filesAttached,
  assignments,
  activityPreference,
  userRole,
  ...rest
}) => ({
  ...rest, // pass all data and not only those from the columns, so that they are accessible in the view
  [columnNames.ID]: id,
  [columnNames.READ_STATE]: read_state,
  [columnNames.PRIORITY]: priority,
  [columnNames.STATE]: state,
  [columnNames.ACTIVITY_TYPE]: type,
  [columnNames.AIRCRAFT_CATEGORY]: aircraftCategory,
  [columnNames.SUBMITTED]: submitted,
  [columnNames.NAME]: applicant,
  [columnNames.REFERENCE]: reference_id,
  [columnNames.LOCATION]: location,
  [columnNames.DATE]: date,
  [columnNames.TIME]: time,
  [columnNames.ALTITUDE]: altitude,
  [columnNames.MAX_ALTITUDE]: maxAltitude,
  [columnNames.ACTIVITY_START_DATE]: activityStartTime,
  [columnNames.ACTIVITY_END_DATE]: activityEndTime,
  [columnNames.COMPANY]: company,
  [columnNames.FILES]: filesAttached,
  [columnNames.ASSIGNMENTS]: assignments,
  [columnNames.PREFERENCE]: activityPreference,
  [columnNames.USER_ROLE]: userRole,
})

export const parseFileFieldsForList = (filesAttached) => {
  return filesAttached.reduce((files, fileReference, index) => {
    const fileNumber = index + 1
    const fieldDescriptorId = inputIDs[`ATTACHED_FILE_${fileNumber}`]

    return fileReference ? [...files, { value: fileReference, fieldDescriptorId }] : files
  }, [])
}

export const parseTimeOperationData = (time = {}) => {
  // We ignore the timezone when parsing data from the API, see SUSI-275 for more info
  const timeFrom = airmapDate.dateProvider(time.from || new Date(), timestampFormatting.TIME_0_23_HOURS)
  const timeTo = airmapDate.dateProvider(time.to || new Date(), timestampFormatting.TIME_0_23_HOURS)

  const [severDateFrom, clientDateFrom] = airmapDate.formatLocalizedDateTime({
    dateFromProvider: timeFrom,
    showMonth: false,
  })
  const [severDateTo, clientDateTo] = airmapDate.formatLocalizedDateTime({
    dateFromProvider: timeTo,
    showMonth: false,
  })

  const local = `${clientDateFrom} - ${clientDateTo}`
  const full = `${severDateFrom} ${clientDateFrom} - ${severDateTo} ${clientDateTo}`
  // FIXME: this should be cleaned
  const localWithoutDecorators = AirmapDate.removeDecorators(local).replace(/,/g, '')
  const fullWithoutDecorators = AirmapDate.removeDecorators(full).replace(/,/g, '')

  return {
    full: fullWithoutDecorators,
    local: localWithoutDecorators,
  }
}

export const parseOperationsData = (operationsData) => {
  return operationsData.map(
    ({ reference_ids, applicant, date, submitted_at, time, read_state, aircraftCategory, ...rest }) => {
      const dateCell = date ? formatDateRange(date) : ''

      const timeParsed = parseTimeOperationData(time)

      const submittedAt = formatDate(submitted_at)
      const name = applicant ? `${applicant.first_name || ''} ${applicant.last_name || ''}` : ''
      const readState = read_state || readStatuses.UNREAD

      return formatOperationDTO({
        ...rest,
        reference_id: Array.isArray(reference_ids) ? reference_ids.map(({ id }) => id).join(',') : '',
        applicant: name,
        submitted: submittedAt,
        date: dateCell,
        time: timeParsed,
        read_state: readState,
        aircraftCategory: aircraftCategory || '',
      })
    }
  )
}

export const parseColumns = ({ columns, getStatusLabelTranslation, pillStyles }) => {
  const replacedColumnNames = roleAccessibility.roleConfiguration.replacedColumnNames || {}

  return columns.map((column) => {
    let parsedColumn = {}

    if (column.name === columnNames.STATE) {
      parsedColumn = {
        ...column,
        options: {
          ...column.options,
          translateCell: getStatusLabelTranslation,
          customStyles: pillStyles,
        },
      }
    }

    return { ...column, ...parsedColumn, name: replacedColumnNames[column.name] || column.name }
  })
}

export const parseUsersToList = (users, currentUserId) => {
  return users
    .map(({ id, first_name, last_name, nameKey, name, email }) => {
      const firstName = first_name || ''
      const lastName = last_name || ''
      const currentUser = id === currentUserId
      const displayName = firstName ? `${firstName} ${lastName}` : name || email

      return {
        value: id,
        displayName: currentUser ? airmapIntl.translateMessage(TemplateListMessages.me) : displayName,
        nameKey: currentUser ? TemplateListMessages.me : nameKey,
      }
    })
    .sort(function placeCurrentUserAtTheTop(a, b) {
      if (
        a.displayName === airmapIntl.translateMessage(TemplateListMessages.me) ||
        b.displayName === airmapIntl.translateMessage(TemplateListMessages.me)
      ) {
        return -1
      } else {
        return 1
      }
    })
}
