import api, { Api } from "./api";
import auth, { Auth } from "./auth";
import general, { GeneralConfig } from "./general";
import { mapStyles, baseMapStyles, mapboxStylesUsername, MapStyles, BaseMapStyles } from "./mapStyles";
import urls, { Services, services, Urls } from "./urls";
import statusPage, { StatusPage } from "./statusPage";

export type Config = GeneralConfig & {
  api: Api,
  auth: Auth,
  mapStyles: MapStyles
  baseMapStyles: BaseMapStyles,
  mapboxStylesUsername: string,
  urls: Urls,
  services: Services,
  statusPage: StatusPage
}

const config: Config = {
  api,
  auth,
  mapStyles,
  baseMapStyles,
  mapboxStylesUsername,
  urls,
  services,
  statusPage,
  ...general
}

export default config
