export const RulesetsListMessages = {
  pick1: {
    id: 'RulesetsList.pick1',
    defaultMessage: 'Pick One'
  },
  optional: {
    id: 'RulesetsList.optional',
    defaultMessage: 'Optional'
  },
  required: {
    id: 'RulesetsList.required',
    defaultMessage: 'Required Rules'
  }
}
