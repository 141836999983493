class RoleAccessibility {
  constructor() {
    this.activeRoleGroup = ''
    this.allRolesMenus = {}
    this.allRolesRoutes = []
    this.clientsConfig = {}
    this.menuGroups = {}
    this.menus = {}
    this.roleConfiguration = {}
    this.roleGroups = {}
    this.routes = {}
    this.userClientKey = ''
    this.userClients = []
    this.activeRoleGroupChangedEventKey = 'activeRoleGroupChanged'
    this.availableGroups = []
  }

  get activeGroupRoles() {
    return this.activeRoleGroup && this.roleGroups[this.activeRoleGroup]
  }

  setAvailableGroupsByRoles({ userRoles }) {
    for (const group in this.roleGroups) {
      for (const role of userRoles) {
        const isValidRole = this.roleGroups[group].includes(role)
        const isUserRoleAnAvailableGroup = this.roleConfiguration?.availableRoleGroups?.includes(group)

        if (isValidRole && isUserRoleAnAvailableGroup) {
          this.availableGroups = [...this.availableGroups, group]
          break
        }
      }
    }

    if (!this.activeRoleGroup) {
      this.setActiveRoleGroup(this.availableGroups[0])
    }
    return this
  }

  setRoleConfiguration() {
    const availableClients = Object.keys(this.clientsConfig)
    const userClientConfiguration = availableClients.find(availableClient => this.userClients.includes(availableClient))
    this.userClientKey = userClientConfiguration
    this.roleConfiguration = this.clientsConfig[userClientConfiguration]
    return this
  }

  isValidTokenClient(clients) {
    const availableClients = Object.keys(this.clientsConfig)
    return clients.length && availableClients.some(client => clients.includes(client))
  }

  setRoutes(routes) {
    this.routes = routes
    return this
  }

  setMenus(menus) {
    this.menus = menus
    return this
  }

  setAllRolesRoutes(routes) {
    this.allRolesRoutes = routes
    return this
  }

  setAllRolesMenus(menus) {
    this.allRolesMenus = menus
    return this
  }

  setMenusGroups(menuGroups) {
    this.menuGroups = menuGroups
    return this
  }

  setClientConfig(clientConfig) {
    this.clientsConfig = clientConfig
    return this
  }

  setUserClient(clients) {
    this.userClients = clients
    return this.setRoleConfiguration()
  }

  setRoleGroups(roleGroups) {
    this.roleGroups = roleGroups
    return this
  }

  setActiveRoleGroup(role) {
    if (this.activeRoleGroup !== role) {
      this.activeRoleGroup = role
      window.dispatchEvent(new CustomEvent(this.activeRoleGroupChangedEventKey, { detail: { role } }))
    }
    return this
  }

  isUserGroup({ userRoles, lookupRoleGroup }) {
    return userRoles.some(role => this.roleGroups[lookupRoleGroup].includes(role))
  }

  getGroupMenu(userRoles, pathTo) {
    const menuConfig = this.getMenusByRoles(userRoles)

    const groupKey = Object.keys(menuConfig).find(groupId => {
      const groupMenu = menuConfig[groupId]

      return groupMenu.children && groupMenu.children.some(menuItem => menuItem.to === pathTo)
    })

    return groupKey ? menuConfig[groupKey] : {}
  }

  getRoleAccessibilityByKey({ roles, accessibilityType }) {
    return Object.keys(this[accessibilityType]).reduce((filteredRoles, role) => {
      if (roles.includes(role) && this.activeGroupRoles.includes(role)) {
        return filteredRoles.concat(this[accessibilityType][role])
      }
      return filteredRoles
    }, [])
  }

  getRoutesByRoles(roles) {
    const roleRoutes = this.getRoleAccessibilityByKey({ roles, accessibilityType: 'routes' })
    const allRolesRoutes = this.allRolesRoutes
    return [...roleRoutes, ...allRolesRoutes]
  }

  getMenusByRoles(roles) {
    const allRolesMenus = this.allRolesMenus

    const menuArrayData = this.getRoleAccessibilityByKey({ roles, accessibilityType: 'menus' })

    const filteredMenuArrayData = menuArrayData.reduce((menus, menuItem) => {
      const isMenuItemUnavailable = this.userClients.some(client => {
        const clientUnavailableMenus = this.clientsConfig[client] && this.clientsConfig[client].unavailableClientMenus
        if (clientUnavailableMenus) {
          return clientUnavailableMenus.includes(menuItem.id)
        }
      })

      if (isMenuItemUnavailable && this.userClients) {
        return menus
      }

      const menuGroup = menuItem.group
      const itemHasGroup = Boolean(menuGroup)
      const menusGroup = menus[menuGroup]
      const menuHasChildren = menusGroup && menusGroup.children && menusGroup.children.length
      const menuItems = menuHasChildren ? menusGroup.children : []

      return !itemHasGroup
        ? { ...menus, [menuItem.to]: menuItem }
        : { ...menus, [menuGroup]: { ...this.menuGroups[menuGroup], children: [...menuItems, menuItem] } }
    }, {})

    return { ...allRolesMenus, ...filteredMenuArrayData }
  }

  isKnownRoute(route) {
    return Object.values(this.routes)
      .flat()
      .map(route => route.path)
      .includes(route)
  }
}

export const roleAccessibility = new RoleAccessibility()
