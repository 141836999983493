import { SFOCoreMessages } from '../core/core.messages'

const AircraftCategoryMessages = {
  captive_balloon: {
    id: 'TemplateList.captive_balloon',
    defaultMessage: 'Captive Balloon'
  },
  drone_heavy: {
    id: 'TemplateList.drone_heavy',
    defaultMessage: 'Drone more than 25 kg'
  },
  drone_light: {
    id: 'TemplateList.drone_light',
    defaultMessage: 'Drone less than 25 kg'
  },
  drone_very_light: {
    id: 'TemplateList.drone_very_light',
    defaultMessage: 'Drone less than 250 gr'
  },
  fireworks: {
    id: 'TemplateList.fireworks',
    defaultMessage: 'Fireworks'
  },
  fixed_wing: {
    id: 'TemplateList.fixed_wing',
    defaultMessage: 'Fixed Wing'
  },
  gas_balloon: {
    id: 'TemplateList.gas_balloon',
    defaultMessage: 'Gas Balloon'
  },
  hang_glider: {
    id: 'TemplateList.hang_glider',
    defaultMessage: 'Hang Glider'
  },
  hot_air_balloon: {
    id: 'TemplateList.hot_air_balloon',
    defaultMessage: 'Hot Air Balloon'
  },
  kite: {
    id: 'TemplateList.kite',
    defaultMessage: 'Kite'
  },
  model_aircraft: {
    id: 'TemplateList.model_aircraft',
    defaultMessage: 'Model Aircraft'
  },
  model_rocket: {
    id: 'TemplateList.model_rocket',
    defaultMessage: 'Model Rocket'
  },
  parachute: {
    id: 'TemplateList.parachute',
    defaultMessage: 'Parachute'
  },
  rotary_wing: {
    id: 'TemplateList.rotary_wing',
    defaultMessage: 'Rotary Wing'
  },
  rpas: {
    id: 'TemplateList.rpas',
    defaultMessage: 'Military drone'
  },
  sky_lantern: {
    id: 'TemplateList.sky_lantern',
    defaultMessage: 'Sky Lantern'
  },
  skylight: {
    id: 'TemplateList.skylight',
    defaultMessage: 'Skylight / Laser Show'
  },
  toy_balloon: {
    id: 'TemplateList.toy_balloon',
    defaultMessage: 'Toy Balloon'
  },
  weather_balloon: {
    id: 'TemplateList.weather_balloon',
    defaultMessage: 'Weather Balloon'
  },
  other: {
    id: 'TemplateList.other',
    defaultMessage: 'Other'
  }
}

export const TemplateListMessages = {
  ...SFOCoreMessages,
  ...AircraftCategoryMessages,
  reference_id: {
    id: 'TemplateList.reference_id',
    defaultMessage: 'Reference'
  },
  company_name: {
    id: 'TemplateList.company_name',
    defaultMessage: 'Company Name'
  },
  first_name: {
    id: 'TemplateList.first_name',
    defaultMessage: 'First Name'
  },
  last_name: {
    id: 'TemplateList.last_name',
    defaultMessage: 'Last Name'
  },
  name: {
    id: 'TemplateList.name',
    defaultMessage: 'Request Name'
  },
  location: {
    id: 'TemplateList.location',
    defaultMessage: 'Location'
  }
}
