import * as types from './template-consultation.types'

export const initialState = {
  templateConsultantList: [],
  showConsultationResponse: false,
  isLoadingSubmitConsultation: false,
  isLoadingCancelConsultation: false,
  isLoadingConsultantVoteConfirmation: false,
  showCancelConsultationResponse: false,
  showSubmitConsultationVoteResponse: false,
  isAssignConsultantModalOpen: false
}

export function templateConsultationReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CONSULTANTS_LIST:
      return {
        ...state,
        templateConsultantList: action.templateConsultantList
      }
    case types.SET_SHOW_POST_CONSULTATION_RESPONSE:
      return {
        ...state,
        showConsultationResponse: action.status
      }
    case types.SET_IS_LOADING_POST_CONSULTATION:
      return {
        ...state,
        isLoadingSubmitConsultation: action.status
      }
    case types.SET_IS_LOADING_CANCEL_CONSULTATION:
      return {
        ...state,
        isLoadingCancelConsultation: action.status
      }
    case types.SET_SHOW_CANCEL_CONSULTATION_RESPONSE:
      return {
        ...state,
        showCancelConsultationResponse: action.status
      }
    case types.SET_IS_LOADING_CONSULTANT_VOTE_CONFIRMATION:
      return {
        ...state,
        isLoadingConsultantVoteConfirmation: action.status
      }
    case types.SET_SHOW_SUBMIT_CONSULTATION_VOTE_RESPONSE:
      return {
        ...state,
        showSubmitConsultationVoteResponse: action.status
      }
    case types.CLEAN_UP_TEMPLATE_CONSULTATION:
      return {
        ...state,
        templateConsultantList: []
      }
    case types.CLOSE_ASSIGN_CONSULTANT_MODAL:
      return {
        ...state,
        isAssignConsultantModalOpen: false
      }
    case types.OPEN_ASSIGN_CONSULTANT_MODAL:
      return {
        ...state,
        isAssignConsultantModalOpen: true
      }
    default:
      return state
  }
}
