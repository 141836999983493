import {
  API_HOST,
  APP_ENV,
  APP_INSIGHTS_CONNECTION,
  APP_MAINTENANCE_MODE,
  APP_MAINTENANCE_MODE_URL,
  AUTH_REALM,
  SHOW_DECONFLICTION,
  SHOW_FLIGHT_PLANNING,
  WEB_APP_PUBLIC_PATH,
  USE_EXPERIMENTAL_URLS,
  EXPERIMENTAL_API_HOST,
  EXPERIMENTAL_AUTH_HOST,
  SHOW_GLOBALS_LAYERS,
} from '@env'
import { languageCodes } from '../../translations/translations.constants'

export type GeneralConfig = {
  apiHost: string
  appEnv: string
  root: string
  default_locale: typeof languageCodes[keyof typeof languageCodes]
  show_deconfliction: boolean
  show_flight_planning: boolean
  show_globals_layers: boolean
  isJCAB: boolean
  app_insights_connection: string
  app_maintenance_mode: {
    active: boolean
    url: string
  }
  useExperimentalUrls: boolean
  experimentalApiHost: string
  experimentalAuthHost: string
  isSwissDronePortal: boolean
}

const config: GeneralConfig = {
  apiHost: API_HOST,
  appEnv: APP_ENV,
  root: WEB_APP_PUBLIC_PATH || '',
  default_locale: 'en',
  show_deconfliction: SHOW_DECONFLICTION === 'true',
  show_flight_planning: SHOW_FLIGHT_PLANNING === 'true',
  show_globals_layers: SHOW_GLOBALS_LAYERS !== 'false',
  isJCAB: AUTH_REALM === 'jcab',
  app_insights_connection: APP_INSIGHTS_CONNECTION,
  app_maintenance_mode: {
    active: APP_MAINTENANCE_MODE === 'true',
    url: APP_MAINTENANCE_MODE_URL,
  },
  useExperimentalUrls: USE_EXPERIMENTAL_URLS || false,
  experimentalApiHost: EXPERIMENTAL_API_HOST,
  experimentalAuthHost: EXPERIMENTAL_AUTH_HOST,
  isSwissDronePortal: USE_EXPERIMENTAL_URLS && EXPERIMENTAL_API_HOST.includes('swissdroneportal')
}

export default config
