export const SET_CONSULTANTS_LIST = 'SET_CONSULTANTS_LIST'
export const SET_SHOW_POST_CONSULTATION_RESPONSE = 'SET_SHOW_POST_CONSULTATION_RESPONSE'
export const SET_IS_LOADING_POST_CONSULTATION = 'SET_IS_LOADING_POST_CONSULTATION'
export const SET_IS_LOADING_CANCEL_CONSULTATION = 'SET_IS_LOADING_CANCEL_CONSULTATION'
export const SET_SHOW_CANCEL_CONSULTATION_RESPONSE = 'SET_SHOW_CANCEL_CONSULTATION_RESPONSE'
export const SET_IS_LOADING_CONSULTANT_VOTE_CONFIRMATION = 'SET_IS_LOADING_CONSULTANT_VOTE_CONFIRMATION'
export const SET_SHOW_SUBMIT_CONSULTATION_VOTE_RESPONSE = 'SET_SHOW_SUBMIT_CONSULTATION_VOTE_RESPONSE'
export const CLEAN_UP_TEMPLATE_CONSULTATION = 'CLEAN_UP_TEMPLATE_CONSULTATION'
export const OPEN_ASSIGN_CONSULTANT_MODAL = 'OPEN_ASSIGN_CONSULTANT_MODAL'
export const CLOSE_ASSIGN_CONSULTANT_MODAL = 'CLOSE_ASSIGN_CONSULTANT_MODAL'
