import * as types from './core.types'

export const receiveScopeDescriptors = scopeDescriptors => ({
  type: types.RECEIVE_SCOPE_DESCRIPTORS,
  scopeDescriptors
})

export const setScopeDescriptorsLoaded = () => ({
  type: types.SET_SCOPE_DESCRIPTORS_LOADED
})

export const setOperationTypeInView = operationTypeInView => ({
  type: types.SET_OPERATION_TYPE_IN_VIEW,
  operationTypeInView
})

export const setUserRoleInView = userRoleInView => ({
  type: types.SET_USER_ROLE_IN_VIEW,
  userRoleInView
})

export const setShouldRedirectUser = status => ({
  type: types.SET_SHOULD_REDIRECT_USER,
  status
})

export const setPreviousContactPage = previousContactPage => ({
  type: types.SET_PREVIOUS_CONTACT_PAGE,
  previousContactPage
})
