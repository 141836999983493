export const START_LOADING_TEMPLATE_LIST = 'START_LOADING_TEMPLATE_LIST'
export const STOP_LOADING_TEMPLATE_LIST = 'STOP_LOADING_TEMPLATE_LIST'
export const SET_ERROR_FETCHING_TEMPLATE = 'SET_ERROR_FETCHING_TEMPLATE'
export const RECEIVE_ALL_TEMPLATES_DATA = 'RECEIVE_ALL_TEMPLATES_DATA'
export const RECEIVE_PLAN_CONFLICT_DATA = 'RECEAIVE_PLAN_CONFLICT_DATA'
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE'
export const PURGE_TEMPLATE_LIST_STORE = 'PURGE_TEMPLATE_LIST_STORE'
export const SET_IS_LOADING_CHANGE_TEMPLATE_STATUS = 'SET_IS_LOADING_CHANGE_TEMPLATE_STATUS'
export const SET_SHOW_BEGIN_REVIEW_RESPONSE = 'SET_SHOW_BEGIN_REVIEW_RESPONSE'
export const SET_SHOW_CHANGE_STATUS_RESPONSE = 'SET_SHOW_CHANGE_STATUS_RESPONSE'
export const REFRESH_OPERATION_LIST = 'REFRESH_OPERATION_LIST'
export const SET_IS_LOADING_PARENT_OPERATION_DATA = 'SET_IS_LOADING_PARENT_OPERATION_DATA'
// FIXME: The 2 types below should be removed when BE provides an ENDPOINT to dashboard
export const SET_ACTIVITIES_ENTITIES_QUANTITY = 'SET_ACTIVITIES_ENTITIES_QUANTITY'
export const SET_REQUESTS_ENTITIES_QUANTITY = 'SET_REQUESTS_ENTITIES_QUANTITY'

export type Field = {
  id: string
  field_descriptor_id: string
  type: string
  value: unknown
}
export type AllTemplatesDataRow = Record<string, unknown> & {
  fields: Record<string, null | Field>
}

export type PaginationResponse = {
  page_size: null | number
  page_number: null | number
  page_count: number
  entities_count: null | number
}

type ConflictingPlanEntry = {
  id: string
  latest_activity_id: string
  reference_id: {
    id: string
    authority: string
  }
  state: unknown
}

export type PlanConflict = {
  plan_1: ConflictingPlanEntry
  plan_2: ConflictingPlanEntry
  id: string
  timestamp_range: {
    from: string
    to: string
  }
  altitude_range: {
    max: number
    max_ref: string
    min: number
    min_ref: string
  }
  geometry: {
    footprint: {
      type: string
      coordinates: [number, number][][]
    }
  }
}
