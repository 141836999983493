import * as types from './template-evaluation.types'
import { updateScopeFieldValue, validateUpdatedScopes } from '../../core/store/core.helpers'
import { updateGeometryFieldValue } from '../helpers/template-evaluation.helpers'

const initialState = {
  currentEvaluationScopes: [],
  currentGeometryScopes: [],
  isEvaluationFinished: false,
  isReadyForCreation: false,
  isProcessingEvaluation: false,
  templateGeometry: {},
  evaluationWorkflow: '',
  didUserRequestEvaluation: false,
  responsibleAuthority: '',
  isWorkflowApplicable: false
}

export const templateEvaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_EVALUATION_SCOPES:
      return {
        ...state,
        currentEvaluationScopes: action.newCurrentEvaluationScopes
      }
    case types.SET_CURRENT_GEOMETRY_SCOPES:
      return {
        ...state,
        currentGeometryScopes: action.newCurrentGeometryScopes
      }
    case types.UPDATE_SCOPE_FIELD_VALUE:
      return {
        ...state,
        currentEvaluationScopes: validateUpdatedScopes(updateScopeFieldValue(state.currentEvaluationScopes, action))
      }
    case types.SET_IS_EVALUATION_FINISHED:
      return {
        ...state,
        isEvaluationFinished: true,
        evaluationWorkflow: action.evaluationWorkflow
      }
    case types.SET_IS_READY_FOR_CREATION:
      return {
        ...state,
        isReadyForCreation: true
      }
    case types.SET_IS_PROCESSING_EVALUATION:
      return {
        ...state,
        isProcessingEvaluation: action.status
      }
    case types.SET_TEMPLATE_GEOMETRY:
      return {
        ...state,
        templateGeometry: action.geometry,
        currentEvaluationScopes: updateGeometryFieldValue(state.currentEvaluationScopes, action.geometry),
        currentGeometryScopes: updateGeometryFieldValue(state.currentGeometryScopes, action.geometry)
      }
    case types.SET_DID_USER_REQUEST_EVALUATION:
      return {
        ...state,
        didUserRequestEvaluation: action.status
      }
    case types.SET_RESPONSIBLE_AUTHORITY:
      return {
        ...state,
        responsibleAuthority: action.responsibleAuthority
      }
    case types.SET_IS_WORKFLOW_APPLICABLE:
      return {
        ...state,
        isWorkflowApplicable: action.status
      }
    case types.CLEAR_EVALUATION_DATA:
      return initialState
    default:
      return state
  }
}
