import AirMapAuth from 'airmap-auth'
import { encodeJWT } from './helpers/encodeJWT'
import { AUTH_REALM } from "@env"
class Auth {
  constructor({ auth, api, root, default_locale }) {
    const options = {
      closeable: true,
      domain: auth.domain,
      logo: 'us', // TODO make configurable
      language: default_locale || 'en',
      autoLaunch: false,
      onAuthenticated: this.handleRedirect
    }



    if (AUTH_REALM) {
      options.realm = AUTH_REALM
    }

    this.auth = new AirMapAuth(api.config, options)
    this.redirectPath = root
  }

  static decodeJWT(JWT) {
    try {
      return JSON.parse(atob(JWT))
    } catch (error) {
      console.warn("JWT Couldn't be decoded", error)
      return
    }
  }

  getAuth() {
    return this.auth
  }

  getUserRoles() {
    const JWT = this.getAuth().getUserToken()
    if (JWT) {
      const encodedJWT = encodeJWT(JWT)
      const userData = Auth.decodeJWT(encodedJWT)
      return (userData.am && userData.am.roles) || []
    }
    return []
  }

  updateRedirectPath(path) {
    this.redirectPath = path
    localStorage.setItem('redirect_path', this.redirectPath)
  }

  handleRedirect() {
    this.redirectPath = localStorage.getItem('redirect_path') || '/'
    localStorage.removeItem('redirect_path')
    const {
      location: { protocol, host }
    } = window
    setTimeout(() => window.location.replace(`${protocol}//${host}${this.redirectPath}`), 0)
  }
}

export default Auth
