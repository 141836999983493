import * as types from './operation-detail.types'
import { updateScopeFieldValue, validateUpdatedScopes } from '../../core/store/core.helpers'

export const initialState = {
  selectedTemplate: {
    reference_ids: [],
    scopes: []
  },
  selectedPlanParent: {
    reference_ids: []
  },
  templateActivityResponse: [],
  isTemplateActivityLoaded: false,
  templateSelfActivityResponse: [],
  areTemplateCommentsLoaded: false,
  isLoadingTemplateDetail: false,
  postCommentErrorMessage: {},
  selectedTemplateState: '',
  isLoadingChangeTemplateStatus: false,
  isLoadingPriority: false,
  recommendedScopeValues: [],
  editionScopeValues: [],
  isProcessingModification: false,
  isPostRecommendModificationSuccess: false,
  isCommentsDrawerOpen: false,
  commentToHighlight: '',
  isAssignReviewersSuccess: false,
  isUnassignReviewersSuccess: false,
  isAssignContactsSuccess: false,
  isPointOfContactModalOpen: false,
  parentTemplateActivityResponse: [],
  isOperationStatusUpdateSuccess: false,
  templatePlans: [],
  beginOperationReviewSuccess: false,
  isPrintButtonDisabled: false,
  isLoadingInstructions: false
}

export function templateDetailReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_TEMPLATE_ACTIVITY_RESPONSE:
      return {
        ...state,
        templateActivityResponse: action.templateActivityResponse,
        isTemplateActivityLoaded: true
      }
    case types.RECEIVE_TEMPLATE_SELF_ACTIVITY_RESPONSE:
      return {
        ...state,
        templateSelfActivityResponse: action.templateSelfActivityResponse
      }
    case types.RECEIVE_PARENT_TEMPLATE_ACTIVITY_RESPONSE:
      return {
        ...state,
        parentTemplateActivityResponse: action.parentTemplateActivityResponse
      }
    case types.RECEIVE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          ...action.template
        },
        recommendedScopeValues: action.template.scopes || state.recommendedScopeValues,
        editionScopeValues: action.template.editionScopes || state.editionScopeValues
      }
    case types.RECEIVE_SELECTED_PLAN_PARENT:
      return {
        ...state,
        selectedPlanParent: {
          ...state.selectedPlanParent,
          ...action.parentTemplate
        }
      }
    case types.START_LOADING_TEMPLATE_DETAIL:
      return {
        ...state,
        isLoadingTemplateDetail: true
      }
    case types.STOP_LOADING_TEMPLATE_DETAIL:
      return {
        ...state,
        isLoadingTemplateDetail: false
      }
    case types.SET_IS_LOADING_CHANGE_TEMPLATE_STATUS:
      return {
        ...state,
        isLoadingChangeTemplateStatus: action.status
      }
    case types.SET_IS_LOADING_PRIORITY:
      return {
        ...state,
        isLoadingPriority: action.status
      }
    case types.SET_ERROR_POSTING_COMMENT:
      return {
        ...state,
        postCommentErrorMessage: action.errorMessage
      }
    case types.SET_SELECTED_TEMPLATE_STATE:
      return {
        ...state,
        selectedTemplateState: action.state
      }
    case types.UPDATE_RECOMMENDED_FIELD_VALUE:
      return {
        ...state,
        recommendedScopeValues: validateUpdatedScopes(
          updateScopeFieldValue(state.recommendedScopeValues, action, { isModification: true })
        )
      }
    case types.UPDATE_EDITION_FIELD_VALUE:
      return {
        ...state,
        editionScopeValues: validateUpdatedScopes(
          updateScopeFieldValue(state.editionScopeValues, action, { isModification: true })
        )
      }
    case types.SET_IS_PROCESSING_MODIFICATION:
      return {
        ...state,
        isProcessingModification: action.status
      }
    case types.SET_IS_POST_RECOMMEND_MODIFICATION_SUCCESS:
      return {
        ...state,
        isPostRecommendModificationSuccess: action.status,
        recommendedScopeValues: state.selectedTemplate.scopes
      }
    case types.OPEN_COMMENTS_DRAWER:
      return {
        ...state,
        isCommentsDrawerOpen: true
      }
    case types.CLOSE_COMMENTS_DRAWER:
      return {
        ...state,
        isCommentsDrawerOpen: false
      }
    case types.SET_COMMENT_TO_HIGHLIGHT:
      return {
        ...state,
        commentToHighlight: action.id
      }
    case types.SET_IS_ASSIGN_REVIEWERS_SUCCESS:
      return {
        ...state,
        isAssignReviewersSuccess: action.status
      }
    case types.SET_IS_UNASSIGN_REVIEWERS_SUCCESS:
      return {
        ...state,
        isUnassignReviewersSuccess: action.status
      }
    case types.SET_IS_OPERATION_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isOperationStatusUpdateSuccess: action.status
      }
    case types.SET_IS_ASSIGN_CONTACTS_SUCCESS:
      return {
        ...state,
        isAssignContactsSuccess: action.status
      }
    case types.CLOSE_POINT_OF_CONTACT_MODAL:
      return {
        ...state,
        isPointOfContactModalOpen: false
      }
    case types.OPEN_POINT_OF_CONTACT_MODAL:
      return {
        ...state,
        isPointOfContactModalOpen: true
      }
    case types.SET_TEMPLATE_PLANS:
      return {
        ...state,
        templatePlans: action.plans
      }
    case types.SET_BEGIN_OPERATION_REVIEW_SUCCESS:
      return {
        ...state,
        beginOperationReviewSuccess: action.status
      }
    case types.CLEAN_UP_TEMPLATE_PLANS:
      return {
        ...state,
        templatePlans: []
      }
    case types.SET_IS_PRINT_BUTTON_DISABLED:
      return {
        ...state,
        isPrintButtonDisabled: action.status
      }
    case types.SET_IS_LOADING_INSTRUCTIONS:
      return {
        ...state,
        isLoadingInstructions: action.status
      }
    case types.CLEAN_UP_TEMPLATE_DETAIL:
      return initialState
    default:
      return state
  }
}
