import React from 'react'

const Sleet = () => (
  <g fillRule="evenodd">
    <path
      strokeWidth="2"
      d="M86.9451109,29.6136186 C84.0142225,29.6136186 81.0833333,29.9770791 78.3333333,30.8980093 C72.0975558,17.4597953 58.3475556,8.61395349 43.5,8.61395349 C22.2357778,8.61395349 5,25.9298977 5,47.2930232 C5,64.2332276 16.1808889,79.3438513 32.3191111,84.3119625 C33.7882222,84.6778789 35.25,83.9485027 35.8048889,82.4701027 C36.1666667,80.9941579 35.4406667,79.5255816 33.9715556,78.9681119 C20.0284444,74.7392 10.5,61.846177 10.5,47.2930232 C10.5,29.0561488 25.3475556,14.1395349 43.5,14.1395349 C56.3333333,14.1395349 68.0691109,21.688707 73.3882225,33.2973395 L74.4857775,35.5026605 C75.0382225,36.789507 76.5,37.3445209 77.7882225,36.9810605 L80.1666667,36.2418605 C82.3617775,35.5026605 84.75,35.1392 87.1382225,35.1392 C99.6073333,35.1392 109.690667,45.2694326 109.690667,57.7965395 C109.690667,70.3162789 99.4166667,80.4465119 86.9451109,80.4465119 C85.476,80.4465119 84.1951109,81.7309027 84.1951109,83.2093027 C84.1951109,84.6877027 85.476,85.9720927 86.9451109,85.9720927 C102.347556,85.9720927 115,73.2608 115,57.7867162 C115,42.3249116 102.347556,29.6136186 86.9451109,29.6136186 Z M50.8333333,67.4 C49.7357778,67.4 49,67.6804465 49,68.0987867 L49,79.9781561 C49,80.3964963 49.7357778,80.6769421 50.8333333,80.6769421 C51.9308889,80.6769421 52.6666667,80.3964963 52.6666667,79.9781561 L52.6666667,68.0987867 C52.6666667,67.6804465 51.9308889,67.4 50.8333333,67.4 Z M64.0333333,91.6 C62.9357775,91.6 62.2,91.8804465 62.2,92.2987867 L62.2,104.178156 C62.2,104.596496 62.9357775,104.876942 64.0333333,104.876942 C65.1308891,104.876942 65.8666667,104.596496 65.8666667,104.178156 L65.8666667,92.2987867 C65.8666667,91.8804465 65.1308891,91.6 64.0333333,91.6 Z"
    />
    <polygon points="76.818 79.331 76.164 78.198 74.016 79.438 70.111 77.184 74.016 74.929 76.162 76.168 76.816 75.036 75.325 74.174 77.6 72.86 76.946 71.728 74.667 73.042 74.667 71.313 73.36 71.313 73.36 73.798 69.449 76.056 69.449 71.539 71.602 70.297 70.95 69.165 69.449 70.031 69.449 67.4 68.142 67.4 68.142 70.027 66.648 69.165 65.996 70.299 68.142 71.538 68.142 76.046 64.236 73.792 64.236 71.313 62.927 71.313 62.927 73.036 60.654 71.724 60 72.856 62.277 74.17 60.782 75.034 61.436 76.168 63.586 74.926 67.495 77.184 63.586 79.442 61.432 78.2 60.78 79.333 62.277 80.197 60.002 81.512 60.656 82.643 62.927 81.331 62.927 83.054 64.236 83.054 64.236 80.576 68.142 78.321 68.142 82.832 65.998 84.068 66.65 85.2 68.142 84.34 68.142 86.967 69.449 86.967 69.449 84.338 70.946 85.202 71.6 84.068 69.449 82.828 69.449 78.312 73.36 80.57 73.36 83.054 74.667 83.054 74.667 81.325 76.946 82.64 77.598 81.508 75.325 80.193" />
    <polygon points="52.618 103.531 51.964 102.398 49.816 103.638 45.911 101.384 49.816 99.129 51.962 100.368 52.616 99.236 51.125 98.374 53.4 97.06 52.746 95.928 50.467 97.242 50.467 95.513 49.16 95.513 49.16 97.998 45.249 100.256 45.249 95.739 47.402 94.497 46.75 93.365 45.249 94.231 45.249 91.6 43.942 91.6 43.942 94.227 42.448 93.365 41.796 94.499 43.942 95.738 43.942 100.246 40.036 97.992 40.036 95.513 38.727 95.513 38.727 97.236 36.454 95.924 35.8 97.056 38.077 98.37 36.582 99.234 37.236 100.368 39.386 99.126 43.295 101.384 39.386 103.642 37.232 102.4 36.58 103.533 38.077 104.397 35.802 105.712 36.456 106.843 38.727 105.531 38.727 107.254 40.036 107.254 40.036 104.776 43.942 102.521 43.942 107.032 41.798 108.268 42.45 109.4 43.942 108.54 43.942 111.167 45.249 111.167 45.249 108.538 46.746 109.402 47.4 108.268 45.249 107.028 45.249 102.512 49.16 104.77 49.16 107.254 50.467 107.254 50.467 105.525 52.746 106.84 53.398 105.708 51.125 104.393" />
  </g>
)

export default Sleet
