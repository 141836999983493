import axios from 'axios'
import { airmapAuth } from 'libs/airmap-auth'
import settings from 'settings'

const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

// FIXME: Once we apply AirmapAuth for the remaining applications, we will be able to remove this token.
// It should be always obtained from the package.
const token = localStorage.getItem(settings.auth.token)

// Quick fix from airmapAuth to not init with an expired token from local storage
const authorization = token && !airmapAuth.isTokenExpired() ? { authorization: `Bearer ${token}` } : {}

const axiosInstance = axios.create({
  headers: {
    ...authorization,
    accept: 'application/json',
    'content-type': 'application/json',
    'X-API-Key': settings.api.config.airmap.api_key,
    'accept-language': language || 'en'
  }
})

// FIXME: Once we apply AirmapAuth for the remaining applications, we will be able to remove this token.
export const axiosInstanceWithoutAuth = axios.create({
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'X-API-Key': settings.api.config.airmap.api_key,
    'accept-language': language || 'en'
  }
})

const receiveConfig = config => {
  if (airmapAuth.isEnabled) {
    if (airmapAuth.isTokenExpired()) {
      return airmapAuth.updateToken().then(() => {
        config.headers.authorization = `Bearer ${airmapAuth.token}`
        return Promise.resolve(config)
      })
    }

    config.headers.authorization = `Bearer ${airmapAuth.token}`
  }

  return config
}

axiosInstance.interceptors.request.use(receiveConfig)

export default axiosInstance
