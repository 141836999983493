import React from 'react'
import PropTypes from 'prop-types'

import styles, { button, disabledButton } from './IconButton.module.scss'

export const IconButton = ({
  onClick,
  icon,
  disabled,
  id,
  className,
  variant,
  onMouseDown,
  onMouseLeave,
  onMouseEnter,
  size,
  intent
}) => (
  <button
    id={`sel-button-${id}`}
    onClick={disabled ? null : onClick}
    onMouseDown={onMouseDown}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
    className={`
      ${button}
      ${variant ? styles[`button--${variant}`] : ''}
      ${size ? styles[`button--${size}`] : ''}
      ${variant && intent ? styles[`button--${variant}-${intent}`] : ''}
      ${disabled ? disabledButton : ''}
      ${className}
    `}
  >
    {icon}
  </button>
)

IconButton.defaultProps = {
  disabled: false,
  className: '',
  variant: '',
  intent: '',
  size: '',
  onClick: event => {
    event.preventDefault()
  },
  onMouseDown: event => {
    event.preventDefault()
  },
  onMouseLeave: event => {
    event.preventDefault()
  },
  onMouseEnter: event => {
    event.preventDefault()
  }
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  intent: PropTypes.string
}
