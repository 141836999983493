import * as types from './template-list.types'
import { removeTemplateItem } from '../helpers/template-list.helpers'
import * as Actions from './template-list.actions'

export type TemplateListState = {
  isLoadingTemplateList: boolean
  errorFetchingTemplate: boolean
  allTemplatesData: types.AllTemplatesDataRow[]
  isLoadingChangeTemplateStatus: boolean
  showBeginReviewResponse: boolean
  showChangeStatusResponse: boolean
  paginationResponse: {
    page_size: number
    page_number: number
    page_count: number
    entities_count: number
  }
  activitiesEntitiesQuantity: number
  isLoadingParentOperationData: boolean
  requestsEntitiesQuantity: number
  planConflicts: types.PlanConflict[]
}

const initialState: TemplateListState = {
  isLoadingTemplateList: true,
  errorFetchingTemplate: false,
  allTemplatesData: [],
  isLoadingChangeTemplateStatus: false,
  showBeginReviewResponse: false,
  showChangeStatusResponse: false,
  paginationResponse: {
    page_size: 20,
    page_number: 1,
    page_count: 1,
    entities_count: 0,
  },
  activitiesEntitiesQuantity: 0,
  isLoadingParentOperationData: false,
  requestsEntitiesQuantity: 0,
  planConflicts: [],
}

export const templateListReducer = (state = initialState, action: Actions.TemplateListAction) => {
  switch (action.type) {
    case types.START_LOADING_TEMPLATE_LIST:
      return {
        ...state,
        isLoadingTemplateList: true,
      }
    case types.STOP_LOADING_TEMPLATE_LIST:
      return {
        ...state,
        isLoadingTemplateList: false,
      }
    case types.SET_ERROR_FETCHING_TEMPLATE:
      return {
        ...state,
        errorFetchingTemplate: action.status,
      }
    case types.RECEIVE_ALL_TEMPLATES_DATA:
      return {
        ...state,
        allTemplatesData: action.allTemplatesData,
        paginationResponse: action.paginationResponse || state.paginationResponse,
      }
    case types.RECEIVE_PLAN_CONFLICT_DATA:
      return {
        ...state,
        planConflicts: action.planConflicts,
      }
    case types.REMOVE_TEMPLATE:
      return removeTemplateItem(state, action)
    case types.PURGE_TEMPLATE_LIST_STORE:
      return initialState
    case types.SET_IS_LOADING_CHANGE_TEMPLATE_STATUS:
      return {
        ...state,
        isLoadingChangeTemplateStatus: action.status,
      }
    case types.SET_SHOW_BEGIN_REVIEW_RESPONSE:
      return {
        ...state,
        showBeginReviewResponse: action.status,
      }
    case types.SET_SHOW_CHANGE_STATUS_RESPONSE:
      return {
        ...state,
        showChangeStatusResponse: action.status,
      }
    // FIXME: The 2 cases below should be removed when BE provides an ENDPOINT to dashboard
    case types.SET_ACTIVITIES_ENTITIES_QUANTITY:
      return {
        ...state,
        activitiesEntitiesQuantity: action.template_count,
      }
    case types.SET_REQUESTS_ENTITIES_QUANTITY:
      return {
        ...state,
        requestsEntitiesQuantity: action.template_count,
      }
    case types.SET_IS_LOADING_PARENT_OPERATION_DATA:
      return {
        ...state,
        isLoadingParentOperationData: action.status,
      }
    default:
      return state
  }
}
