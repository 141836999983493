import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'

const styles = () => ({
  wrapper: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paper: {
    width: '75%',
    height: '80vh',
    padding: '0 3em 3em 3em'
  },
  title: {
    fontSize: '5em',
    textAlign: 'center'
  }
})

const NotFound = ({ classes }) => (
  <div className={classes.wrapper}>
    <Paper square elevation={1} className={classes.paper}>
      <h1 className={classes.title}>404</h1>
      <p className={classes.subtitle}>Sorry, the page you requested cannot be found.</p>
      <Link to="/">
        <div className={classes.link}>Back to Home</div>
      </Link>
    </Paper>
  </div>
)

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotFound)
