import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'

import { FIREBASE_CONFIG } from "@env"

/**
 * Validate that all required options have been provided.
 * @private
 */
function validateConfig(config: {[key: string]: string}) {
  if (!config.apiKey) throw new Error('firebase-analytics - Missing option: apiKey.')
  if (!config.authDomain) throw new Error('firebase-analytics - Missing option: authDomain.')
  if (!config.projectId) throw new Error('firebase-analytics - Missing option: projectId.')
  if (!config.storageBucket) throw new Error('firebase-analytics - Missing option: storageBucket.')
  if (!config.messagingSenderId) throw new Error('firebase-analytics - Missing option: messagingSenderId.')
  if (!config.appId) throw new Error('firebase-analytics - Missing option: appId.')
  return config as FirebaseOptions
}

function parseConfig(configString: string) {
  try {
    return JSON.parse(atob(configString))
  } catch (e) {
    throw new Error("Failed to parse the firebase config, make sure it's a base64 encode json")
  }
}

type UserData = {
  id: string,
  email: string,
  first_name: string,
  last_name: string
}

export default class FirebaseAnalytics {
  _enabled = false // Enable analytics only if the firebase config is passed;
  _config?: FirebaseOptions = undefined // Firebase Config
  _app?: FirebaseApp = undefined // Firebase App
  _analytics?: Analytics = undefined // Firebase Analytics interface

  constructor() {
    this._enabled = !!FIREBASE_CONFIG

    if (!this._enabled) {
      console.warn('FIREBASE_CONFIG is not defined')
      return
    }

    const parsedConfig = parseConfig(FIREBASE_CONFIG)
    this._config = validateConfig(parsedConfig)
    this._app = initializeApp(this._config)
    this._analytics = getAnalytics(this._app)
  }


  /**
   * Push a virtualPageview event to the dataLayer.
   * @public
   */
  pageview = () => {
    if (this._enabled && this._analytics) {
      logEvent(this._analytics, 'virtualPageview')
    }
  }

  /**
   * Register and update profile data on the current user.
   * @public
   * @param {Object} data - Any arbitrary user data you wish to provide.
   * @param {string} data.user_id - Required. Unique identifier for the user.
   * @param {string} [data.email] - Optional. User's email address.
   * @param {string} [data.first_name] - Optional. User's first name.
   * @param {string} [data.last_name] - Optional. User's last name.
   */
  user = (data: UserData) => {
    if (this._enabled  && this._analytics) {
      logEvent(this._analytics, 'identifyUser', data)
    }
  }

  /**
   * Register a crash in the UI
   */
  appException = (error: Error, errorInfo: string) => {
    if (this._enabled  && this._analytics) {
      logEvent(this._analytics, "exception", { error, errorInfo })
    }
  }
}
