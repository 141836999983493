import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { airmapIntl } from '../airmap-intl'

export const AirmapIntlProvider = ({ children, lang }) => {
  useEffect(
    function updateUserLanguage() {
      airmapIntl.setUserLanguage(lang)
    },
    [lang]
  )

  return <>{React.Children.map(children, child => React.cloneElement(child))}</>
}

AirmapIntlProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  lang: PropTypes.string.isRequired
}
