import * as types from './user.types'
import { MeasurementTypes } from 'modules/core/constants/measurement-types.constants'

const initialState = {
  measurementType: localStorage.getItem('selected-measurement') || MeasurementTypes.METRIC,
  userSelectionLanguage: 'en',
  isUpdateUserPreferencesLoading: false
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SFO_MEASUREMENT_TYPE:
      return {
        ...state,
        measurementType: action.measurementType
      }
    case types.SET_USER_SELECTION_LANGUAGE:
      return {
        ...state,
        userSelectionLanguage: action.userSelectionLanguage
      }
    case types.SET_IS_UPDATE_USER_PREFERENCES_LOADING:
      return {
        ...state,
        isUpdateUserPreferencesLoading: action.isLoading
      }
    default:
      return state
  }
}
