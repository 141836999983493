export const operationRouteTypes = {
  REQUESTS: 'requests',
  ACTIVITIES: 'activities'
}

export const routePaths = {
  operator: {
    TEMPLATE_CREATE: '/operator/requests/create',
    TEMPLATE_EVAL: '/operator/requests/eval',
    TEMPLATE_DETAIL: '/operator/requests/:id',
    TEMPLATE_DETAIL_BASE_PATH: '/operator/requests',
    TEMPLATE_LIST: '/operator/requests',
    PLAN_DETAIL_BASE_PATH: '/operator/activities',
    PLAN_DETAIL: '/operator/activities/:id',
    PLAN_CREATE: '/operator/activities/create',
    PLAN_LIST: '/operator/activities'
  },
  reviewer: {
    TEMPLATE_DETAIL: '/reviewer/requests/:id',
    TEMPLATE_LIST: '/reviewer/requests',
    PLAN_LIST: '/reviewer/activities',
    DAY_OF_FLIGHT: '/reviewer/activities/day-of-flight',
    PLAN_DETAIL: '/reviewer/activities/:id'
  },
  consultant: {
    TEMPLATE_DETAIL: '/consultant/requests/:id',
    TEMPLATE_LIST: '/consultant/requests',
    PLAN_LIST: '/consultant/activities',
    PLAN_DETAIL: '/consultant/activities/:id'
  },
  contact: {
    DAY_MINUS_ONE: '/contact/activities/day-1',
    DAY_OF_FLIGHT: '/contact/activities/day-of-flight',
    ANY_DAY: '/contact/activities/any-day',
    PLAN_DETAIL: '/contact/activities/:id',
    PLAN_DETAIL_BASE_PATH: '/contact/activities',
    TEMPLATE_DETAIL: '/contact/requests/:id'
  },
  DASHBOARD: '/dashboard'
}
