import { inputTypes, requirementTypes, scopesIDs, inputIDs } from '../../core/constants/scopes.constants'
import { getGeometryScopeAndField, getMissingFieldDescriptors } from '../../core/helpers/scopes.helpers'
import { updateScopeFieldValue } from '../../core/store/core.helpers'

export const appendMissingScopesFieldsToCurrentScopesFields = (
  scopeDescriptors,
  currentScopes,
  missingScopes,
  didUserRequestEvaluation,
  previousEvaluationScopes = []
) => {
  const currentScopesTaggedisNewIncomingFieldFalse = setIsNewIncomingFieldStatusToScopes(currentScopes, false)

  return missingScopes.reduce((mergedOldAndNewScopes, missingScope) => {
    const scopeDescriptor = scopeDescriptors.find(({ id }) => id === missingScope.scope_descriptor_id)
    const previousScope = previousEvaluationScopes.find(({ id }) => id === missingScope.scope_descriptor_id)
    const previousScopeFields = previousScope ? previousScope.fields : []
    const hasPreviousScopeFields = previousScopeFields.length > 0
    const missingFields = missingScope.field_descriptors
    const missingFieldDescriptors = getMissingFieldDescriptors(missingFields, scopeDescriptor)

    let missingFieldDescriptorsWithPreviousValues

    if (!didUserRequestEvaluation && hasPreviousScopeFields) {
      missingFieldDescriptorsWithPreviousValues = persistPreviousFields(missingFieldDescriptors, previousScopeFields)
    } else {
      missingFieldDescriptorsWithPreviousValues = missingFieldDescriptors
    }

    const fieldDescriptorsTaggedIsNewIncomingField = didUserRequestEvaluation
      ? addIsNewIncomingFieldStatusToFieldDescriptors(missingFieldDescriptorsWithPreviousValues, true)
      : missingFieldDescriptorsWithPreviousValues

    const scopeInCurrentEvaluation = mergedOldAndNewScopes.find(
      ({ scope_descriptor_id }) => scopeDescriptor.id === scope_descriptor_id
    )

    if (!scopeInCurrentEvaluation) {
      return mergedOldAndNewScopes.concat({
        ...scopeDescriptor,
        scope_descriptor_id: scopeDescriptor.id,
        fields: fieldDescriptorsTaggedIsNewIncomingField
      })
    }

    scopeInCurrentEvaluation.fields = scopeInCurrentEvaluation.fields.concat(fieldDescriptorsTaggedIsNewIncomingField)

    return mergedOldAndNewScopes
  }, currentScopesTaggedisNewIncomingFieldFalse)
}

const addIsNewIncomingFieldStatusToFieldDescriptors = (fieldDescriptors, status) =>
  fieldDescriptors.map(descriptor => {
    if (descriptor.type === inputTypes.GROUP)
      return {
        ...descriptor,
        details: { fields: addIsNewIncomingFieldStatusToFieldDescriptors(descriptor.details.fields, status) }
      }
    return { ...descriptor, isNewIncomingField: status }
  })

const setIsNewIncomingFieldStatusToScopes = (scopes, status) =>
  scopes.map(scope => ({ ...scope, fields: addIsNewIncomingFieldStatusToFieldDescriptors(scope.fields, status) }))

export const updateGeometryFieldValue = (currentEvaluationScopes, geometry) => {
  const { geometryScope, geometryField } = getGeometryScopeAndField(currentEvaluationScopes)

  const doesGeometryScopeExist = geometryScope && geometryField

  return doesGeometryScopeExist
    ? updateScopeFieldValue(currentEvaluationScopes, {
        scopeId: geometryScope.id,
        fieldId: geometryField.id,
        value: geometry && geometry.geometry
      })
    : currentEvaluationScopes
}

export const tagFieldsAsOptional = (scopes, optionalScopes) => {
  const optionalFieldsArr = optionalScopes
    .map(scope =>
      scope.field_descriptors.map(({ field_descriptor_id }) => ({
        scopeId: scope.scope_descriptor_id,
        fieldId: field_descriptor_id
      }))
    )
    .flat()

  const scopesTaggedOptional = optionalFieldsArr.reduce(
    (updatedScopes, optionalField) =>
      updateScopeFieldValue(updatedScopes, optionalField, { requirement: { type: requirementTypes.OPTIONAL } }),
    scopes
  )

  return scopesTaggedOptional
}

const persistPreviousFields = (comingFields, previousFields) => {
  return comingFields.map(field => {
    const previousField = previousFields.find(({ id }) => id === field.id)
    const subFields = field.details && field.details.fields

    if (previousField) {
      const hasValue = previousField.inputValue !== undefined

      if (hasValue) {
        field.inputValue = previousField.inputValue
      } else if (subFields) {
        const previousSubfields = previousField.value

        field.details.fields = persistPreviousFields(subFields, previousSubfields)
      }
    }
    return field
  })
}

export function parseGeometriesInMapBounds(mapBounds) {
  const parsedMapBoundsForAPI = [
    {
      scope_descriptor_id: scopesIDs.EVALUATION,
      fields: [
        {
          field_descriptor_id: inputIDs.GEOMETRY,
          type: inputTypes.GEOMETRY,
          value: {
            footprint: {
              ...mapBounds.geometry
            }
          }
        }
      ]
    }
  ]

  return parsedMapBoundsForAPI
}

export function validateMaximumAltitudeReferenceFromAPIDataBody(data, scopesDescriptors) {
  const evaluationScopeDescriptor = scopesDescriptors.find(scope => scope.id === scopesIDs.EVALUATION)
  const altitudeFieldDescriptor = evaluationScopeDescriptor.fields.find(field => field.id === inputIDs.ALTITUDE)
  const altitudeMaximumReferenceFieldDescriptor = altitudeFieldDescriptor.details.fields.find(
    field => field.id === inputIDs.ALTITUDE_MAXIMUM_REFERENCE
  )

  return data.map(scope => {
    const isEvaluationScope = scope.scope_descriptor_id === scopesIDs.EVALUATION

    return !isEvaluationScope
      ? scope
      : {
          ...scope,
          fields: scope.fields.map(field => {
            const isAltitudeField = field.id === inputIDs.ALTITUDE

            if (isAltitudeField) {
              const hasAltitudeMaximumReference = field.details.fields.find(
                field => field.id === inputIDs.ALTITUDE_MAXIMUM_REFERENCE
              )

              if (!hasAltitudeMaximumReference) {
                const defaultMaximumReference = {
                  ...altitudeMaximumReferenceFieldDescriptor,
                  value: 'meters_agl',
                  inputValue: 'meters_agl'
                }

                return {
                  ...field,
                  details: {
                    ...field.details,
                    fields: [...field.details.fields, defaultMaximumReference]
                  },
                  values: {
                    ...field.values,
                    defaultMaximumReference
                  }
                }
              }
            }

            return field
          })
        }
  })
}
