import {
  defaultSdkOptions,
  airmapAuthChangeStateEventKey,
  airmapAuthPermissionsChangedEventKey,
  airmapAuthStates,
  airmapAuthSessionStorageKeys,
  airmapAuthEvents
} from './constants/airmap-auth.constants'
import { removeUriUnsupportedCharsParams } from './helpers/airmap-auth.helpers'
import { debounce } from 'modules/core/helpers/utilities.helpers'

class AirmapAuth {
  constructor() {
    this.sdk = {}
    this.bannedRoles = []
  }

  get isAuthenticated() {
    return this.sdk.authenticated || false
  }

  get token() {
    return this.sdk.token || ''
  }

  get tokenParsed() {
    return this.sdk.tokenParsed || {}
  }

  get tokenRoles() {
    return (this.tokenParsed.am && this.tokenParsed.am.roles) || []
  }

  get userEmail() {
    return this.tokenParsed.email
  }

  get tokenClients() {
    return this.tokenRoles.reduce((clients, role) => {
      const roleIdentifiers = role.split(':')
      const roleClient = roleIdentifiers[roleIdentifiers.length - 2]
      const roleAlreadyExist = clients.some(client => client === roleClient)
      return roleAlreadyExist ? clients : [...clients, roleClient]
    }, [])
  }

  get userRoles() {
    const availableRoles = this.tokenRoles
      .map(role => {
        return this.appRoles.find(appRole => {
          const roleName = role.split(':').pop()
          return appRole.name === roleName
        })
      })
      .filter(role => {
        const isRoleValid = Boolean(role)
        const isRoleBanned = this.bannedRoles.includes(role?.name)

        return isRoleValid && !isRoleBanned
      })

    this.hasRolesAttached = Boolean(availableRoles.length)

    if (!this.hasRolesAttached) {
      availableRoles.push({ ...this.defaultRole })
    }

    const uniqueRoles = [...new Set(availableRoles)]

    return uniqueRoles
  }
  get userRolesNames() {
    return this.userRoles.map(role => role.name)
  }

  setAppRoles = roles => {
    this.appRoles = roles
  }

  setBannedRoles = roles => {
    this.bannedRoles = roles
  }

  initCurrentRole = () => {
    const sessionRole = sessionStorage.getItem(airmapAuthSessionStorageKeys.CURRENT_ROLE)
    const roleNeededForRoute = this.getCurrentRoleFromRoute()

    let currentRoleName = this.userRolesNames[0]
    const isSessionRoleAvailableInUserRoles = this.userRolesNames.includes(sessionRole) && this.hasRolesAttached
    const isRouteRoleAvailableInUserRoles = this.userRolesNames.includes(roleNeededForRoute) && this.hasRolesAttached

    if (isSessionRoleAvailableInUserRoles) {
      currentRoleName = sessionRole
    } else if (isRouteRoleAvailableInUserRoles) {
      currentRoleName = roleNeededForRoute
    }

    if (currentRoleName) {
      this.setCurrentRole(currentRoleName)
    }
  }

  getCurrentRoleFromRoute = () => {
    const path = window.location.href
    const role = this.appRoles.find(role => path.includes(role.defaultRoute) || path.includes(role.name))
    return role && role.name
  }

  setCurrentRole = roleName => {
    const role = this.appRoles.find(appRole => appRole.name === roleName)

    sessionStorage.setItem(airmapAuthSessionStorageKeys.CURRENT_ROLE, role.name)

    this.currentRole = { ...role }
  }

  setCurrentRolePermissions = permissions => {
    this.currentRole.permissions = [...permissions]
    this.dispatchPermissionsChangedEvent()
  }

  can = permissionName => {
    if (this.hasRolesAttached && this.isMultiRoleApp) {
      return this.userRoles.some(userRole => userRole.permissions.includes(permissionName))
    }

    return this.currentRole.permissions.includes(permissionName)
  }

  isTokenExpired = () => {
    return Boolean(this.sdk.refreshToken) && this.sdk.isTokenExpired()
  }

  initAndCheckLoginState = settings => {
    this.initService(settings)

    return this.initSDK({ ...defaultSdkOptions, redirectUri: location.href, onLoad: 'check-sso' }).then(() => {
      if (!this.isMultiRoleApp) {
        this.initCurrentRole()
      }
    })
  }

  initAndForceLogin = settings => {
    this.initService(settings)
    return this.initSDK({ ...defaultSdkOptions, redirectUri: location.href, ...settings.app }).then(authenticated => {
      if (!authenticated) {
        this.sdk.login()
      } else {
        if (!this.isMultiRoleApp) {
          this.initCurrentRole()
        }
      }
    })
  }

  initService = ({ auth, roles, defaultRole = '', isMultiRoleApp = false }) => {
    this.sdk = window.Keycloak(auth)
    this.appRoles = roles
    this.defaultRole = this.appRoles.find(appRole => appRole.name === defaultRole) || {}
    this.isMultiRoleApp = isMultiRoleApp
    this.tokenStorageKey = `${airmapAuthSessionStorageKeys.TOKEN}-${auth.clientId}`
    this.refreshTokenStorageKey = `${airmapAuthSessionStorageKeys.REFRESH_TOKEN}-${auth.clientId}`

    // FIXME: This boolean is just to be able to avoid the axios interceptor.
    // Once the package is applied in all applications this could be removed.
    this.isEnabled = true
  }

  initSDK = settings => {
    const token = sessionStorage.getItem(this.tokenStorageKey)
    const refreshToken = sessionStorage.getItem(this.refreshTokenStorageKey)

    return this.sdk
      .init({ ...settings, token, refreshToken, checkLoginIframe: false })
      .then(authenticated => {
        if (authenticated) {
          this.setAuthTokensOnStorage()
        }

        this.isInitialized = true

        this.dispatchChangeStateEvent(airmapAuthStates.INITIALIZED)

        return authenticated
      })
      .catch(error => {
        // FIXME: Check why the token request fails
        console.warn('error', error)
      })
  }

  setAuthTokensOnStorage = () => {
    sessionStorage.setItem(this.tokenStorageKey, this.sdk.token)

    let counterToRemoveInterval = 0

    const interval = setInterval(() => {
      if (this.sdk.refreshToken) {
        sessionStorage.setItem(this.refreshTokenStorageKey, this.sdk.refreshToken)
        clearInterval(interval)
      }

      if (counterToRemoveInterval === 10) {
        clearInterval(interval)
      }

      counterToRemoveInterval++
    }, 100)
  }

  login = (options = {}) => {
    return this.sdk.login({ ...options })
  }

  register = (options = {}) => {
    return this.login({
      ...options,
      action: 'register'
    })
  }

  logout = (options = {}, urlReturned) => {
    sessionStorage.removeItem(this.tokenStorageKey)
    sessionStorage.removeItem(this.refreshTokenStorageKey)
    const urlToReturn = urlReturned ? urlReturned : window.location.href

    return this.sdk.logout({
      redirectUri: removeUriUnsupportedCharsParams(urlToReturn),
      ...options
    })
  }

  updateToken = () => {
    if (!this.updateTokenPromise) {
      this.updateTokenPromise = this.sdk
        .updateToken()
        .then(response => {
          this.setAuthTokensOnStorage()
          return response
        })
        .catch(() => this.login())
    }

    return this.updateTokenPromise
  }

  initUserActivityListener = () => {
    let timerID = -1

    const checkUserTokenExpiration = debounce(() => {
      const hasTimerIDBeenCleared = timerID >= 0

      if (!hasTimerIDBeenCleared) {
        const hasUserTokenExpired = this.isTokenExpired()
        const tokenExpirationListenerTime = 30 * 1000

        if (hasUserTokenExpired) {
          const userTokenHasExpired = new Event(airmapAuthEvents.TOKEN_HAS_EXPIRED)
          document.dispatchEvent(userTokenHasExpired)
        }
        timerID = setTimeout(() => {
          timerID = -1
        }, tokenExpirationListenerTime)
      }
    }, 300)

    document.addEventListener('mousemove', checkUserTokenExpiration)
    return () => {
      document.removeEventListener('mousemove', checkUserTokenExpiration)
    }
  }

  dispatchChangeStateEvent = nextState => {
    const changeStateEvent = new CustomEvent(airmapAuthChangeStateEventKey, {
      detail: {
        nextState
      }
    })

    document.dispatchEvent(changeStateEvent)
  }

  dispatchPermissionsChangedEvent = () => {
    const permissionsChangedEvent = new Event(airmapAuthPermissionsChangedEventKey)

    document.dispatchEvent(permissionsChangedEvent)
  }

  onTokenAboutToExpire = () => {
    this.sdk.updateToken(50).catch(() => {
      console.error('onTokenAboutToExpire: Failed to refresh token')
    })
  }
}

export const airmapAuth = new AirmapAuth()
