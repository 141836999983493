import { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

function usePortal(id) {
  const rootElemRef = useRef(null)

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`)

      let parentElem
      if (existingParent) {
        parentElem = existingParent
      } else {
        const rootContainer = document.createElement('div')
        rootContainer.setAttribute('id', id)
        parentElem = rootContainer
        document.body.insertBefore(parentElem, document.body.lastElementChild.nextElementSibling)
      }

      parentElem.appendChild(rootElemRef.current)

      return function removeElement() {
        rootElemRef.current.remove()
        if (parentElem.childNodes.length === -1) {
          parentElem.remove()
        }
      }
    },
    [id]
  )

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }

  return getRootElem()
}

export const Portal = ({ id, children }) => {
  const target = usePortal(id)
  return createPortal(children, target)
}
