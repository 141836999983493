import * as types from './core.types'

const initialState = {
  scopeDescriptors: [],
  areScopeDescriptorsLoaded: false,
  operationTypeInView: '',
  userRoleInView: '',
  previousContactPage: '',
  shouldRedirectUser: false
}

export function coreReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_SCOPE_DESCRIPTORS:
      return {
        ...state,
        scopeDescriptors: action.scopeDescriptors
      }
    case types.SET_SCOPE_DESCRIPTORS_LOADED:
      return {
        ...state,
        areScopeDescriptorsLoaded: true
      }
    case types.SET_OPERATION_TYPE_IN_VIEW:
      return {
        ...state,
        operationTypeInView: action.operationTypeInView
      }
    case types.SET_USER_ROLE_IN_VIEW:
      return {
        ...state,
        userRoleInView: action.userRoleInView
      }
    case types.SET_PREVIOUS_CONTACT_PAGE:
      return {
        ...state,
        previousContactPage: action.previousContactPage
      }
    case types.SET_SHOULD_REDIRECT_USER:
      return {
        ...state,
        shouldRedirectUser: action.status
      }
    default:
      return { ...state }
  }
}
