import React from 'react'
import PropTypes from 'prop-types'

export const Layer = ({ width, height, viewBox, fillColor }) => (
  <svg width={width} height={height} viewBox={viewBox || '0 0 44 46'} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-4">
        <path
          d="M42.5164348,33.0147125 L37.9729565,30.9449525 L26.2555652,36.285865 C24.9160522,36.911875 23.4812696,37.19947 21.9998087,37.19947 C20.5164348,37.19947 19.0816522,36.9099693 17.7440522,36.285865 L6.02666087,30.8963463 L1.48318357,32.9661063 C-0.334590348,33.7838463 -0.334590348,36.3837513 1.48318357,37.2014913 L19.1300522,45.3336528 C20.9478261,46.1513928 23.0514087,46.1513928 24.9179652,45.3336528 L42.5648348,37.2481725 C44.3340174,36.3834144 44.3340174,33.8324044 42.5162626,33.0146644 L42.5164348,33.0147125 Z"
          id="Fill-1"
          fill={`${fillColor || '#FC4C02'}`}
        />
        <path
          d="M42.5164348,20.9834625 L37.9729565,18.9137025 L26.2555652,24.3032213 C24.9160522,24.9292313 23.4812696,25.2168263 21.9998087,25.2168263 C20.5164348,25.2168263 19.0816522,24.9273255 17.7440522,24.3032213 L5.97787826,18.9137025 L1.43440143,20.9834625 C-0.383372478,21.8012025 -0.383372478,24.4011075 1.43440143,25.2188475 L19.1300522,33.3024038 C20.9478261,34.1201438 23.0514087,34.1201438 24.9179652,33.3024038 L42.5648348,25.2169225 C44.3340174,24.3991825 44.3340174,21.8011544 42.5162626,20.9834144 L42.5164348,20.9834625 Z"
          id="Fill-2"
          fill={`${fillColor || '#333F48'}`}
        />
        <path
          d="M1.48337391,13.1852875 L19.1302435,21.319375 C20.9480174,22.137115 23.0516,22.137115 24.9181565,21.319375 L42.5650261,13.2338937 C44.3828,12.4161537 44.3828,9.81624875 42.5650261,8.99850875 L24.8693739,0.86634625 C23.0516,0.04860625 20.9480174,0.04860625 19.0814609,0.86634625 L1.48289422,8.9518275 C-0.334879696,9.81658562 -0.334879696,12.3675956 1.48289422,13.1853356 L1.48337391,13.1852875 Z"
          id="Fill-3"
          fill={`${fillColor || '#88DBDF'}`}
        />
      </g>
    </g>
  </svg>
)

Layer.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  fillColor: PropTypes.string
}
