import turfKinks from '@turf/kinks'
import { buffer, point, center, lineString, circle, featureCollection, area } from '@turf/turf'
import { isObjectEmpty } from 'modules/core/helpers/object.helpers'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { zoomByArea } from '../constants/draw-plugin.constants'

import {
  drawLayerConstants,
  geometryTypes,
  MAP_DRAWING_TYPE_FEATURE_COLLECTION,
  drawPluginConfig,
  MAP_LAYER_TO_POSITION_GEOMETRY_BEFORE,
  GL_DRAW_LAYER,
  modificationColorsByState,
  modificationColorStateFallback,
  clusterColors,
} from '../constants/draw-plugin.constants'
import { advisoryColors, conflictColors } from '../../map/constants/map.constants'

export const drawPluginInstance = new MapboxDraw(drawPluginConfig)

export const addControlsAndListenersToMap = (map, listenerCallback) => {
  map.addControl(drawPluginInstance, 'bottom-right')

  map.on('draw.create', listenerCallback)
  map.on('draw.update', listenerCallback)
}

export const removeControlsAndListenersFromMap = (map, listenerCallback) => {
  map.removeControl(drawPluginInstance)
  map.off('draw.create', listenerCallback)
  map.off('draw.update', listenerCallback)
}

const {
  INVALID_FLIGHT_AREA_ID,
  FLIGHT_PLAN_SOURCE_ID,
  FLIGHT_PLAN_GEOMETRY_ID,
  FLIGHT_PLAN_GEOMETRY_HIDDEN_ID,
  FLIGHT_PLAN_OUTLINE_SOURCE_ID,
  FLIGHT_PLAN_OUTLINE_ID,
  CLUSTERS_SOURCE_ID,
  CLUSTERS_ID,
  CLUSTERS_GEOMETRY_COUNT_ID,
  CLUSTERS_GEOMETRY_POINT_ID,
  CONFLICT_SOURCE_ID,
  CONFLICT_GEOMETRY_ID,
  CONFLICT_OUTLINE_ID,
} = drawLayerConstants
const { POLYGON, LINE_STRING, POINT } = geometryTypes

export const checkPolygonIntersection = (feature) => {
  const intersection = turfKinks(feature)
  return intersection.features.length ? turfKinks(feature) : false
}

export const addIntersectionToMap = (map, intersection) => {
  if (!map.getSource(INVALID_FLIGHT_AREA_ID)) {
    map.addSource(INVALID_FLIGHT_AREA_ID, {
      type: 'geojson',
      data: intersection,
    })
    map.addLayer({
      id: INVALID_FLIGHT_AREA_ID,
      source: INVALID_FLIGHT_AREA_ID,
      type: 'circle',
      paint: {
        'circle-radius': 5,
        'circle-opacity': 0,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#ff0000',
      },
    })
  }
}

export const getBufferedFeature = (feature, buffer) => {
  const featureType = feature.geometry.type
  let templateGeometry = {}
  let featureToGeoJson
  switch (featureType) {
    case POLYGON:
      templateGeometry = feature
      break
    case LINE_STRING:
      featureToGeoJson = lineString(feature.geometry.coordinates)
      templateGeometry = addBuffer(featureToGeoJson, buffer)
      break
    case POINT:
      featureToGeoJson = point(feature.geometry.coordinates)
      templateGeometry = addBuffer(featureToGeoJson, buffer)
      break
    default:
      templateGeometry = feature
      break
  }

  return templateGeometry
}
export const addBuffer = (feature, userSelectedBufferSize = 10) => {
  const isLineString = feature.geometry.type === LINE_STRING
  const adjustedBufferSize = isLineString ? userSelectedBufferSize * 0.5 : userSelectedBufferSize

  return isLineString
    ? buffer(feature, adjustedBufferSize, { units: 'meters' })
    : circle(feature, adjustedBufferSize, { units: 'meters' })
}

const applyModificationByState = ({ state, modification, fallback }) => {
  return ['match', ['get', 'state'], state, modification, fallback]
}

const getFilterExpression = () => {
  let counter = 0
  function generateFilterExpressionColor() {
    modificationColorsByState[counter]
    counter++
    return modificationColorsByState[counter]
      ? applyModificationByState({
          ...modificationColorsByState[counter],
          fallback: generateFilterExpressionColor(),
        })
      : modificationColorStateFallback
  }
  return generateFilterExpressionColor()
}

const searchForZoom = (area) => {
  let zoomSearched = 22

  for (const zoom in zoomByArea) {
    const areaLimit = zoomByArea[zoom]

    if (area >= areaLimit) {
      zoomSearched = zoom
      break
    }
  }

  return Number(zoomSearched)
}

export const parseGeometriesToPoints = (geometries) => {
  if (geometries.type === MAP_DRAWING_TYPE_FEATURE_COLLECTION) {
    return {
      ...geometries,
      features: geometries?.features?.map((feature) => {
        const middlePointGeometry = center(feature)
        const properties = feature.properties || {}
        const areaCalculated = area(feature)
        const zoomAssigned = searchForZoom(areaCalculated)

        return {
          ...middlePointGeometry,
          properties: {
            ...properties,
            'point-color': clusterColors[properties.state] || clusterColors.default,
            'zoom-level-to-show': zoomAssigned,
          },
        }
      }),
    }
  }

  const areaCalculated = area(geometries)
  const zoomAssigned = searchForZoom(areaCalculated)

  const middlePointGeometry = center(geometries)
  const newFeatureCollection = featureCollection([middlePointGeometry])
  const [firstFeature] = newFeatureCollection.features

  firstFeature.properties = { ...geometries.properties, 'zoom-level-to-show': zoomAssigned }

  return { ...newFeatureCollection, features: [firstFeature] }
}

export const drawGeometryOnMap = ({
  map,
  geometry,
  outline,
  applyColorFilter,
  layerToPositionBefore,
  planConflictGeometry,
}) => {
  map.addSource(FLIGHT_PLAN_SOURCE_ID, {
    type: 'geojson',
    data: geometry,
    generateId: true,
  })
  map.addSource(FLIGHT_PLAN_OUTLINE_SOURCE_ID, {
    type: 'geojson',
    data: outline,
    generateId: true,
  })

  map.addSource(CONFLICT_SOURCE_ID, {
    type: 'geojson',
    data: planConflictGeometry,
    generateId: true,
  })

  const isLayerInMap = layerToPositionBefore && Boolean(map.getLayer(layerToPositionBefore))
  const firstDrawPluginLayer = map.getStyle().layers.find(({ id }) => id.includes(GL_DRAW_LAYER))

  let beforeId = isLayerInMap ? layerToPositionBefore : ''

  if (firstDrawPluginLayer) {
    beforeId = firstDrawPluginLayer.id
  }

  map.addLayer({
    id: FLIGHT_PLAN_GEOMETRY_HIDDEN_ID,
    source: FLIGHT_PLAN_OUTLINE_SOURCE_ID,
    type: 'fill',
    paint: {
      'fill-opacity': 0,
    },
    beforeId,
  })
  map.addLayer({
    id: FLIGHT_PLAN_GEOMETRY_ID,
    source: FLIGHT_PLAN_SOURCE_ID,
    type: 'fill',
    paint: {
      'fill-color': advisoryColors.grey,
    },
    beforeId,
  })

  map.addLayer({
    id: CONFLICT_GEOMETRY_ID,
    source: CONFLICT_SOURCE_ID,
    type: 'fill',
    paint: {
      'fill-color': conflictColors.light,
    },
    beforeId,
  })

  if (applyColorFilter) {
    const filterExpression = getFilterExpression()
    map.setPaintProperty(FLIGHT_PLAN_GEOMETRY_ID, 'fill-color', filterExpression)
  }

  map.addLayer(
    {
      id: FLIGHT_PLAN_OUTLINE_ID,
      source: FLIGHT_PLAN_OUTLINE_SOURCE_ID,
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'isConflictHighlighted'], false],
          advisoryColors.red,
          ['boolean', ['feature-state', 'isHighlighted'], false],
          advisoryColors.yellow,
          advisoryColors.black,
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'isConflictHighlighted'], false],
          2,
          ['boolean', ['feature-state', 'isHighlighted'], false],
          2,
          1,
        ],
      },
    },
    beforeId
  )
  map.addLayer(
    {
      id: CONFLICT_OUTLINE_ID,
      source: CONFLICT_SOURCE_ID,
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'isConflictHighlighted'], false],
          advisoryColors.red,
          'transparent',
        ],
        'line-width': ['case', ['boolean', ['feature-state', 'isConflictHighlighted'], false], 2, 0],
      },
    },
    beforeId
  )
  map.addSource(CLUSTERS_SOURCE_ID, {
    type: 'geojson',
    data: parseGeometriesToPoints(geometry),
    generateId: true,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
  })

  map.addLayer({
    id: CLUSTERS_ID,
    type: 'circle',
    source: CLUSTERS_SOURCE_ID,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': clusterColors.default,
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-stroke-width': 2,
      'circle-stroke-color': clusterColors.white,
    },
  })

  map.addLayer({
    id: CLUSTERS_GEOMETRY_COUNT_ID,
    type: 'symbol',
    source: CLUSTERS_SOURCE_ID,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': clusterColors.white,
    },
  })

  map.addLayer({
    id: CLUSTERS_GEOMETRY_POINT_ID,
    type: 'circle',
    source: CLUSTERS_SOURCE_ID,
    filter: ['>', ['get', 'zoom-level-to-show'], ['zoom']],
    paint: {
      'circle-color': ['get', 'point-color'],
      'circle-radius': 10,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#fff',
    },
  })
}

export const drawUpdateOrDeleteGeometryOnMap = (templateGeometry, rawFeature, map) => {
  if (!isObjectEmpty(templateGeometry)) {
    const geometryOnMap = map.getSource(FLIGHT_PLAN_SOURCE_ID)
    const clustersOnMap = map.getSource(CLUSTERS_SOURCE_ID)

    if (geometryOnMap) {
      geometryOnMap.setData(templateGeometry)

      if (clustersOnMap) {
        const newClusters = parseGeometriesToPoints(templateGeometry)

        clustersOnMap.setData(newClusters)
      }
    } else {
      drawGeometryOnMap({
        map,
        geometry: templateGeometry,
        layerToPositionBefore: MAP_LAYER_TO_POSITION_GEOMETRY_BEFORE,
      })
    }
    if (!isObjectEmpty(rawFeature)) {
      setFeatureInDrawPlugin(drawPluginInstance, rawFeature)
    }
  } else {
    removeGeometryLayers(map)
    removeClusterLayers(map)
  }
}

const removeGeometryLayers = (map) => {
  if (map.getSource(FLIGHT_PLAN_SOURCE_ID)) {
    map.removeLayer(FLIGHT_PLAN_GEOMETRY_ID)
    map.removeLayer(FLIGHT_PLAN_GEOMETRY_HIDDEN_ID)
    map.removeLayer(CONFLICT_GEOMETRY_ID)
    map.removeLayer(CONFLICT_OUTLINE_ID)
    map.removeLayer(FLIGHT_PLAN_OUTLINE_ID)
    map.removeSource(FLIGHT_PLAN_SOURCE_ID)
    map.removeSource(FLIGHT_PLAN_OUTLINE_SOURCE_ID)
    map.removeSource(CONFLICT_SOURCE_ID)
  }
}

const removeClusterLayers = (map) => {
  if (map.getSource(CLUSTERS_SOURCE_ID)) {
    map.removeLayer(CLUSTERS_GEOMETRY_POINT_ID)
    map.removeLayer(CLUSTERS_GEOMETRY_COUNT_ID)
    map.removeLayer(CLUSTERS_ID)
    map.removeSource(CLUSTERS_SOURCE_ID)
  }
}

export const checkAndSetIntersectionOnMap = (geometry, mapInstance) => {
  removeIntersectionCircleOnMap(mapInstance)

  const isGeometryTypePolygon = geometry.geometry && geometry.geometry.type === geometryTypes.POLYGON
  if (!isGeometryTypePolygon) return false

  const intersection = checkPolygonIntersection(geometry)
  if (intersection) {
    addIntersectionToMap(mapInstance, intersection)
    return true
  }
  return false
}

const removeIntersectionCircleOnMap = (map) => {
  if (map.getSource(INVALID_FLIGHT_AREA_ID)) {
    map.removeLayer(INVALID_FLIGHT_AREA_ID)
    map.removeSource(INVALID_FLIGHT_AREA_ID)
  }
}

export const setFeatureInDrawPlugin = (drawPlugin, feature) =>
  drawPlugin.set({
    type: MAP_DRAWING_TYPE_FEATURE_COLLECTION,
    features: [feature],
  })
