import React from 'react'

export const AirmapPrivacyPolicy = () => (
  <p>
    {`By clicking “ACCEPT” you consent to the use of cookies
   on your device in accordance with our `}
    <a href="https://www.airmap.com/privacy-policy/" target="_blank" rel="noreferrer">{`cookie policy`}</a>
    {`. You can change your cookie settings at any time but parts of our site and services will not function correctly without them.`}
  </p>
)
