/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import settings from 'settings'

import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/es'
import '@formatjs/intl-pluralrules/locale-data/ja'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-pluralrules/locale-data/it'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/it'

import enTranslationMessages from '../translations/en.json'
import esTranslationMessages from '../translations/es.json'
import jaTranslationMessages from '../translations/ja.json'
import deTranslationMessages from '../translations/de.json'
import frTranslationMessages from '../translations/fr.json'
import itTranslationMessages from '../translations/it.json'
import frChTranslationMessages from '../translations/fr-ch.json'
import ptTranslationMessages from '../translations/pt-pt.json'
import { languageCodes } from '../translations/translations.constants'

const DEFAULT_LOCALE = settings.default_locale || languageCodes.en

export const appLocales = [
  languageCodes.en,
  languageCodes.es,
  languageCodes.ja,
  languageCodes.de,
  languageCodes.fr,
  languageCodes.it,
  languageCodes.ptPT,
  languageCodes.frCH
]

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

export const translationMessages = {
  en: formatTranslationMessages(languageCodes.en, enTranslationMessages),
  es: formatTranslationMessages(languageCodes.es, esTranslationMessages),
  ja: formatTranslationMessages(languageCodes.ja, jaTranslationMessages),
  de: formatTranslationMessages(languageCodes.de, deTranslationMessages),
  fr: formatTranslationMessages(languageCodes.fr, frTranslationMessages),
  it: formatTranslationMessages(languageCodes.it, itTranslationMessages),
  'pt-pt': formatTranslationMessages(languageCodes.ptPT, ptTranslationMessages),
  'fr-ch': formatTranslationMessages(languageCodes.frCH, frChTranslationMessages)
}
