import PropTypes from 'prop-types'
import { buttonSizes, buttonVariants } from 'ui-styling/components/buttons/constants/buttons.contants'

const possibleButtonVariants = Object.values(buttonVariants)
const possibleButtonSizes = Object.values(buttonSizes)

export const buttonPropTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  variant: PropTypes.oneOf(possibleButtonVariants),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(possibleButtonSizes),
  className: PropTypes.string,
  intent: PropTypes.oneOf(['default', 'destructive']),
  bold: PropTypes.bool,
  onMouseDown: PropTypes.func
}

export const buttonDefaultPropTypes = {
  id: '',
  disabled: false,
  onClick: () => {},
  buttonText: '',
  variant: buttonVariants.PRIMARY,
  icon: '',
  iconPosition: 'left',
  fullWidth: false,
  size: buttonSizes.MEDIUM,
  className: '',
  intent: 'default',
  bold: false,
  onMouseDown: () => {}
}
