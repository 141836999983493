export const columnNames = {
  ID: 'id',
  PRIORITY: 'priority',
  READ_STATE: 'read_state',
  STATE: 'state',
  NAME: 'name',
  ACTIVITY_TYPE: 'activity_type',
  AIRCRAFT_CATEGORY: 'aircraft_category',
  SUBMITTED: 'submitted_at',
  REFERENCE: 'reference',
  LOCATION: 'location',
  DATE: 'start_at',
  TIME: 'time',
  ALTITUDE: 'altitude',
  MAX_ALTITUDE: 'max_altitude',
  ACTIVITY_START_DATE: 'activity_start_date',
  ACTIVITY_END_DATE: 'activity_end_date',
  CONTACT_NAME: 'contact_name',
  COMPANY: 'company',
  FILES: 'files',
  ASSIGNMENTS: 'assignments',
  PREFERENCE: 'preference',
  USER_ROLE: 'user_role',
}

type ColumnNamesKeys = keyof typeof columnNames
export type ColumnNamesValues = typeof columnNames[ColumnNamesKeys]
