import * as types from './profile.types'

export const initialState = {
  profileData: {},
  profileUpdateErrorType: '',
  profileUpdateMessage: '',
  hasProfileBeenUpdated: false,

  phoneNumber: '',
  openPhoneVerificationModal: false,
  phoneNumberError: false,
  phoneNumberErrorMessage: '',
  hasPhoneNumberBeenUpdated: false,

  showEnterSMSCodeInput: false,
  smsToken: '',
  SMSCodeError: false,
  SMSCodeErrorMessage: '',
  hasSMSCodeBeenSent: false
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...action.data
        }
      }
    case types.SET_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profileUpdateMessage: action.profileUpdateMessage,
        hasProfileBeenUpdated: true
      }
    case types.SET_PROFILE_UPDATE_ERROR_TYPE:
      return {
        ...state,
        hasProfileBeenUpdated: true,
        profileUpdateErrorType: action.profileUpdateErrorType
      }
    case types.CLEAR_PROFILE_UPDATE_ERROR_TYPE:
      return {
        ...state,
        hasProfileBeenUpdated: false,
        profileUpdateErrorType: ''
      }
    case types.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber || ''
      }
    case types.OPEN_PHONE_VERIFICATION_MODAL:
      return {
        ...state,
        openPhoneVerificationModal: true
      }
    case types.HIDE_PHONE_VERIFICATION_MODAL:
      return {
        ...state,
        openPhoneVerificationModal: false
      }
    case types.SET_PHONE_NUMBER_ERROR:
      return {
        ...state,
        hasPhoneNumberBeenUpdated: true,
        phoneNumberError: true,
        phoneNumberErrorMessage: action.phoneNumberErrorMessage || ''
      }
    case types.CLEAR_PHONE_NUMBER_ERROR:
      return {
        ...state,
        hasPhoneNumberBeenUpdated: false,
        phoneNumberError: false,
        phoneNumberErrorMessage: ''
      }
    case types.CLEAR_PHONE_VERIFICATION_MODAL_VALUES:
      return {
        ...state,
        smsToken: '',
        showEnterSMSCodeInput: false,
        phoneNumberError: false,
        phoneNumber: '',
        phoneNumberErrorMessage: '',
        SMSCodeError: false,
        SMSCodeErrorMessage: '',
        hasPhoneNumberBeenUpdated: false,
        hasSMSCodeBeenSent: false
      }
    case types.SHOW_ENTER_SMS_CODE:
      return {
        ...state,
        showEnterSMSCodeInput: true
      }
    case types.HIDE_ENTER_SMS_CODE:
      return {
        ...state,
        showEnterSMSCodeInput: false
      }
    case types.SET_SMS_TOKEN:
      return {
        ...state,
        smsToken: action.value
      }
    case types.SET_SMS_CODE_ERROR:
      return {
        ...state,
        SMSCodeError: true,
        hasSMSCodeBeenSent: true,
        SMSCodeErrorMessage: action.errorMessage
      }
    case types.CLEAR_SMS_CODE_ERROR:
      return {
        ...state,
        SMSCodeError: false,
        hasSMSCodeBeenSent: false,
        SMSCodeErrorMessage: ''
      }
    default:
      return state
  }
}
