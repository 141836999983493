import React from 'react'
import { privacyPolicyLink, termsAndConditionsLink } from "./common"

const privacyPolicyContent = () => (
  <>
    <p>
      {`L’app skyguide è supportata da AirMap. Per continuare a utilizzare l’app, l'utente deve dichiarare di accettare l'`}
      <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
        informativa sulla privacy
      </a>
      {' i '}
      <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
        termini e condizioni
      </a>
      .{` di skyguide cliccando su «ACCETTO».  `}
      {`È possibile modificare le impostazioni dei cookie in qualsiasi momento, ma in tal caso parti del sito e dei servizi non funzioneranno correttamente. `}
      {`Se non è d'accordo con le informative, i termini e le condizioni, l’utente è pregato di non utilizzare l'app skyguide.`}
    </p>
    <p>
      {`L’utente che fa volare il proprio drone è tenuto a rispettare integralmente tutte le normative applicabili. `}
      {`I contenuti, le informazioni e i dati sono forniti senza garanzia di alcun tipo. `}
      {`Skyguide e i suoi fornitori terzi declinano ogni responsabilità nella misura consentita dalla legge.`}
    </p>
  </>
)

export default privacyPolicyContent
