import { operationRouteTypes } from './routes.constants'
import { roles } from 'projects/SFO/modules/auth/constants/roles.constants'
import { templateListFilters } from '../../template-list/constants/template-list.constants'
import templateStatusIconStyles from 'projects/SFO/modules/shared-components/TemplateStatusIcon/TemplateStatusIcon.module.scss'
import { templateStatuses } from './templates.constants'

export const operationTypes = {
  PLAN: 'plan',
  TEMPLATE: 'template',
} as const

export type OperationType = typeof operationTypes[keyof typeof operationTypes]

export const operationTypesRoutes = {
  [operationTypes.PLAN]: operationRouteTypes.ACTIVITIES,
  [operationTypes.TEMPLATE]: operationRouteTypes.REQUESTS,
}

export const templateSelectedDefaultFilters = [
  templateListFilters.STATES,
  templateListFilters.PRIORITY,
  templateListFilters.MULTI_SEARCH,
  templateListFilters.DATE_RANGE,
  templateListFilters.TIME_RANGE,
  templateListFilters.AIRCRAFT_CATEGORY,
]

export const planSelectedDefaultFilters = [
  templateListFilters.MULTI_SEARCH,
  templateListFilters.DATE_TIME_RANGE,
  templateListFilters.AIRCRAFT_CATEGORY,
  templateListFilters.SECTORS,
]

export const planRoleSelectedDefaultFilters = {
  [roles.CONTACT]: [
    ...planSelectedDefaultFilters,
    templateListFilters.STATES,
    templateListFilters.PREFERENCE,
    templateListFilters.READ_STATE,
    templateListFilters.ASSIGNED_TO,
  ],
  [roles.CONSULTANT]: [...planSelectedDefaultFilters, templateListFilters.STATES, templateListFilters.READ_STATE],
  [roles.REVIEWER]: [
    ...planSelectedDefaultFilters,
    templateListFilters.STATES,
    templateListFilters.READ_STATE,
    templateListFilters.ASSIGNED_TO,
  ],
  [roles.OPERATOR]: [...planSelectedDefaultFilters, templateListFilters.STATES].filter(
    (filter) => filter !== templateListFilters.PRIORITY
  ),
}

export const templateRoleSelectedDefaultFilters = {
  [roles.OPERATOR]: templateSelectedDefaultFilters.filter((filter) => filter !== templateListFilters.PRIORITY),
  [roles.REVIEWER]: [
    ...templateSelectedDefaultFilters,
    templateListFilters.READ_STATE,
    templateListFilters.ASSIGNED_TO,
    templateListFilters.ASSIGNED_ATC_UNIT,
  ],
  [roles.CONSULTANT]: [
    ...templateSelectedDefaultFilters,
    templateListFilters.READ_STATE,
    templateListFilters.ASSIGNED_TO,
    templateListFilters.ASSIGNED_ATC_UNIT,
  ],
}

export const planMultiSearchFilterOptions = [
  templateListFilters.REFERENCE_ID,
  templateListFilters.FIRST_NAME,
  templateListFilters.LAST_NAME,
  templateListFilters.LOCATION,
  templateListFilters.NAME,
  templateListFilters.COMPANY_NAME,
]

export const laancLiteIdentifier = 'flight_plan|'

export const templatePillStyles = {
  [templateStatuses.ACCEPTED]: templateStatusIconStyles.inReview,
}
