export const daySorting = [
  'day_monday',
  'day_tuesday',
  'day_wednesday',
  'day_thursday',
  'day_friday',
  'day_saturday',
  'day_sunday',
  'day_work_day',
  'day_before_work_day',
  'day_after_work_day',
  'day_holiday',
  'day_before_holiday',
  'day_after_holiday',
  'day_busy_friday',
  'day_any'
]
