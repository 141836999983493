import React from 'react'

const Snow = () => (
  <g fillRule="evenodd">
    <path
      strokeWidth="2"
      d="M86.9451109,29.6136186 C84.0142225,29.6136186 81.0833333,29.9770791 78.3333333,30.8980093 C72.0975558,17.4597953 58.3475556,8.61395349 43.5,8.61395349 C22.2357778,8.61395349 5,25.9298977 5,47.2930232 C5,64.2332276 16.1808889,79.3438513 32.3191111,84.3119625 C33.7882222,84.6778789 35.25,83.9485027 35.8048889,82.4701027 C36.1666667,80.9941579 35.4406667,79.5255816 33.9715556,78.9681119 C20.0284444,74.7392 10.5,61.846177 10.5,47.2930232 C10.5,29.0561488 25.3475556,14.1395349 43.5,14.1395349 C56.3333333,14.1395349 68.0691109,21.688707 73.3882225,33.2973395 L74.4857775,35.5026605 C75.0382225,36.789507 76.5,37.3445209 77.7882225,36.9810605 L80.1666667,36.2418605 C82.3617775,35.5026605 84.75,35.1392 87.1382225,35.1392 C99.6073333,35.1392 109.690667,45.2694326 109.690667,57.7965395 C109.690667,70.3162789 99.4166667,80.4465119 86.9451109,80.4465119 C85.476,80.4465119 84.1951109,81.7309027 84.1951109,83.2093027 C84.1951109,84.6877027 85.476,85.9720927 86.9451109,85.9720927 C102.347556,85.9720927 115,73.2608 115,57.7867162 C115,42.3249116 102.347556,29.6136186 86.9451109,29.6136186 L86.9451109,29.6136186 Z"
    />
    <polygon points="59.218 79.331 58.564 78.198 56.416 79.438 52.511 77.184 56.416 74.929 58.562 76.168 59.216 75.036 57.725 74.174 60 72.86 59.346 71.728 57.067 73.042 57.067 71.313 55.76 71.313 55.76 73.798 51.849 76.056 51.849 71.539 54.002 70.297 53.35 69.165 51.849 70.031 51.849 67.4 50.542 67.4 50.542 70.027 49.048 69.165 48.396 70.299 50.542 71.538 50.542 76.046 46.636 73.792 46.636 71.313 45.327 71.313 45.327 73.036 43.054 71.724 42.4 72.856 44.677 74.17 43.182 75.034 43.836 76.168 45.986 74.926 49.895 77.184 45.986 79.442 43.832 78.2 43.18 79.333 44.677 80.197 42.402 81.512 43.056 82.643 45.327 81.331 45.327 83.054 46.636 83.054 46.636 80.576 50.542 78.321 50.542 82.832 48.398 84.068 49.05 85.2 50.542 84.34 50.542 86.967 51.849 86.967 51.849 84.338 53.346 85.202 54 84.068 51.849 82.828 51.849 78.312 55.76 80.57 55.76 83.054 57.067 83.054 57.067 81.325 59.346 82.64 59.998 81.508 57.725 80.193" />
    <polygon points="76.818 92.531 76.164 91.398 74.016 92.638 70.111 90.384 74.016 88.129 76.162 89.368 76.816 88.236 75.325 87.374 77.6 86.06 76.946 84.928 74.667 86.242 74.667 84.513 73.36 84.513 73.36 86.998 69.449 89.256 69.449 84.739 71.602 83.497 70.95 82.365 69.449 83.231 69.449 80.6 68.142 80.6 68.142 83.227 66.648 82.365 65.996 83.499 68.142 84.738 68.142 89.246 64.236 86.992 64.236 84.513 62.927 84.513 62.927 86.236 60.654 84.924 60 86.056 62.277 87.37 60.782 88.234 61.436 89.368 63.586 88.126 67.495 90.384 63.586 92.642 61.432 91.4 60.78 92.533 62.277 93.397 60.002 94.712 60.656 95.843 62.927 94.531 62.927 96.254 64.236 96.254 64.236 93.776 68.142 91.521 68.142 96.032 65.998 97.268 66.65 98.4 68.142 97.54 68.142 100.167 69.449 100.167 69.449 97.538 70.946 98.402 71.6 97.268 69.449 96.028 69.449 91.512 73.36 93.77 73.36 96.254 74.667 96.254 74.667 94.525 76.946 95.84 77.598 94.708 75.325 93.393" />
    <polygon points="52.618 103.531 51.964 102.398 49.816 103.638 45.911 101.384 49.816 99.129 51.962 100.368 52.616 99.236 51.125 98.374 53.4 97.06 52.746 95.928 50.467 97.242 50.467 95.513 49.16 95.513 49.16 97.998 45.249 100.256 45.249 95.739 47.402 94.497 46.75 93.365 45.249 94.231 45.249 91.6 43.942 91.6 43.942 94.227 42.448 93.365 41.796 94.499 43.942 95.738 43.942 100.246 40.036 97.992 40.036 95.513 38.727 95.513 38.727 97.236 36.454 95.924 35.8 97.056 38.077 98.37 36.582 99.234 37.236 100.368 39.386 99.126 43.295 101.384 39.386 103.642 37.232 102.4 36.58 103.533 38.077 104.397 35.802 105.712 36.456 106.843 38.727 105.531 38.727 107.254 40.036 107.254 40.036 104.776 43.942 102.521 43.942 107.032 41.798 108.268 42.45 109.4 43.942 108.54 43.942 111.167 45.249 111.167 45.249 108.538 46.746 109.402 47.4 108.268 45.249 107.028 45.249 102.512 49.16 104.77 49.16 107.254 50.467 107.254 50.467 105.525 52.746 106.84 53.398 105.708 51.125 104.393" />
  </g>
)

export default Snow
