import React from 'react'
import { airmapIntl } from 'libs/airmap-intl'
import { WarningInfoBoxMessages } from './WarningInfoBox.messages'
import { Alert } from 'modules/icons/components/Alert/Alert.component'

import { warning_info_box__wrapper, warning_info_box__icon, warning_info_box__text } from './WarningInfoBox.module.scss'

export const WarningInfoBox = () => {
  return (
    <div className={warning_info_box__wrapper}>
      <Alert className={warning_info_box__icon} />
      <p className={warning_info_box__text}>
        {airmapIntl.translateMessage(WarningInfoBoxMessages.warning_info_box_label)}
      </p>
    </div>
  )
}
