import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const Clock = ({ color }) => {
  return (
    <svg
      className={icon}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M12,2 C14.6521649,2 17.195704,3.0535684 19.0710678,4.92893219 C20.9464316,6.80429597 22,9.3478351 22,12 C22,17.5228475 17.5228475,22 12,22 C6.47,22 2,17.5 2,12 C2,6.4771525 6.4771525,2 12,2 L12,2 Z M12.5555556,6.44444444 L12.5555556,12.2777778 L17.5555556,15.2444444 L16.7222222,16.6111111 L10.8888889,13.1111111 L10.8888889,6.44444444 L12.5555556,6.44444444 Z"
          id="path-clock"
          fill={color}
        />
      </defs>
      <g id="Icon/clock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2">
          <use xlinkHref="#path-clock" />
        </mask>
        <use xlinkHref="#path-clock" />
      </g>
    </svg>
  )
}

Clock.defaultProps = {
  color: 'currentColor'
}

Clock.propTypes = {
  color: PropTypes.string
}
