import { airmapIntl } from 'airmap-intl'
import { TemplateListMessages } from 'projects/SFO/modules/template-list/TemplateList.messages'

export const templateListFilters = {
  REFERENCE_ID: 'reference_id',
  START_BEFORE_DATE: 'start_before_date',
  DATE_RANGE: 'date_range',
  START_AFTER_DATE: 'start_after_date',
  END_BEFORE_DATE: 'end_before_date',
  END_AFTER_DATE: 'end_after_date',
  START_BEFORE_TIME: 'start_before_time',
  START_AFTER_TIME: 'start_after_time',
  END_BEFORE_TIME: 'end_before_time',
  END_AFTER_TIME: 'end_after_time',
  DATE_TIME_RANGE: 'date_time_range',
  TIME_RANGE: 'time',
  STATES: 'states',
  READ_STATE: 'read_state',
  NAME: 'name',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  COMPANY_NAME: 'company_name',
  MULTI_SEARCH: 'multi_search',
  ROLE: 'role',
  AUTHORITY_PARAMS: 'authority_params',
  ASSIGNED_TO: 'assigned_to',
  START_DATE_TIME: 'start_date_time',
  END_DATE_TIME: 'end_date_time',
  LOCATION: 'location',
  AIRCRAFT_CATEGORY: 'aircraft_category',
  ASSIGNED_ATC_UNIT: 'assigned_atc_unit',
  PRIORITY: 'priority',
  PREFERENCE: 'preference',
  SECTORS: 'sectors',
}

export const multiSearchFilterDefaultOptions = [templateListFilters.REFERENCE_ID, templateListFilters.COMPANY_NAME]

export const LIST_OPERATION_CHANGE_EVENT_KEY = 'LIST_OPERATION_CHANGE_EVENT'
export const LIST_OPERATION_CHANGE_EVENT = new Event(LIST_OPERATION_CHANGE_EVENT_KEY)

export const UNASSIGNED_USER_ID = 'unassigned'

export const unassignedUserListItem = {
  id: UNASSIGNED_USER_ID,
  name: airmapIntl.translateMessage(TemplateListMessages.unassigned),
  nameKey: TemplateListMessages.unassigned,
}
