export type StatusPage = {
  token: string,
  api: string,
  pageUrl: string
}

const statusPage: StatusPage = {
  token: import.meta.env.STATUS_PAGE_API_TOKEN || "",
  api: "https://api.statuspage.io/v1",
  pageUrl: "https://api.statuspage.io/v1/pages/14g1k5z9wvsj"
};

export default statusPage;
