import { templateStatuses } from '../../core/constants/templates.constants'

export const geometryTypes = {
  POLYGON: 'Polygon',
  LINE_STRING: 'LineString',
  POINT: 'Point',
}
export const drawModes = {
  DRAW_POINT: 'draw_point',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_LINE: 'draw_line_string',
  SIMPLE_SELECT: 'simple_select',
  DIRECT_SELECT: 'direct_select',
}

export const drawLayerConstants = {
  INVALID_FLIGHT_AREA_ID: 'invalid-flight-area-id',
  FLIGHT_PLAN_SOURCE_ID: 'flight-planning-geometry-source-id',
  FLIGHT_PLAN_GEOMETRY_ID: 'flight-planning-geometry-id',
  FLIGHT_PLAN_GEOMETRY_HIDDEN_ID: 'flight-planning-geometry-hidden-id',
  FLIGHT_PLAN_OUTLINE_SOURCE_ID: 'flight-planning-outline-source-id',
  FLIGHT_PLAN_OUTLINE_ID: 'flight-planning-geometry-outline-id',
  CLUSTERS_SOURCE_ID: 'geometry-clusters-source-id',
  CLUSTERS_ID: 'geometry-clusters-layer-id',
  CLUSTERS_GEOMETRY_COUNT_ID: 'geometry-cluster-count-id',
  CLUSTERS_GEOMETRY_POINT_ID: 'geometry-cluster-point-id',
  CONFLICT_SOURCE_ID: 'conflict-source-id',
  CONFLICT_GEOMETRY_ID: 'conflict-geometry-id',
  CONFLICT_OUTLINE_ID: 'conflict-outline-id',
}

export const MAP_DRAWING_TYPE_FEATURE_COLLECTION = 'FeatureCollection'

export const MAP_LAYER_TO_POSITION_GEOMETRY_BEFORE = 'gl-draw-polygon-fill-inactive.'

export const GL_DRAW_LAYER = 'gl-draw'

const drawingStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    paint: {
      'fill-color': '#fff',
      'fill-outline-color': '#000',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fff',
      'fill-outline-color': '#fff',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#000',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 7,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
]

export const drawPluginConfig = {
  displayControlsDefault: false,
  styles: drawingStyles,
  controls: {
    polygon: false,
    trash: false,
    line_string: false,
    combine_features: false,
    uncombine_features: false,
  },
}

export const modificationColorsByState = [
  {
    state: templateStatuses.UNDER_CONSULTATION,
    modification: 'rgba(135, 143, 148, 0.7)',
  },
  {
    state: templateStatuses.REJECTED,
    modification: 'rgba(229, 58, 55, 0.7)',
  },
  {
    state: templateStatuses.RESCINDED,
    modification: 'rgba(229, 58, 55, 0.7)',
  },
  {
    state: templateStatuses.CANCELLED,
    modification: 'rgba(229, 58, 55, 0.7)',
  },
  {
    state: templateStatuses.ACCEPTED,
    modification: 'rgba(19, 167, 125, 0.7)',
  },
  {
    state: templateStatuses.IN_PROGRESS,
    modification: 'rgba(132, 143, 152, 0.7)',
  },
  {
    state: templateStatuses.SUBMITTED,
    modification: 'rgba(8, 117, 240, 0.7)',
  },
]

export const modificationColorStateFallback = 'rgba(135, 143, 148, 0.7)'

export const clusterColors = {
  [templateStatuses.SUBMITTED]: '#0875F0',
  [templateStatuses.ACCEPTED]: '#03a97c',
  [templateStatuses.IN_PROGRESS]: '#1f2b34',
  [templateStatuses.RESCINDED]: '#e53935',
  [templateStatuses.REJECTED]: '#e53935',
  [templateStatuses.CANCELLED]: '#e53935',
  [templateStatuses.TERMINATED]: '#848f98',
  white: '#fff',
  default: '#800080',
}

export const zoomByArea = {
  0: 750_000_000_000,
  1: 100_000_000_000,
  2: 80_000_000_000,
  3: 10_000_000_000,
  4: 2_000_000_000,
  5: 500_000_000,
  6: 120_000_000,
  7: 20_000_000,
  8: 10_000_000,
  9: 2_000_000,
  10: 1_000_000,
  11: 100_000,
  12: 25_000,
  13: 15_000,
  14: 10_000,
  15: 5_000,
  16: 1_000,
}

export const keyEvents = {
  ESCAPE: 'Escape',
}
