import React from 'react'

const Fog = () => (
  <svg viewBox="30 25 50 55">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.177,55.641c-0.262-0.646-0.473-1.315-0.648-2h43.47 c0,0.684-0.07,1.348-0.181,2H29.177z M36.263,35.643c2.294-1.271,4.93-1.999,7.738-1.999c2.806,0,5.436,0.73,7.727,1.999H36.263z M28.142,47.642c0.085-0.682,0.218-1.347,0.387-1.999h40.396c0.551,0.613,1.039,1.281,1.455,1.999H28.142z M29.177,43.643 c0.281-0.693,0.613-1.359,0.984-2h27.682c0.04,0.068,0.084,0.135,0.123,0.205c0.664-0.114,1.338-0.205,2.033-0.205 c2.451,0,4.729,0.738,6.627,2H29.177z M31.524,39.643c0.58-0.723,1.225-1.388,1.92-2h21.122c0.69,0.61,1.326,1.28,1.903,2H31.524z M71.817,51.641H28.142c-0.082-0.656-0.139-1.32-0.139-1.999h43.298C71.528,50.285,71.702,50.953,71.817,51.641z M71.301,57.641 c-0.247,0.699-0.555,1.367-0.921,2H31.524c-0.505-0.629-0.957-1.299-1.363-2H71.301z M33.444,61.641h35.48 c-0.68,0.758-1.447,1.434-2.299,1.999H36.263C35.247,63.078,34.309,62.4,33.444,61.641z"
    />
  </svg>
)

export default Fog
