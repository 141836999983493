import React from 'react'

const Wind = () => (
  <path
    fillRule="evenodd"
    d="M28.3927051,21.651302 C33.1392482,21.651302 36.9972312,24.9671107 36.9972312,29.0466074 L48.2447703,29.0466074 C48.2447703,19.6407765 39.3365018,12 28.3927051,12 C15.4995165,12 5,21.0239869 5,32.1052558 C5,45.3022522 17.4851764,56.0328481 32.8355058,56.0328481 L115,56.0328489 L115,46.3659614 L32.8536396,46.3659605 C23.7005637,46.3659605 16.2430056,39.9759179 16.2430056,32.0896703 C16.2430056,26.338632 21.69677,21.651302 28.3927051,21.651302 Z M99.1193706,64.4801726 L70.4134389,64.4801726 C55.0631092,64.4801726 42.5779328,75.2107683 42.5779328,88.4038687 C42.5779328,99.5007225 53.0774493,108.509124 65.970638,108.509124 C76.9144342,108.509124 85.8227033,100.872244 85.8227033,91.4625169 L74.575164,91.4625169 C74.575164,95.5420138 70.7171812,98.8461335 65.970638,98.8461335 C59.2747028,98.8461335 53.8209384,94.1588032 53.8209384,88.4038687 C53.8209384,80.5526884 61.260363,74.1431634 70.4315726,74.1431634 L114.676338,74.1431641 L114.676338,64.4801726 L99.1193706,64.4801726 Z"
  />
)

export default Wind
