import { defineMessages } from 'react-intl'

export const TimeRangeFilterMessages = defineMessages({
  title: {
    id: 'TimeRangeFilter.title',
    defaultMessage: 'Time Range'
  },
  rangeTitle: {
    id: 'TimeRangeFilter.rangeTitle',
    defaultMessage: 'Select Start and End Time'
  },
  today: {
    id: 'TimeRangeFilter.today',
    defaultMessage: 'Today'
  },
  tomorrow: {
    id: 'TimeRangeFilter.tomorrow',
    defaultMessage: 'Tomorrow'
  },
  pst: {
    id: 'TimeRangeFilter.pst',
    defaultMessage: 'PST'
  },
  next_1h: {
    id: 'TimeRangeFilter.next_1h',
    defaultMessage: 'Next 1h'
  },
  next_4h: {
    id: 'TimeRangeFilter.next_4h',
    defaultMessage: 'Next 4h'
  },
  next_8h: {
    id: 'TimeRangeFilter.next_8h',
    defaultMessage: 'Next 8h'
  },
  next_12h: {
    id: 'TimeRangeFilter.next_12h',
    defaultMessage: 'Next 12h'
  },
  custom: {
    id: 'TimeRangeFilter.custom',
    defaultMessage: 'Custom'
  },
  errorMessageStartTime: {
    id: 'TimeRangeFilter.errorMessageStartTime',
    defaultMessage: 'Start time must be after the current time'
  },
  errorMessageEndTime: {
    id: 'TimeRangeFilter.errorMessageEndTime',
    defaultMessage: 'End time must be after the start time'
  }
})
