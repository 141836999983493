import { airmapConfigMap } from './airmap/airmap.config'
import { skyguideConfigMap } from './skyguide/skyguide.config'

import { WHITE_LABEL_ENV } from '@env'

const whiteLabelEnv = WHITE_LABEL_ENV || 'airmap'

const whiteLabelConfig = {
  airmap: airmapConfigMap,
  skyguide: skyguideConfigMap,
}

const airmapWhiteLabelConfig = whiteLabelConfig['airmap']

export const {
  sfo: sfoWhiteLabelConfig = airmapWhiteLabelConfig.sfo
} = whiteLabelConfig[whiteLabelEnv]

