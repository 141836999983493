import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { TimePicker } from '@material-ui/pickers'
import { airmapIntl } from 'libs/airmap-intl'
import { TimeRangeFilterMessages } from './TimeRangeFilter.messages'
import { ArrowNext } from 'modules/icons/components/ArrowNext/ArrowNext.component'
import { predefinedRanges } from './TimeRangeFilter.constants'
import { timestampFormatting } from 'modules/core/constants/date-time.constants'
import { getDeviceTimeZone } from 'modules/core/helpers/date-time.helpers'
import moment from 'moment'
import {
  timeRangePickerWrapper,
  title,
  rangeWrapper,
  timePicker,
  rangeTitle,
  predefinedRangesWrapper,
  predefinedRange,
  predefinedRangeDisabled,
  predefinedRangeSelected,
  timePickerErrorMessage
} from './TimeRangeFilter.module.scss'

export const TimeRangeFilter = ({
  handleChangeCustomRange,
  handleChangePredefinedRange,
  value,
  id,
  isTodaySelected,
  selectedPredefinedRange,
  errorMessage,
  hasUserInteracted
}) => {
  const deviceTimeZone = getDeviceTimeZone()

  const handleCustomRangeChange = useCallback(
    key => newValue => {
      handleChangeCustomRange({
        ...value,
        [key]: newValue
      })
    },
    [handleChangeCustomRange, value]
  )

  const handlePredefinedRangeChange = useCallback(
    value => () => {
      handleChangePredefinedRange(value)
    },
    [handleChangePredefinedRange]
  )

  return (
    <div className={timeRangePickerWrapper} id={`sel-wrapper-${id}-range-picker`}>
      <h2 className={title}>{airmapIntl.translateMessage(TimeRangeFilterMessages.title)}</h2>

      <div className={predefinedRangesWrapper}>
        {Object.keys(predefinedRanges).map(predefinedRangeKey => {
          const predefinedRangeValue = predefinedRanges[predefinedRangeKey]
          const isCustomOption = predefinedRangeValue === predefinedRanges.CUSTOM
          const predefinedRangeString = isCustomOption ? predefinedRangeValue : `next_${predefinedRangeValue}h`
          const isSelected = selectedPredefinedRange === predefinedRangeValue
          const isDisabled = !isTodaySelected

          return (
            <button
              key={predefinedRangeString}
              className={`${predefinedRange} ${isSelected ? predefinedRangeSelected : ''} ${isDisabled ? predefinedRangeDisabled : ''
                }`}
              disabled={isDisabled}
              onClick={handlePredefinedRangeChange(predefinedRangeValue)}
            >
              {airmapIntl.translateMessage(TimeRangeFilterMessages[predefinedRangeString])}
            </button>
          )
        })}
      </div>

      {selectedPredefinedRange === predefinedRanges.CUSTOM && (
        <>
          <p className={rangeTitle}>{airmapIntl.translateMessage(TimeRangeFilterMessages.rangeTitle)}</p>
          <div className={rangeWrapper}>
            <TimePicker
              disablePast
              variant="outlined"
              value={value.start}
              onChange={handleCustomRangeChange('start')}
              fullWidth
              className={timePicker}
              labelFunc={date => `${moment(date).format(timestampFormatting.TIME_AM_PM)} ${deviceTimeZone}`}
            />
            <ArrowNext />
            <TimePicker
              disablePast
              variant="outlined"
              value={value.end}
              onChange={handleCustomRangeChange('end')}
              fullWidth
              className={timePicker}
              labelFunc={date => `${moment(date).format(timestampFormatting.TIME_AM_PM)} ${deviceTimeZone}`}
            />
          </div>
          {hasUserInteracted && errorMessage && (
            <p className={timePickerErrorMessage}>
              {airmapIntl.translateMessage(TimeRangeFilterMessages[errorMessage])}
            </p>
          )}
        </>
      )}
    </div>
  )
}

TimeRangeFilter.defaultProps = {
  errorMessage: ''
}

TimeRangeFilter.propTypes = {
  handleChangeCustomRange: PropTypes.func.isRequired,
  handleChangePredefinedRange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired
  }).isRequired,
  id: PropTypes.string.isRequired,
  isTodaySelected: PropTypes.bool.isRequired,
  selectedPredefinedRange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  errorMessage: PropTypes.string,
  hasUserInteracted: PropTypes.bool.isRequired
}
