import { combineReducers } from '@reduxjs/toolkit'
import { templateDetailReducer } from './modules/operation-detail/store/operation-detail.reducer'
import { templateRevisionReducer } from './modules/template-revision/store/template-revision.reducer'
import { templateListReducer } from './modules/template-list/store/template-list.reducer'
import { operationCreationReducer } from './modules/operation-creation/store/operation-creation.reducer'
import { templateEvaluationReducer } from './modules/template-evaluation/store/template-evaluation.reducer'
import { templateConsultationReducer } from './modules/template-consultation/store/template-consultation.reducer'

import { mapReducer } from './modules/map/store/map.reducer'
import { coreReducer } from './modules/core/store/core.reducer'
import { userReducer } from './modules/user/store/user.reducer'
import { profileReducer } from './modules/profile/store/profile.reducer'
import { rulesReducerId, rulesPluginStore } from './modules/rules-plugin/RulesPlugin.container'

export const templateReducers = combineReducers({
  templateDetail: templateDetailReducer,
  templateRevision: templateRevisionReducer,
  templateList: templateListReducer,
  operationCreation: operationCreationReducer,
  templateEvaluation: templateEvaluationReducer,
  templateConsultation: templateConsultationReducer,
  map: mapReducer,
  core: coreReducer,
  user: userReducer,
  profile: profileReducer,
  [rulesReducerId]: rulesPluginStore
})
