import { defineMessages } from 'react-intl'

export const DatePickerMessages = defineMessages({
  today: {
    id: 'DatePicker.today',
    defaultMessage: 'Today'
  },
  tomorrow: {
    id: 'DatePicker.tomorrow',
    defaultMessage: 'Tomorrow'
  },
  date: {
    id: 'DatePicker.date',
    defaultMessage: 'Date'
  }
})
