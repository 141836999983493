import settings from 'settings'
import { mapThemes } from './controls.constants'

import { SFO_CUSTOM_MAP_COORDINATES } from '@env'
import { PALEO_RULESET, SWITZERLAND_RULESET, POLICE_VAUD_RULESET } from '../../../api-requests/rules.api-requests'

export const {
  sfoWhiteLabelConfig: {
    MapSettings: { geocoderBoundingBox },
  },
  baseMapStyles,
  appEnv,
} = settings

let defaultLocation = [6.1432, 46.2044]
// FIXME: This is just a quick workaround. We should find a better way to implement this.
if (SFO_CUSTOM_MAP_COORDINATES) {
  const customCoordinates = SFO_CUSTOM_MAP_COORDINATES.split(',')

  defaultLocation = [Number(customCoordinates[0]), Number(customCoordinates[1])]
}

export const defaultMapOptions = {
  center: defaultLocation,
  zoom: 12,
}

export const GEOMETRY_TYPE_POINT = 'Point'
export const GEOMETRY_TYPE_LINE_STRING = 'LineString'
export const GEOMETRY_TYPE_POLYGON = 'Polygon'

export const defaultMapGeometry = { type: 'Point', coordinates: defaultMapOptions.center }

export const mapboxSupportedLanguages = ['ar', 'en', 'es', 'fr', 'de', 'ja', 'ko', 'mul', 'pt', 'ru', 'zh']

export const defaultBoundsOptions = { padding: 180, linear: true }

export const defaultMapTheme = 'standard'

export const drawingTypes = {
  DRAW_POINT: 'draw_point',
  DRAW_POLYGON: 'draw_polygon',
  DRAW_LINE: 'draw_line_string',
}

export const bufferInputFormattingByMeasurement = {
  imperial: {
    unit: 'ft',
    conversion_factor: 0.3048,
    defaultInputValue: 30.48,
  },
  metric: {
    unit: 'm',
    conversion_factor: 1,
    defaultInputValue: 200,
  },
}

export const advisoryColors = {
  green: 'rgba(79, 255, 63, .7)', //#4fff3f
  orange: 'rgba(255, 170, 0, .7)', //#FFAA00
  yellow: 'rgba(255, 255, 0, .7)', //#ffff00
  red: 'rgba(208, 1, 27, .7)', //#d0011b
  grey: 'rgba(51, 63, 72, .7)', // #333f48
  teal: 'rgba(136, 219, 223, .7)', //#88dbdf
  black: 'rgb(0, 0, 0)', //#000
}

export const conflictColors = {
  light: 'rgba(208, 1, 27, .7)',
}

export const storageKeys = {
  LAYERS_KEY: 'layersVisibilityConfig',
  MAP_THEME_KEY: 'mapTheme',
  OPERATION_DETAIL_KEY: 'operation-detail',
  DAY_OF_FLIGHT_KEY: 'contact-dof',
  DAY_MINUS_ONE_KEY: 'contact-d-1',
  CONTACT_ANY_DAY: 'contact-any-day',
  DAY_OF_FLIGHT_REVIEWER_KEY: 'dof-reviewer',
  REVIEWER_PLANS_KEY: 'reviewer-plans',
  OPERATOR_PLANS_KEY: 'operator-plans',
  AMSL_MEASURE_IS_SELECTED_KEY: 'amsl-measure-is-selected',
}

export const vectorSourcesUrls = {
  TWR_APP_CHART_POINT: {
    id: 'twr_app_chart_point',
    type: 'symbol',
    url: 'mapbox://ugwy-support.twr_app_chart_point',
  },
  TWR_APP_CHART_POLYGON: {
    id: 'twr_app_chart_polygon',
    type: 'fill',
    url: 'mapbox://ugwy-support.twr_app_chart_polygon',
  },
  TWR_APP_CHART_LINE_STRING: {
    id: 'twr_app_chart_linestring',
    type: 'line',
    url: 'mapbox://ugwy-support.twr_app_chart_linestring',
  },
  GVA_UPPER_CHART_LINE_STRING: {
    id: 'gva_upper_chart_linestring',
    type: 'line',
    url: 'mapbox://ugwy-support.gva_upper_chart_linestring',
  },
  GVA_UPPER_CHART_POLYGON: {
    id: 'gva_upper_chart_polygon',
    type: 'fill',
    url: 'mapbox://ugwy-support.gva_upper_chart_polygon',
  },
  GVA_UPPER_CHART_POINT: {
    id: 'gva_upper_chart_point',
    type: 'symbol',
    url: 'mapbox://ugwy-support.gva_upper_chart_point',
  },
  ZRH_LOWER_CHART_LINE_STRING: {
    id: 'zrh_lower_chart_linestring',
    type: 'line',
    url: 'mapbox://ugwy-support.zrh_lower_chart_linestring',
  },
  ZRH_LOWER_CHART_POLYGON: {
    id: 'zrh_lower_chart_polygon',
    type: 'fill',
    url: 'mapbox://ugwy-support.zrh_lower_chart_polygon',
  },
  ZRH_LOWER_CHART_POINT: {
    id: 'zrh_lower_chart_point',
    type: 'symbol',
    url: 'mapbox://ugwy-support.zrh_lower_chart_point',
  },
  ZRH_UPPER_CHART_LINE_STRING: {
    id: 'zrh_upper_chart_linestring',
    type: 'line',
    url: 'mapbox://ugwy-support.zrh_upper_chart_linestring',
  },
  ZRH_UPPER_CHART_POLYGON: {
    id: 'zrh_upper_chart_polygon',
    type: 'fill',
    url: 'mapbox://ugwy-support.zrh_upper_chart_polygon',
  },
  ZRH_UPPER_CHART_POINT: {
    id: 'zrh_upper_chart_point',
    type: 'symbol',
    url: 'mapbox://ugwy-support.zrh_upper_chart_point',
  },
  GVA_LOWER_CHART_LINE_STRING: {
    id: 'gva_lower_chart_linestring',
    type: 'line',
    url: 'mapbox://ugwy-support.gva_lower_chart_linestring',
  },
  GVA_LOWER_CHART_POLYGON: {
    id: 'gva_lower_chart_polygon',
    type: 'fill',
    url: 'mapbox://ugwy-support.gva_lower_chart_polygon',
  },
  GVA_LOWER_CHART_POINT: {
    id: 'gva_lower_chart_point',
    type: 'symbol',
    url: 'mapbox://ugwy-support.gva_lower_chart_point',
  },
}

// SUSI-362: Display vector layers only on TEST
export const vectorLayers =
  appEnv !== 'test'
    ? {}
    : {
        TWR_APP_CHART: {
          id: 'twr_app_chart',
          sources: [
            vectorSourcesUrls.TWR_APP_CHART_POINT,
            vectorSourcesUrls.TWR_APP_CHART_LINE_STRING,
            vectorSourcesUrls.TWR_APP_CHART_POLYGON,
          ],
        },
        GVA_UPPER_CHART: {
          id: 'gva_upper_chart',
          sources: [
            vectorSourcesUrls.GVA_UPPER_CHART_POINT,
            vectorSourcesUrls.GVA_UPPER_CHART_LINE_STRING,
            vectorSourcesUrls.GVA_UPPER_CHART_POLYGON,
          ],
        },
        GVA_LOWER_CHART: {
          id: 'gva_lower_chart',
          sources: [
            vectorSourcesUrls.GVA_LOWER_CHART_POINT,
            vectorSourcesUrls.GVA_LOWER_CHART_LINE_STRING,
            vectorSourcesUrls.GVA_LOWER_CHART_POLYGON,
          ],
        },
        ZRH_LOWER_CHART: {
          id: 'zrh_lower_chart',
          sources: [
            vectorSourcesUrls.ZRH_LOWER_CHART_POINT,
            vectorSourcesUrls.ZRH_LOWER_CHART_LINE_STRING,
            vectorSourcesUrls.ZRH_LOWER_CHART_POLYGON,
          ],
        },
        ZRH_UPPER_CHART: {
          id: 'zrh_upper_chart',
          sources: [
            vectorSourcesUrls.ZRH_UPPER_CHART_POINT,
            vectorSourcesUrls.ZRH_UPPER_CHART_LINE_STRING,
            vectorSourcesUrls.ZRH_UPPER_CHART_POLYGON,
          ],
        },
      }

export const layers = {
  ICAO_CHART: {
    id: 'icao_chart',
    url: 'mapbox://ugwy-support.dzjfoi4o',
  },
  TWR_APP_CHART: {
    id: 'twr_app_chart',
    url: 'mapbox://ugwy-support.1szdwd62',
  },
  LOWER_ENROUTE_CHART_LAYER: {
    id: 'lower_enroute_chart',
    url: 'mapbox://ugwy-support.60si19eh',
  },
  GVA_LOWER_CHART: {
    id: 'gva_lower_chart',
    url: 'mapbox://ugwy-support.db9etlaj',
  },
  ZRH_LOWER_CHART: {
    id: 'zrh_lower_chart',
    url: 'mapbox://ugwy-support.arkv5fij',
  },
  UPPER_ENROUTE_CHART_LAYER: {
    id: 'upper_enroute_chart',
    url: 'mapbox://ugwy-support.1mb37xq8',
  },
  GVA_UPPER_CHART: {
    id: 'gva_upper_chart',
    url: 'mapbox://ugwy-support.4377ztt3',
  },
  ZRH_UPPER_CHART: {
    id: 'zrh_upper_chart',
    url: 'mapbox://ugwy-support.38kuswmm',
  },
}
type LayersKeys = keyof typeof layers

export const globalLayersById = Object.keys(layers).reduce(
  (layersById, layerName) => ({
    ...layersById,
    [layers[layerName as LayersKeys].id]: layers[layerName as LayersKeys],
  }),
  {}
)

export const layerTypes = {
  AIRMAP: 'airmap',
  GLOBALS: 'Globals',
}

export type LayerTypesValues = typeof layerTypes[keyof typeof layerTypes]

export const layerClassificationTypes = {
  AIRPORT: 'airport',
  CONTROLLED_AIRSPACE: 'controlled_airspace',
  HELIPORT: 'heliport',
  SPECIAL_USE_AIRSPACE: 'special_use_airspace',
  C0_AREAS: 'c0_areas',
  PALEO: 'paleo',
  COUNTY: 'county',
}

export const layerFilters = {
  CLASSIFICATION_TYPES: 'classification_types',
  LAANC: 'laanc',
}

export type LayerFiltersKeys = keyof typeof layerFilters
export type LayerFiltersValues = typeof layerFilters[LayerFiltersKeys]

export const controlledAirspaceSubTypes = ['b', 'c', 'd', 'e']

export const controlledAirspaceSubTypesOnLayersMenu = ['c', 'd']

export const measureDistanceUnits = {
  imperial: 'feet',
  metric: 'kilometers',
  nauticalMiles: 'nautical miles',
}

export const templateMapId = 'template-map-div'

export const mapboxUsernames = {
  AIRMAP: 'airmap',
  MAPBOX: 'mapbox',
}
type MapBoxUsernamesKeys = keyof typeof mapboxUsernames
export type MapboxUsernamesValues = typeof mapboxUsernames[MapBoxUsernamesKeys]

const airmapStreetStyleId = 'cke319crm05o419mqwqztfg5a'

export const themesToUsernameStyles = {
  [mapboxUsernames.MAPBOX]: {
    [mapThemes.STANDARD]: 'streets-v11',
    [mapThemes.LIGHT]: 'light-v10',
    [mapThemes.DARK]: 'dark-v10',
    [mapThemes.SATELLITE]: 'satellite-v9',
  },
  [mapboxUsernames.AIRMAP]: {
    [mapThemes.STANDARD]: airmapStreetStyleId,
    [mapThemes.LIGHT]: airmapStreetStyleId,
    [mapThemes.DARK]: airmapStreetStyleId,
    [mapThemes.SATELLITE]: airmapStreetStyleId,
  },
}

export const markerSizes = {
  LARGE: 'l',
  SMALL: 's',
}

export const sourceTypes = {
  VECTOR: 'vector',
  RASTER: 'raster',
}

export const rulesetIds = {
  MILITARY_BASE_EDWARDS_RULES: 'military_base_edwards_rules',
  CHE_SFO: SWITZERLAND_RULESET.id,
  CHE_SFO_POLICE_VD: POLICE_VAUD_RULESET.id,
  CHE_SFO_PALEO: PALEO_RULESET.id,
}

export const kmToNMConversionFactor = 0.539956803
