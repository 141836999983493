import axiosInstance from 'modules/axiosInstance'
import settings from 'settings'

const {
  urls: { advisoryApiUrl, flightPlansUrl },
} = settings

/*
TODO: Rulesets are currently hardcoded but we expect to have a reflexion on how to refactor
 the whole thing (rulesets, airspace_types, layers...) on both backend and frontend side
 */
export const SWITZERLAND_RULESET = {
  id: 'che_sfo_13b57',
  airspace_types: ['airport', 'c0_areas', 'controlled_airspace', 'heliport', 'special_use_airspace', 'tma'],
  jurisdiction: {
    id: 5,
    name: 'Switzerland',
    region: 'federal',
  },
}

export const PALEO_RULESET = {
  id: 'che_sfo_paleo',
  airspace_types: ['event_airspace', 'powerline', 'public_zone', 'parking', 'gridline', 'event_zone', 'controlled_airspace'],
  groupedLayer: ['paleo'],
  jurisdiction: {
    id: 5,
    name: 'Switzerland',
    region: 'federal',
  },
}

export const POLICE_VAUD_RULESET = {
  id: 'che_sfo_police-vd',
  airspace_types: ['county'],
  jurisdiction: {
    id: 5,
    name: 'Switzerland',
    region: 'federal',
  },
}

const RULESETS_LIST = [SWITZERLAND_RULESET, PALEO_RULESET, POLICE_VAUD_RULESET]

export const getAdvisoriesFromRulesetsGeometry = (rulesets, geometry, start, end) => {
  return axiosInstance.post(advisoryApiUrl, {
    geometry,
    rulesets,
    start,
    end,
  })
}

// Notes on 2024-02-02:
// The only ruleset that is ever queried currently is "che_sfo_13b57",
// and the only information that is used in it is the one included in SWITZERLAND_RULESET.
// We save ourselves a relatively big request that also occurs very often by just
// returning the same object every time, while throwing an error if the ruleset is unknown
// should something else ever be requested.
// Other APIs make similar information available: if/when we need to support more rulesets
// again, we can consider refactoring this part of the code to use an approach similar to the
// Orbitalize Apps.
// Update on 2024-07-08:
// Rulesets for paleo and police-vaud have been added, so we now need to handle multiple rulesets
export const getRulesInformation = (rulesets) => {
  const filteredRulesets = RULESETS_LIST.filter((ruleset) => rulesets.includes(ruleset.id))

  if (filteredRulesets?.length > 0) {
    return filteredRulesets
  } else {
    throw new Error('Unknown ruleset request: ' + rulesets)
  }
}

export const checkAirspaceStatus = ({ pilotId, geoScope }) => {
  const params = { pilot: pilotId, geoScope }

  return axiosInstance.get(`${flightPlansUrl}/status`, { params }).then((response) => response.data.data)
}
