export const UPDATE_SCOPE_FIELD_VALUE = 'UPDATE_SCOPE_FIELD_VALUE'
export const SET_CURRENT_EVALUATION_SCOPES = 'SET_CURRENT_EVALUATION_SCOPES'
export const SET_CURRENT_GEOMETRY_SCOPES = 'SET_CURRENT_GEOMETRY_SCOPES'
export const SET_IS_EVALUATION_FINISHED = 'SET_IS_EVALUATION_FINISHED'
export const SET_IS_READY_FOR_CREATION = 'SET_IS_READY_FOR_CREATION'
export const SET_IS_PROCESSING_EVALUATION = 'SET_IS_PROCESSING_EVALUATION'
export const SET_TEMPLATE_GEOMETRY = 'SET_TEMPLATE_GEOMETRY'
export const CLEAR_EVALUATION_DATA = 'CLEAR_EVALUATION_DATA'
export const SET_DID_USER_REQUEST_EVALUATION = 'SET_DID_USER_REQUEST_EVALUATION'
export const SET_RESPONSIBLE_AUTHORITY = 'SET_RESPONSIBLE_AUTHORITY'
export const SET_IS_WORKFLOW_APPLICABLE = 'SET_IS_WORKFLOW_APPLICABLE'
