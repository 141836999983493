export const debounce = (fn, wait) => {
  let timer
  return (...args) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => fn(...args), wait)
    return {
      cancel: () => clearTimeout(timer)
    }
  }
}

export const upperFirst = string => string.charAt(0).toUpperCase() + string.slice(1)

export const isDeeplyEqual = (baseObject, objectToCompare) => {
  const areBothSameType = typeof baseObject === typeof objectToCompare
  if (!areBothSameType) return false
  const isAtLeastOneValueFalsy = !baseObject || !objectToCompare
  const areValuesTypeNumber = typeof baseObject === 'number'
  if (isAtLeastOneValueFalsy || areValuesTypeNumber) {
    return baseObject === objectToCompare || (Number.isNaN(baseObject) && Number.isNaN(objectToCompare))
  }
  const baseObjectKeys = Object.keys(baseObject)
  const objectToCompareKeys = Object.keys(objectToCompare)
  if (baseObjectKeys.length !== objectToCompareKeys.length) return false
  for (const key of baseObjectKeys) {
    const baseValue = baseObject[key]
    const compareValue = objectToCompare[key]
    const isValueAnObject =
      baseValue !== null && typeof baseValue === 'object' && compareValue !== null && typeof compareValue === 'object'
    const isValueAnObjectAndEqual = isValueAnObject && isDeeplyEqual(baseValue, compareValue)
    const isValueEqual = !isValueAnObject && baseValue === compareValue
    if (!isValueAnObjectAndEqual && !isValueEqual) return false
  }
  return true
}

export const keyBy = (collection, identity) => {
  const obj = {}
  const isIdentityAFunction = typeof identity === 'function'
  Object.keys(collection).forEach(elementKey => {
    const value = collection[elementKey]
    const keyForCurrentValue = isIdentityAFunction ? identity(value) : value[identity]
    if (keyForCurrentValue) obj[keyForCurrentValue] = value
  })
  return obj
}

export const pick = (object, properties) => {
  return Object.entries(object).reduce((newObject, [key, value]) => {
    const shouldPick = properties.includes(key)

    return shouldPick ? { ...newObject, [key]: value } : newObject
  }, {})
}

export const removeItemsFromArray = (array, predicateForRemoving) => {
  const itemsToRemove = array.filter(predicateForRemoving)
  const itemsToRemoveIndexes = itemsToRemove.map(item => array.findIndex(element => element === item))
  itemsToRemoveIndexes.forEach(indexToRemove => array.splice(indexToRemove, 1))
  return array
}

export const findLastIndex = (array, predicate) => {
  let indexInOriginalArray
  const reversedArray = array.reverse()
  const indexFound = reversedArray.findIndex(predicate)
  if (indexFound > -1) {
    indexInOriginalArray = array.length - 1 - indexFound
  } else {
    indexInOriginalArray = indexFound
  }
  return indexInOriginalArray
}

export const capitalizeEachWordAndRemoveSpacing = string => {
  const underscoreAndDashRegex = new RegExp(/[A-Z\s_-]/g)
  const words = string.split(underscoreAndDashRegex)
  return words.reduce((result, word, index) => {
    return result + (index ? ' ' : '') + upperFirst(word)
  }, '')
}

export const get = (object, stringKeys) => {
  const keys = stringKeys.split('.')
  let value = object

  for (const key in keys) {
    const newValue = value[key]
    const doesNewValueExist = typeof newValue !== 'undefined'
    value = newValue

    if (!doesNewValueExist) {
      break
    }
  }

  return value
}

export const generateRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const parseCamelCaseToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
