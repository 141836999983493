import moment from 'moment'
import { timestampFormatting } from 'modules/core/constants/date-time.constants'

export const formatInputLabel = ({ todayLabel, tomorrowLabel }) => date => {
  const momentDate = moment(date)
  const isToday = momentDate.isSame(moment(), 'day')
  const isTomorrow = momentDate.isSame(moment().add(1, 'day'), 'day')
  const format = timestampFormatting.DATE_SLASHED_MONTH_FIRST

  if (isToday) {
    return `${todayLabel}, ${momentDate.format(format)}`
  } else if (isTomorrow) {
    return `${tomorrowLabel}, ${momentDate.format(format)}`
  } else {
    return momentDate.format(`${timestampFormatting.DAY_SHORT}, ${format}`)
  }
}
