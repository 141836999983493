import React from 'react'

const Hurricane = () => (
  <svg viewBox="25 16 75 90">
    <g>
      <path d="M59.635,52.717" />
      <path d="M51.742,67.59c1.293-0.139,2.591-0.409,3.877-0.826c12.553-4.076,19.425-17.556,15.349-30.108 c-5.094-15.691-21.944-24.281-37.635-19.187c0,0,10.279,7.59,14.926,14.942c-1.293,0.139-2.591,0.409-3.877,0.826    c-12.553,4.076-19.425,17.556-15.349,30.108c5.094,15.691,21.944,24.281,37.635,19.187C66.668,82.532,56.389,74.943,51.742,67.59z M44,49c0-3.314,2.686-6,6-6s6,2.686,6,6s-2.686,6-6,6S44,52.314,44,49z" />
    </g>
  </svg>
)

export default Hurricane
