export const buttonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  XSMALL: 'xsmall'
}

export const buttonVariants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MINIMAL: 'minimal',
  MUTED: 'muted'
}
