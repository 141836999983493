import { services } from './urls'

export type Auth = {
  domain: string,
  token: string
}

const auth: Auth = {
  domain: services.BARE_AUTH,
  token: 'AirMapUserToken'
}

export default auth
