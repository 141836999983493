import { MAPBOX_GEOCODER_URL } from '@env'
import { buildServiceUrl, buildAuthUrl, buildApiUrl, BuildApiParams } from '../helpers/buildServiceUrl'
import api from './api'
import config from './general'

export type Services = {
  API: string
  AUTH: string
  BARE_AUTH: string
  MQTT: string
  WS: string
}

export type Urls = {
  advisoryApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  aircraftApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  baseJurisdictionSourceUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  flightsUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  flightPlansUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  mapboxGeocoderUrl: string
  pilotUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  pilotsV3Url: ({ url, envVar, defaultPath }: BuildApiParams) => string
  rulesetSourceUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  rulesUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  weatherApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  mqttUrl: string
  scopeApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  operationApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  templateApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  planApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  airspaceApiUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
  getMapboxStaticClient: (mapboxUsername: string) => string
  agreementsUrl: ({ url, envVar, defaultPath }: BuildApiParams) => string
}

export const services: Services = {
  API: buildServiceUrl({ service: api.services.API, protocol: api.protocols.HTTPS, path: null }),
  AUTH: buildAuthUrl({ service: api.services.AUTH, protocol: api.protocols.HTTPS, path: null }),
  BARE_AUTH: buildAuthUrl({ service: api.services.AUTH, protocol: null, path: null }),
  MQTT: buildServiceUrl({ service: api.services.MQTT, protocol: null, path: null }),
  WS: buildServiceUrl({ service: api.services.API, protocol: api.protocols.WSS, path: null }),
}

const url = services.API

const urls: Urls = {
  advisoryApiUrl: buildApiUrl({ url, envVar: 'ADVISORY_API_URL', defaultPath: '/advisory/v1/airspace' }),
  aircraftApiUrl: buildApiUrl({ url, envVar: 'AIRCRAFT_API_URL', defaultPath: '/aircraft' }),
  baseJurisdictionSourceUrl: buildApiUrl({
    url,
    envVar: 'BASE_JURISDICTION_SOURCE_URL',
    defaultPath: '/tiledata/v1/base-jurisdiction/{z}/{x}/{y}',
  }),
  flightsUrl: buildApiUrl({ url, envVar: 'FLIGHTS_API_URL', defaultPath: '/flight/v2' }),
  flightPlansUrl: buildApiUrl({ url, envVar: 'FLIGHTPLANS_API_URL', defaultPath: '/flight/v2/plan' }),
  mapboxGeocoderUrl: MAPBOX_GEOCODER_URL || 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  pilotUrl: buildApiUrl({ url, envVar: 'PILOT_API_URL', defaultPath: '/pilot' }),
  pilotsV3Url: buildApiUrl({ url, envVar: 'PILOTS_v3_API_URL', defaultPath: '/api/pilots/v3' }),
  rulesetSourceUrl: buildApiUrl({ url, envVar: 'RULESET_SOURCE_URL', defaultPath: '/tiledata/v1' }),
  rulesUrl: buildApiUrl({ url, envVar: 'RULES_URL', defaultPath: '/rules' }),
  weatherApiUrl: buildApiUrl({ url, envVar: 'WEATHER_API_URL', defaultPath: '/advisory/v1/weather' }),
  mqttUrl: services.MQTT,
  scopeApiUrl: buildApiUrl({
    url,
    envVar: 'SCOPE_API_URL',
    defaultPath: '/operations/v1/scope',
    useExperimentalUrl: config.useExperimentalUrls,
  }),
  operationApiUrl: buildApiUrl({
    url,
    envVar: 'OPERATION_API_URL',
    defaultPath: '/operations/v1',
    useExperimentalUrl: config.useExperimentalUrls,
  }),
  templateApiUrl: buildApiUrl({
    url,
    envVar: 'TEMPLATE_API_URL',
    defaultPath: '/operations/v1/template',
    useExperimentalUrl: config.useExperimentalUrls,
  }),
  planApiUrl: buildApiUrl({
    url,
    envVar: 'PLAN_API_URL',
    defaultPath: '/operations/v1/plan',
    useExperimentalUrl: config.useExperimentalUrls,
  }),
  airspaceApiUrl: buildApiUrl({ url, envVar: 'AIRSPACE_API_URL', defaultPath: '/rules/v2/airspace' }),
  getMapboxStaticClient: (mapboxUsername: string) => `https://api.mapbox.com/styles/v1/${mapboxUsername}`,
  agreementsUrl: buildApiUrl({ url, envVar: 'AGREEMENTS_API_URL', defaultPath: '/agreements/v2' }),
}

export default urls
