export const inputTypes = {
  BOOLEAN: 'boolean',
  STRING: 'string',
  STRING_ENUMERATION: 'string_enumeration',
  MULTILINE_STRING: 'multiline_string',
  MULTIPLE_STRING_ENUMERATION: 'multiple_string_enumeration',
  DYNAMIC_STRING_ENUMERATION: 'dynamic_string_enumeration',
  DYNAMIC_MULTIPLE_STRING_ENUMERATION: 'dynamic_multiple_string_enumeration',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  INTEGER: 'integer',
  FLOATING_POINT: 'floating_point',
  GROUP: 'group',
  GEOMETRY: 'geometry',
  DURATION: 'duration',
  FILE: 'file',
  DOCUMENT: 'document',
  DECLARATION: 'declaration',
  PHONE_NUMBER: 'phone_number',
}
type InputTypesKeys = keyof typeof inputTypes
export type InputTypesValues = typeof inputTypes[InputTypesKeys]

export const inputValues = {
  FLIGHT_LEVEL: 'flight_level',
}

export const inputIDs = {
  ACTIVITY_END_TIME: 'cbe2df43-87fd-47c6-b5ed-46cf8ae97c5f',
  ACTIVITY_START_TIME: '3a474ea5-9db0-4211-b356-e1f7839b14bc',
  ACTIVITY_TYPE: '0c819675-d1b4-488c-8b7a-6274d1c3914f',
  ADDITIONAL_INFORMATION: 'fa7e154a-f7cb-4e29-8b8e-1f47e167081a',
  AIRCRAFT_CATEGORY: '255ddeb1-937b-45ae-a749-e15ee3630f61',
  AIRCRAFT_TYPE: 'f87f5f80-c56d-4edc-ad28-c4cae6e374eb',
  ALTITUDE_MAXIMUM_REFERENCE: 'e09b70b9-d6b9-4b9d-b172-580a3d912303',
  ALTITUDE_MAXIMUM: '3858ba3d-0d81-4b72-a864-4e147508294f',
  ALTITUDE_MINIMUM_REFERENCE: 'c27287d9-8bab-447a-8141-d606d9aba79f',
  ALTITUDE_MINIMUM: 'e0ff9144-6e3b-4e75-86b8-1792e28a928b',
  ALTITUDE: '61d6d07e-e8c8-4dad-9fab-e34038e503d9',
  APPLICANT_FIRST_NAME: 'c787a187-8aea-46e8-93c2-9a4d7fb9befa',
  APPLICANT_LAST_NAME: 'df16ca5d-28b6-4847-b5bc-bc749512b733',
  CALL_SIGN: '6a2ec438-9af9-416a-b117-0e2f77c8234e',
  COMPANY: '1d2a3fa5-69a1-47b5-b90a-3dbc446db8b4',
  CONTACT_MOBILE: '8e653664-4a2a-4c4b-b2cb-cee3cd4196d0',
  CONTACT_NAME: 'e9cb96d3-f89b-4f29-8ab9-4745eb4cccfb',
  DATE_FROM: '2967899e-f9b8-4721-9f30-411fea7fe961',
  DATE_TO: '61e7d3cd-dca9-4b58-8279-d84f679c5a7e',
  DEPARTURE: 'd92b8eea-e053-4b41-8c56-2a1e1b78751f',
  DESTINATION: '1c0fc78b-e2df-45bb-8f31-4aab6d44a906',
  DURATION: 'abc4c0c1-3268-443c-9e18-b5dff05f608b',
  END_TIME: 'a1711a01-a927-4a8f-8447-ea07bcf63a06',
  FLIGHT_RULES: '827ea7f4-08e0-4ae0-a8b7-830dfeec3441',
  GEOMETRY: 'df9fc04e-64a0-4d6a-b515-810c06094a13',
  LOCATION: 'ca1f1984-e518-4ae3-b1fd-58a19e31576d',
  PREFERRED_LANGUAGE: '6328b4bd-acf9-447f-8ff5-e622bc1fff68',
  START_TIME: '8c6b585e-0453-478f-b86d-d8f9f8400bf4',
  TERMS_AND_CONDITIONS: '4c82d560-4fb2-436f-a28f-69cab9c47f4f',
  MULTI_DAY_OPERATION: 'ca352903-dd0e-4cdb-8828-a2aa8b1900cc',
  REGISTRATION_INFORMATION: 'd791d144-2e54-4ac9-845c-df6dca860486',
  REMARKS: '8a4adba6-b5a2-4c8d-a137-366f64f9485d',
  OPERATOR_INFORMATION: '83751919-3611-4825-9b43-e74ef01b5ca5',
  APPLICANT_MOBILE: '6d0c3da0-68b7-4e47-ac42-c36fa65c1951',
  APPLICANT_EMAIL: 'ed26f69a-cd08-4950-9df8-fe48678e2f01',
  APPLICANT_ADDRESS: '641ccd3f-386d-4a4a-b20b-350bb1602cf7',
  DRONE_MANUFACTURER: '8f95098e-7843-4a95-aba7-790962ec5d3c',
  DRONE_MODEL: 'e6acf003-6809-45ea-bae4-33be8504f7e1',
  MASS_RANGE: '6f77a29f-719a-4b98-95cc-1b59441d8e3a',
  OPERATION_SCENARIO: '0f3bcbd3-4845-42e0-b4eb-06e016583b1d',
  NUMBER_OF_AIRCRAFT: 'ca61d269-7cae-4603-b1a0-0e5e02ae12cc',
  PAYLOAD: 'fec9326d-3f6c-4656-9e95-c8c23a022adf',
  LAUNCH: '85704d32-18fd-4a24-a00b-a8f080494471',
  DIRECTION: 'd3d749ba-874e-4514-b604-d8e8258c365f',
  QR_CODE_LINK: 'c978317f-7283-422e-80e8-0c2a4a0816fe',
  ATTACHED_FILE_1: 'f31d35b9-9162-44a0-b6ff-9819d5a959bd',
  ATTACHED_FILE_2: '8cb06327-5600-4198-b1a6-9db5da4ba4d0',
  ATTACHED_FILE_3: '70b4f52c-1b86-4201-a252-5e64379aa82d',
  ATTACHED_FILE_4: '5102cfc6-d40c-4a4f-8424-41f6e7da4ff1',
  ATTACHED_FILE_5: 'c91a2cbe-7524-43f5-a5e4-23b76dd559e8',
  ALLOW_LATE_ANNOUNCEMENTS: '49ce4cec-b75d-4e88-ac71-8c6a921978f7',
  PREFERENCE: 'ee6a6305-fc18-4e32-9220-a82c6ec3b212',
  SECTORS: 'ef48c0a5-c1dd-482e-95c1-5588009f354c',
}
type InputIDKeys = keyof typeof inputIDs
export type InputIDValues = typeof inputIDs[InputIDKeys]

export const attachedFileIds = [
  inputIDs.ATTACHED_FILE_1,
  inputIDs.ATTACHED_FILE_2,
  inputIDs.ATTACHED_FILE_3,
  inputIDs.ATTACHED_FILE_4,
  inputIDs.ATTACHED_FILE_5,
]

export const requirementTypes = {
  OPTIONAL: 'optional',
  REQUIRED: 'required',
  CONDITIONAL: 'conditional',
  EVALUATED: 'evaluated',
  INCOMPATIBLE: 'incompatible',
}

export const scopesIDs = {
  APPLICANT: '10bbb430-48ba-4b8c-98bd-6394839ed24b',
  DETAILS: '1fe84ec3-b4bf-4dd9-8e0d-8b720e93960f',
  EVALUATION: '575b4ef8-6aa1-4f9b-b2f2-e94abd2cd425',
  SCHEDULE: '7efb730a-c307-4f24-a20d-6c520aa20af3',
}

export const scopeGroupsForUI = {
  GENERAL: 'general_group',
  ALTITUDE: 'altitude_group',
  SCHEDULE: 'schedule_group',
  TIME: 'time_group',
  ADDITIONAL_DETAILS: 'additional_details_group',
  ADDITIONAL_FILES: 'additional_files_group',
  APPLICANT_DETAILS: 'applicant_details_group',
  CONTACT_DETAILS: 'contact_details_group',
}

export const customScopeGroupsForUI = {
  OPERATION_TAB_SCOPE: 'operation_tab_scope',
}

export const inputTechnicalNames = {
  TERMS_AND_CONDITIONS: 'dsna_terms_and_conditions',
  GEOMETRY: 'geometry',
  ALTITUDE: 'altitude_group',
  OPERATOR_INFORMATION: 'applicant_name_group',
  ADDITIONAL_INFORMATION: 'additional_info',
  AIRCRAFT_TYPE: 'icao_aircraft_type',
  CALL_SIGN: 'callsign',
  DEPARTURE: 'departure_icao',
  DESTINATION: 'destination_icao',
  FLIGHT_RULES: 'flight_rules',
  MULTI_DAY_OPERATION: 'multi_day_operation',
  ACTIVITY_END_TIME: 'end_timestamp',
  ACTIVITY_START_TIME: 'start_timestamp',
  ACTIVITY_TYPE: 'activity_type',
  AIRCRAFT_CATEGORY: 'aircraft_category',
  ALTITUDE_MAXIMUM_REFERENCE: 'altitude_max_reference',
  ALTITUDE_MAXIMUM: 'altitude_max_value',
  ALTITUDE_MINIMUM_REFERENCE: 'altitude_min_reference',
  ALTITUDE_MINIMUM: 'altitude_min_value',
  APPLICANT_FIRST_NAME: 'applicant_first_name',
  APPLICANT_LAST_NAME: 'applicant_last_name',
  COMPANY: 'applicant_company',
  CONTACT_MOBILE: 'contact_mobile_phone',
  CONTACT_NAME: 'contact_name',
  DATE_FROM: 'start_date',
  DATE_TO: 'end_date',
  DURATION: 'maximum_activity_duration',
  END_TIME: 'end_time',
  LOCATION: 'location',
  PREFERRED_LANGUAGE: 'preferred_language',
  START_TIME: 'start_time',
  REGISTRATION_INFORMATION: 'registration_info',
  REMARKS: 'remarks',
  APPLICANT_MOBILE: 'applicant_mobile_phone',
  APPLICANT_EMAIL: 'applicant_email_address',
  APPLICANT_ADDRESS: 'applicant_mailing_address',
  DRONE_MANUFACTURER: 'drone_manufacturer',
  DRONE_MODEL: 'drone_model',
  MASS_RANGE: 'mass_range',
  OPERATION_SCENARIO: 'operational_scenario',
  NUMBER_OF_AIRCRAFT: 'num_aircraft',
  PAYLOAD: 'payload',
  LAUNCH: 'launch_method',
  DIRECTION: 'direction',
  ADDITIONAL_FILES: 'additional_files',
  QR_CODE_LINK: 'qrcode_link',
  ALLOW_LATE_ANNOUNCEMENTS: 'allow_late_announcements',
  PREFERENCE: 'preference',
  SECTOR: 'sector',
}

export const fieldGroupsByTechnicalName = {
  [scopeGroupsForUI.GENERAL]: [
    inputTechnicalNames.AIRCRAFT_CATEGORY,
    inputTechnicalNames.ACTIVITY_TYPE,
    inputTechnicalNames.AIRCRAFT_TYPE,
    inputTechnicalNames.NUMBER_OF_AIRCRAFT,
  ],
  [scopeGroupsForUI.ALTITUDE]: [
    inputTechnicalNames.ALTITUDE_MAXIMUM,
    inputTechnicalNames.ALTITUDE_MAXIMUM_REFERENCE,
    inputTechnicalNames.ALTITUDE_MINIMUM,
    inputTechnicalNames.ALTITUDE_MINIMUM_REFERENCE,
  ],
  [scopeGroupsForUI.SCHEDULE]: [
    inputTechnicalNames.ACTIVITY_START_TIME,
    inputTechnicalNames.ACTIVITY_END_TIME,
    inputTechnicalNames.DATE_FROM,
    inputTechnicalNames.DATE_TO,
    inputTechnicalNames.MULTI_DAY_OPERATION,
    inputTechnicalNames.ADDITIONAL_INFORMATION,
  ],
  [scopeGroupsForUI.TIME]: [inputTechnicalNames.START_TIME, inputTechnicalNames.END_TIME, inputTechnicalNames.DURATION],
  [scopeGroupsForUI.ADDITIONAL_DETAILS]: [
    inputTechnicalNames.DEPARTURE,
    inputTechnicalNames.DESTINATION,
    inputTechnicalNames.CALL_SIGN,
    inputTechnicalNames.FLIGHT_RULES,
    inputTechnicalNames.PAYLOAD,
    inputTechnicalNames.LAUNCH,
    inputTechnicalNames.DIRECTION,
    inputTechnicalNames.DRONE_MANUFACTURER,
    inputTechnicalNames.DRONE_MODEL,
    inputTechnicalNames.MASS_RANGE,
    inputTechnicalNames.REGISTRATION_INFORMATION,
    inputTechnicalNames.LOCATION,
    inputTechnicalNames.OPERATION_SCENARIO,
    inputTechnicalNames.REMARKS,
    inputTechnicalNames.PREFERRED_LANGUAGE,
    inputTechnicalNames.PREFERENCE,
    inputTechnicalNames.SECTOR,
  ],
  [scopeGroupsForUI.ADDITIONAL_FILES]: [inputTechnicalNames.ADDITIONAL_FILES],
  [scopeGroupsForUI.APPLICANT_DETAILS]: [
    inputTechnicalNames.APPLICANT_FIRST_NAME,
    inputTechnicalNames.APPLICANT_LAST_NAME,
    inputTechnicalNames.APPLICANT_ADDRESS,
    inputTechnicalNames.APPLICANT_EMAIL,
    inputTechnicalNames.APPLICANT_MOBILE,
    inputTechnicalNames.COMPANY,
  ],
  [scopeGroupsForUI.CONTACT_DETAILS]: [inputTechnicalNames.CONTACT_MOBILE, inputTechnicalNames.CONTACT_NAME],
}

export const fieldsWithoutName = [
  inputTechnicalNames.ALTITUDE_MAXIMUM_REFERENCE,
  inputTechnicalNames.ALTITUDE_MINIMUM_REFERENCE,
]

export const fieldsWithInformation = [
  inputTechnicalNames.ALTITUDE_MAXIMUM_REFERENCE,
  inputTechnicalNames.ALTITUDE_MINIMUM_REFERENCE,
]

export const optionalFieldsValidationFunction = {
  [inputTechnicalNames.REMARKS]: (value: unknown) => !value,
  [inputTechnicalNames.REGISTRATION_INFORMATION]: (value: unknown) => !value,
  [inputTechnicalNames.NUMBER_OF_AIRCRAFT]: (value: unknown, customConditionToHide: unknown) => customConditionToHide,
}
