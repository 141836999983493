import React from 'react'
import { privacyPolicyLink, termsAndConditionsLink } from "./common"

const privacyPolicyContent = () => (
  <>
    <p>
      {`En continuant à utiliser notre application et en cliquant sur "ACCEPTER", vous acceptez la `}
      <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
        Politique de confidentialité
      </a>
      {' de skyguide et les '}
      <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
        Termes et Conditions
      </a>
      {` de l’application. `}
      {`Vous pouvez modifier en tout temps le paramétrage des cookies, mais certains éléments du site et des services ne fonctionneront pas correctement sans ces témoins. `}
      {`Si vous n’acceptez pas les conditions d’utilisation, la politique de confidentialité ou la politique en matière de cookies, vous êtes priés de ne pas utiliser l’application skyguide.`}
    </p>
    <p>
      {`Lorsque vous utilisez votre drone, vous devez respecter scrupuleusement les normes applicables. `}
      {`Tous les contenus, informations et données sont fournis sans garantie d’aucune sorte. `}
      {`Dans les limites autorisées par le droit en vigueur, skyguide et ses fournisseurs déclinent toute responsabilité.`}
    </p>
  </>
)

export default privacyPolicyContent
