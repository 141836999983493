export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const SET_PROFILE_UPDATE_SUCCESS = 'SET_PROFILE_UPDATE_SUCCESS'
export const SET_PROFILE_UPDATE_ERROR_TYPE = 'SET_PROFILE_UPDATE_ERROR_TYPE'
export const CLEAR_PROFILE_UPDATE_ERROR_TYPE = 'CLEAR_PROFILE_UPDATE_ERROR_TYPE'
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const OPEN_PHONE_VERIFICATION_MODAL = 'OPEN_PHONE_VERIFICATION_MODAL'
export const HIDE_PHONE_VERIFICATION_MODAL = 'HIDE_PHONE_VERIFICATION_MODAL'
export const SET_PHONE_NUMBER_ERROR = 'SET_PHONE_NUMBER_ERROR'
export const CLEAR_PHONE_NUMBER_ERROR = 'CLEAR_PHONE_NUMBER_ERROR'
export const CLEAR_PHONE_VERIFICATION_MODAL_VALUES = 'CLEAR_PHONE_VERIFICATION_MODAL_VALUES'
export const SHOW_ENTER_SMS_CODE = 'SHOW_ENTER_SMS_CODE'
export const HIDE_ENTER_SMS_CODE = 'HIDE_ENTER_SMS_CODE'
export const SET_SMS_TOKEN = 'SET_SMS_TOKEN'
export const SET_SMS_CODE_ERROR = 'SET_SMS_CODE_ERROR'
export const CLEAR_SMS_CODE_ERROR = 'CLEAR_SMS_CODE_ERROR'
