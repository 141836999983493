import dePrivacyPolicy from './de'
import frPrivacyPolicy from './fr'
import itPrivacyPolicy from './it'
import enPrivacyPolicy from './en'

const policiesByLang = {
  de: dePrivacyPolicy,
  fr: frPrivacyPolicy,
  it: itPrivacyPolicy,
  en: enPrivacyPolicy
}

const language = (navigator.languages && navigator.languages[0]) || navigator.language || 'en'

const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

let policy

if (policiesByLang[languageWithoutRegionCode]) {
  policy = policiesByLang[languageWithoutRegionCode]
} else {
  policy = policiesByLang['en']
}

export const skyguidePrivacyPolicy = policy
