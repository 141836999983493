import moment from 'moment'
import axiosInstance from 'modules/axiosInstance'
import { setMapLanguage } from '../modules/map/helpers/mapbox.helpers'
import { SET_TEMPLATE_MAP_INSTANCE } from '../modules/map/store/map.types'
import { SET_USER_SELECTION_LANGUAGE } from '../modules/user/store/user.types'
import { airmapDate } from 'libs/airmap-date'
import AirmapIntl from 'libs/airmap-intl'

export const languageMiddleware = store => next => action => {
  if (action.type === SET_USER_SELECTION_LANGUAGE) {
    const { userSelectionLanguage } = action

    const userLocale = AirmapIntl.parseLanguageSelection(userSelectionLanguage)

    axiosInstance.defaults.headers['accept-language'] = userLocale
    localStorage.setItem('selected-language', userSelectionLanguage)
    moment.locale(userLocale)
    airmapDate.setLocale(userLocale)

    const initialState = store.getState()

    const { mapInstance, mapLoaded: isMapLoaded } = initialState.template.map

    if (isMapLoaded) {
      setMapLanguage(mapInstance, userLocale)
    }
  }

  if (action.type === SET_TEMPLATE_MAP_INSTANCE) {
    const { mapInstance } = action
    const userSelectionLanguage = localStorage.getItem('selected-language')
    const userLocale = AirmapIntl.parseLanguageSelection(userSelectionLanguage)

    if (userLocale) {
      setMapLanguage(mapInstance, userLocale)
    }
  }

  return next(action)
}
