import { SET_SFO_MEASUREMENT_TYPE } from '../modules/user/store/user.types'

export const measurementMiddleware = () => next => action => {
  if (action.type === SET_SFO_MEASUREMENT_TYPE) {
    const { measurementType } = action
    localStorage.setItem('selected-measurement', measurementType)
  }

  return next(action)
}
