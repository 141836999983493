import moment from 'moment'
import mtz from 'moment-timezone'

import { timestampFormatting } from 'modules/core/constants/date-time.constants'
import { airmapDate, formatKeys } from 'libs/airmap-date'
import { timezoneNames } from 'libs/airmap-date/constants/timezone.constants'

export const formatDateRange = (date) => {
  const formattedFrom = (date.from && airmapDate.getDate({ dateFormatKey: formatKeys.DATE, date: date.from })) || ''
  const formattedTo = (date.to && airmapDate.getDate({ dateFormatKey: formatKeys.DATE, date: date.to })) || ''

  return `${formattedFrom} - ${formattedTo}`
}

export const formatTimeRange = (time) => {
  const formattedFrom = time.from && parseUtcToDisplayFormat(time.from)
  const formattedTo = time.to && parseUtcToDisplayFormat(time.to)

  return `${formattedFrom} - ${formattedTo}`
}

export const formatDate = (date) => airmapDate.getDate({ dateFormatKey: formatKeys.DATE, date: date })
export const formatDateTime = (date) => {
  const isUTCFormat = date.includes('Z')
  const isLTFormat = date.includes('LT')
  const unit = isUTCFormat ? timestampFormatting.UNIVERSAL_TIME : timestampFormatting.LOCALE_TIME

  let parsedDate = isLTFormat ? date.replace('LT', '') : date
  parsedDate = isUTCFormat ? moment(parsedDate).utc() : parsedDate

  const dateTime = airmapDate.getDate({ dateFormatKey: formatKeys.DATE_TIME, date: parsedDate })

  return `${dateTime} ${unit}`
}

export const parseUtcToDisplayFormat = (time) => {
  return airmapDate.getDate({
    dateFormatKey: formatKeys.TIME_WITH_TZ,
    date: time.replace('UTC', '').trim(),
    inputDateFormat: timestampFormatting.TIME_0_23_HOURS,
  })
}

export const roundToNearestStep = (step) => (seconds) => {
  const minutes = seconds / 60
  const roundedMinutes = Math.round(minutes / step) * step
  const roundedSeconds = roundedMinutes * 60

  return roundedSeconds
}

export const isValidTimeFormat = (value) => {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/

  return regex.test(value)
}

export const parseTimeToSeconds = (timeString) => {
  const is24Hours = timeString.substring(0, timeString.indexOf(':')) === '24'
  const hours = is24Hours ? 24 : moment(timeString, timestampFormatting.TIME_0_23_HOURS).hours()
  const hoursInSeconds = hours * 60 * 60
  const minutesInSeconds = moment(timeString, timestampFormatting.TIME_0_23_HOURS).minutes() * 60

  return hoursInSeconds + minutesInSeconds
}

export const parseSecondsToTime = (value, showUnit, useZuluTime) => {
  const unit = useZuluTime ? timestampFormatting.ZULU_TIME : timestampFormatting.LOCALE_TIME

  const dateTime = moment.utc(value * 1000)
  const formattedValue = useZuluTime
    ? moment.tz(dateTime, timezoneNames.UTC).format(timestampFormatting.TIME_0_23_HOURS)
    : dateTime.format(timestampFormatting.TIME_0_23_HOURS)

  return `${formattedValue}${showUnit ? unit : ''}`
}

export const parseDateWithoutTimezone = (date) => {
  const parsedDateWithoutTimeZone = date.toISOString().split('T')[0]
  return parsedDateWithoutTimeZone
}

export const formatTimesheetToTime = (timesheet) => {
  return moment()
    .utc()
    .set({
      ...timesheet.date,
      ...timesheet.time,
    })
}

export const formatMonthDayDate = (start, end) => {
  const startDate = {
    ...start,
    month: start.month - 1,
  }
  const endDate = {
    ...end,
    month: end.month - 1,
  }

  const formattedStart = moment(startDate).format(timestampFormatting.MONTH_DAY) || ''
  const formattedEnd = moment(endDate).format(timestampFormatting.MONTH_DAY) || ''

  return `${formattedStart} - ${formattedEnd}`
}

export const getDeviceTimeZone = () => {
  const timezone = moment.tz.zone(moment.tz.guess())
  const customDateFromDevice = new Date()
  const deviceTimeZoneOffset = customDateFromDevice.getTimezoneOffset()
  const deviceTimezoneAbbr = timezone.abbr(deviceTimeZoneOffset)
  return deviceTimezoneAbbr
}

export const formatByTimezone = (date) => {
  const timezone = mtz.tz.zone(moment.tz.guess(true))
  const tzDate = moment.tz(date, timezone.name)
  const clientDate = tzDate.format(timestampFormatting.LOCALE_DATE)
  const clientTime = tzDate.format(timestampFormatting.LOCALE_TIME)

  return {
    date: clientDate,
    time: clientTime,
  }
}

export const formatAppliedFilterValue = ({ start, end }) => {
  const { date: startDate, time: startTime } = formatByTimezone(start)
  const { date: endDate, time: endTime } = formatByTimezone(end)
  const timezone = getDeviceTimeZone()
  const areSameDay = moment(start).isSame(moment(end), 'day')

  if (areSameDay) {
    return `${startDate}, ${startTime} ${timezone} - ${endTime} ${timezone}`
  }

  return `${startDate} ${startTime} ${timezone} - ${endDate} ${endTime} ${timezone}`
}

export const getFormattedTimeValue = (inputValue) => {
  const [severLocalizedTime, clientLocalizedTime] = airmapDate.formatLocalizedTime(inputValue)

  return `${severLocalizedTime} (${clientLocalizedTime})`
}

export const removeBlankSpacesFromDate = (date) => date.replace(/\s/g, '')

export const mixDateAndTimeFields = (date, time) => {
  const timeStringWithoutBlankSpace = removeBlankSpacesFromDate(time)
  const dateTime = moment(`${date}T${timeStringWithoutBlankSpace}`)

  const formattedDate = airmapDate.getDate({ dateFormatKey: formatKeys.DATE, date })
  const formattedTime = getFormattedTimeValue(dateTime)

  return `${formattedDate} - ${formattedTime}`
}

export const separateTimeZone = (time) => {
  const [timezone] = time?.match(/[+|-].+/) || []
  const [timeWithoutTimezone] = time?.match(/\d\d:\d\d/) || []

  return { timezone, timeWithoutTimezone }
}

export const formatSecondsToHours = (inputValue) => {
  const formatType = inputValue < 3600 ? 'HH:mm' : 'k:mm'
  const millisecondsValue = inputValue * 1000
  const hoursValue = moment.utc(millisecondsValue).format(formatType)
  return `${hoursValue}h`
}

// Return false if the duration is not defined
export const checkDurationTooShort = (start, end, durationInSeconds) => {
  return durationInSeconds && start && end && end.getTime() - start.getTime() < durationInSeconds * 1000
}
