import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const CalendarMonth = ({ color }) => {
  return (
    <svg
      className={icon}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9,11 L9,13 L7,13 L7,11 L9,11 L9,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 L13,11 Z M17,11 L17,13 L15,13 L15,11 L17,11 L17,11 Z M19,4 C20.1045695,4 21,4.8954305 21,6 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.89,22 3,21.1 3,20 L3,6 C3,4.8954305 3.8954305,4 5,4 L6,4 L6,2 L8,2 L8,4 L16,4 L16,2 L18,2 L18,4 L19,4 L19,4 Z M19,20 L19,9 L5,9 L5,20 L19,20 L19,20 Z M9,15 L9,17 L7,17 L7,15 L9,15 L9,15 Z M13,15 L13,17 L11,17 L11,15 L13,15 L13,15 Z M17,15 L17,17 L15,17 L15,15 L17,15 Z"
        id="path-1"
        fill={color}
      />
    </svg>
  )
}

CalendarMonth.defaultProps = {
  color: 'currentColor'
}

CalendarMonth.propTypes = {
  color: PropTypes.string
}
