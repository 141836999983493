import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router'

import { Loader } from 'modules/shared-components/Loader/Loader.component'
import EnvironmentIndicatorBanner from 'modules/shared-components/EnvironmentIndicators/EnvironmentIndicatorBanner'
import NotFound from 'modules/shared-components/NotFound'

import { NODE_ENV } from '@env'

const SFOAppContainer = lazy(() => import('./SFO/App.sfo.container'))


const isDevelopment = NODE_ENV === 'development'

export const Routes = () => {
  return (
    <div>
      {isDevelopment && <EnvironmentIndicatorBanner />}

      <Suspense fallback={<Loader />}>
        <Switch>

          <Route exact path="/">
            <Redirect to="/operator/requests" />
          </Route>

          <Route exact path={'**/(requests|activities)**'} component={SFOAppContainer} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  )
}
