const LATITUDE = 'lat'
const LONGITUDE = 'long'

function truncate(number) {
  return number > 0 ? Math.floor(number) : Math.ceil(number)
}

const parseLatLongToDMS = function(decimal, coordinateIdentifier) {
  const hemisphere = /^[WE]|(?:lon)/i.test(coordinateIdentifier) ? (decimal < 0 ? 'W' : 'E') : decimal < 0 ? 'S' : 'N'

  const absDecimal = Math.abs(decimal)
  const degrees = truncate(absDecimal)
  const minutes = truncate((absDecimal - degrees) * 60)
  const seconds = ((absDecimal - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2)

  let dmsArray = [degrees, minutes, seconds, hemisphere]
  return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`
}

export const getDMSFromLat = decimal => {
  return parseLatLongToDMS(decimal, LATITUDE)
}

export const getDMSFromLong = decimal => {
  return parseLatLongToDMS(decimal, LONGITUDE)
}

export function getDMSCoordinates(coordinates) {
  const coordinateLat = coordinates[0]
  const coordinateLng = coordinates[1]
  return `${getDMSFromLat(coordinateLng)}, ${getDMSFromLong(coordinateLat)}`
}
