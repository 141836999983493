import { point, lineString, center } from '@turf/turf'
import bbox from '@turf/bbox'
import {
  defaultBoundsOptions,
  mapboxSupportedLanguages,
  GEOMETRY_TYPE_LINE_STRING,
  GEOMETRY_TYPE_POINT,
  GEOMETRY_TYPE_POLYGON
} from '../constants/map.constants'
import queryString from 'query-string'
import { parseTimeOperationData } from '../../template-list/helpers/template-list.helpers'
import { drawLayerConstants } from '../../draw-plugin/constants/draw-plugin.constants'
import { addBuffer } from '../../draw-plugin/helpers/draw-plugin.helpers'
import { NODE_ENV } from "@env"

const isDevelopment = NODE_ENV === 'development'
const {
  FLIGHT_PLAN_OUTLINE_ID,
  FLIGHT_PLAN_GEOMETRY_ID,
  CLUSTERS_SOURCE_ID,
  CLUSTERS_GEOMETRY_POINT_ID,
  CLUSTERS_ID,
  CLUSTERS_GEOMETRY_COUNT_ID
} = drawLayerConstants

const getPopUpOrientation = layer => {
  const viewportHeight = window.innerHeight
  const elementHeight = layer.offsetHeight * 1.1
  const elementWidth = layer.offsetWidth * 1.1
  const distanceToTop = layer.offsetTop
  const distanceToLeft = layer.offsetLeft

  const isElementOffViewportHeight = elementHeight + distanceToTop >= viewportHeight
  const isElementOffViewportWidth = elementWidth >= distanceToLeft

  const positionY = isElementOffViewportHeight ? 'top' : 'bottom'
  const positionX = isElementOffViewportWidth ? 'right' : 'left'

  return [positionX, positionY]
}

export const fitMapToNewBounds = (geometry, map, boundsCustomOptions = {}, eventData = {}) => {
  if (geometry.type === 'FeatureCollection' && !geometry.features.length) return
  const boundsOptions = {
    ...defaultBoundsOptions,
    ...boundsCustomOptions
  }
  const newMapBounds = bbox(geometry)

  map.fitBounds(newMapBounds, boundsOptions, eventData)
}

export const removeGeometryLayers = map => {
  const hasGeometrySource = Boolean(map.getSource(FLIGHT_PLAN_GEOMETRY_ID))
  const hasClusterSource = Boolean(map.getSource(CLUSTERS_SOURCE_ID))

  if (hasGeometrySource) {
    map.removeLayer(FLIGHT_PLAN_GEOMETRY_ID)
    map.removeLayer(FLIGHT_PLAN_OUTLINE_ID)

    map.removeSource(FLIGHT_PLAN_GEOMETRY_ID)
  }

  if (hasClusterSource) {
    map.removeLayer(CLUSTERS_GEOMETRY_POINT_ID)
    map.removeLayer(CLUSTERS_ID)
    map.removeLayer(CLUSTERS_GEOMETRY_COUNT_ID)

    map.removeSource(CLUSTERS_SOURCE_ID)
  }
}

export const getGeometryCenter = geometry => {
  const geometryCenter = center(geometry)
  const { coordinates: geometryCenterCoordinates } = geometryCenter.geometry

  return geometryCenterCoordinates
}

export const getBufferedFeature = (feature, buffer) => {
  const featureType = feature.geometry.type
  let templateGeometry = {}
  let featureToGeoJson
  switch (featureType) {
    case GEOMETRY_TYPE_POLYGON:
      templateGeometry = feature
      break
    case GEOMETRY_TYPE_LINE_STRING:
      featureToGeoJson = lineString(feature.geometry.coordinates)
      templateGeometry = addBuffer(featureToGeoJson, buffer)
      break
    case GEOMETRY_TYPE_POINT:
      featureToGeoJson = point(feature.geometry.coordinates)
      templateGeometry = addBuffer(featureToGeoJson, buffer)
      break
    default:
      templateGeometry = feature
      break
  }

  return templateGeometry
}

export const setMapLanguage = (mapInstance, userSelectionLanguage) => {
  const isMapboxSupportedLanguages = mapboxSupportedLanguages.includes(userSelectionLanguage)

  if (!isMapboxSupportedLanguages) {
    if (isDevelopment) {
      console.warn(`The language "${userSelectionLanguage}" is not supported by Mapbox. Using English as fallback`)
    }

    userSelectionLanguage = 'en'
  }

  const { layers } = mapInstance.getStyle()

  layers.forEach(layer => {
    const sourceLayer = layer['source-layer']

    const hasLabel = sourceLayer && sourceLayer.includes('label')
    const isIcon = sourceLayer && sourceLayer.includes('poi')
    const isSign = layer.layout && layer.layout['text-field'] && layer.layout['text-field'] == '{ref}'

    if (hasLabel && !isIcon && !isSign) {
      mapInstance.setLayoutProperty(layer.id, 'text-field', ['get', `name_${userSelectionLanguage}`])
    }
  })
}

export const getCoordsQueryString = ({ zoom, center }) => {
  const [lng, lat] = center
  return queryString.stringify({ zoom, lat, lng })
}

export const getOperationGeometryPopUpPosition = (layer, [left, top]) => {
  const layerStyles = layer.style
  layerStyles.left = left + 'px'
  layerStyles.top = top + 'px'
  const popUpOrientation = getPopUpOrientation(layer)
  return popUpOrientation
}

export const parseOperationGeometry = feature => {
  const featureProperties = feature.properties
  const parsedFeature = {}
  Object.keys(featureProperties).map(feature => {
    try {
      parsedFeature[feature] = JSON.parse(featureProperties[feature])
    } catch {
      parsedFeature[feature] = featureProperties[feature]
    }
  })
  const time = parseTimeOperationData(parsedFeature.time)
  return { ...parsedFeature, time: time.full }
}

export const coordinatesGeocoder = query => {
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i)

  if (!matches) {
    return null
  }

  function coordinateFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature'
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  let geocodes = []

  if (coord1 < -90 || coord1 > 90) {
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
    geocodes.push(coordinateFeature(coord1, coord2))
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  return geocodes
}
