export const CoreMessages = {
  id: {
    id: 'Core.id',
    defaultMessage: 'id',
  },
  type: {
    id: 'Core.type',
    defaultMessage: 'Type',
  },
  status: {
    id: 'Core.status',
    defaultMessage: 'Status',
  },
  reference: {
    id: 'Core.reference',
    defaultMessage: 'Reference',
  },
  companyName: {
    id: 'Core.companyName',
    defaultMessage: 'Company Name',
  },
  location: {
    id: 'Core.location',
    defaultMessage: 'Location',
  },
  date: {
    id: 'Core.date',
    defaultMessage: 'Date',
  },
  date_time_label: {
    id: 'Core.date_time_label',
    defaultMessage: 'Date & Time',
  },
  created: {
    id: 'Core.created',
    defaultMessage: 'Created',
  },
  delete: {
    id: 'Core.delete',
    defaultMessage: 'Delete',
  },
  time: {
    id: 'Core.time',
    defaultMessage: 'Time',
  },
  rescind: {
    id: 'Core.rescind',
    defaultMessage: 'Rescind',
  },
  approve: {
    id: 'Core.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'Core.reject',
    defaultMessage: 'Reject',
  },
  consult: {
    id: 'Core.consult',
    defaultMessage: 'Consult',
  },
  acknowledge: {
    id: 'Core.acknowledge',
    defaultMessage: 'Acknowledge',
  },
  deny: {
    id: 'Core.deny',
    defaultMessage: 'Deny',
  },
  apply: {
    id: 'Core.apply',
    defaultMessage: 'Apply',
  },
  save: {
    id: 'Core.save',
    defaultMessage: 'Save',
  },
  close: {
    id: 'Core.close',
    defaultMessage: 'Close',
  },
  reset: {
    id: 'Core.reset',
    defaultMessage: 'Reset',
  },
  cancel: {
    id: 'Core.cancel',
    defaultMessage: 'Cancel',
  },
  terminate: {
    id: 'Core.terminate',
    defaultMessage: 'Terminate',
  },
  submit: {
    id: 'Core.submit',
    defaultMessage: 'Submit',
  },
  yes: {
    id: 'Core.yes',
    defaultMessage: 'Yes',
  },
  continue: {
    id: 'Core.continue',
    defaultMessage: 'Continue',
  },
  back: {
    id: 'Core.back',
    defaultMessage: 'Back',
  },
  no: {
    id: 'Core.no',
    defaultMessage: 'No',
  },
  all: {
    id: 'Core.all',
    defaultMessage: 'All',
  },
  name: {
    id: 'Core.name',
    defaultMessage: 'Name',
  },
  organization: {
    id: 'Core.organization',
    defaultMessage: 'Organization',
  },
  city: {
    id: 'Core.city',
    defaultMessage: 'City',
  },
  operatorID: {
    id: 'Core.operatorID',
    defaultMessage: 'Operator ID',
  },
  joined: {
    id: 'Core.joined',
    defaultMessage: 'Joined',
  },
  required: {
    id: 'Core.required',
    defaultMessage: 'Required',
  },
  lat: {
    id: 'Core.lat',
    defaultMessage: 'lat',
  },
  lng: {
    id: 'Core.lng',
    defaultMessage: 'lng',
  },
  createdAt: {
    id: 'Core.createdAt',
    defaultMessage: 'Created At',
  },
  search: {
    id: 'Core.search',
    defaultMessage: 'Search',
  },
  email: {
    id: 'Core.email',
    defaultMessage: 'Email',
  },
  firstCountry: {
    id: 'Core.firstCountry',
    defaultMessage: 'First Country',
  },
  phone: {
    id: 'Core.phone',
    defaultMessage: 'Phone',
  },
  nifNumber: {
    id: 'Core.nifNumber',
    defaultMessage: 'NIF Number',
  },
  insuranceExpiration: {
    id: 'Core.insuranceExpiration',
    defaultMessage: 'Insurance Expiration',
  },
  citizenship: {
    id: 'Core.citizenship',
    defaultMessage: 'Citizenship',
  },
  download: {
    id: 'Core.download',
    defaultMessage: 'Download',
  },
  errorMissingName: {
    id: 'Core.errorMissingName',
    defaultMessage: 'You must provide a name before submitting.',
  },
  errorTermsAndConditionsNotAgreed: {
    id: 'Core.errorTermsAndConditionsNotAgreed',
    defaultMessage: 'You must agree to the terms and conditions to submit.',
  },
  errorUnknown: {
    id: 'Core.errorUnknown',
    defaultMessage: 'The system encountered an error. Please contact {companyName} at ',
  },
  errorActivityOutsideBounds: {
    id: 'Core.errorActivityOutsideBounds',
    defaultMessage:
      'Submission failed. One or more values in your Activity are outside the bounds of your Request. Conflicting: {conflictingFields}.',
  },
  errorInvalidEvaluation: {
    id: 'Core.errorInvalidEvaluation',
    defaultMessage: 'Submission failed. One or more values contain invalid information. {validationMessage}.',
  },
  errorSettingContact: {
    id: 'Core.errorSettingContact',
    defaultMessage: 'You must assign a contact before advancing template state to accepted',
  },
  errorSettingInstructions: {
    id: 'Core.errorSettingInstructions',
    defaultMessage: 'You must place instructions for the operator or the contact',
  },
  errorExpiredToken: {
    id: 'Core.errorExpiredToken',
    defaultMessage: 'Your session has expired, redirecting to login page',
  },
  errorInvalidInsuranceExpirationDate: {
    id: 'Core.errorInvalidInsuranceExpirationDate',
    defaultMessage: '{fieldName} must be in the future, unless only drones < 500g are operated',
  },
  errorInvalidFields: {
    id: 'Core.errorInvalidFields',
    defaultMessage: 'Invalid fields: {missingFields}',
  },
  errorAirspaceAuthorizerServiceDown: {
    id: 'Core.errorAirspaceAuthorizerServiceDown',
    defaultMessage:
      'FAA Controlled Airspace Authorization is not available at this time, FAA authorization service is down. Please try again soon.',
  },
  copied: {
    id: 'Core.copied',
    defaultMessage: 'Copied!',
  },
  state: {
    id: 'Core.state',
    defaultMessage: 'State',
  },
  errorMFCANameTaken: {
    id: 'Core.errorMFCANameTaken',
    defaultMessage: 'A MFCA with this name already exists in the given city',
  },
  webAppTitle: {
    id: 'Core.webAppTitle',
    defaultMessage: 'AirMap Web App',
  },
} as const
