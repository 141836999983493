export const RECEIVE_TEMPLATE_ACTIVITY_RESPONSE = 'RECEIVE_TEMPLATE_ACTIVITY_RESPONSE'
export const RECEIVE_PARENT_TEMPLATE_ACTIVITY_RESPONSE = 'RECEIVE_PARENT_TEMPLATE_ACTIVITY_RESPONSE'
export const RECEIVE_TEMPLATE_SELF_ACTIVITY_RESPONSE = 'RECEIVE_TEMPLATE_SELF_ACTIVITY_RESPONSE'
export const RECEIVE_SELECTED_TEMPLATE = 'RECEIVE_SELECTED_TEMPLATE'
export const CLOSE_REVIEWER_CHANGE_STATUS_ALERT = 'CLOSE_REVIEWER_CHANGE_STATUS_ALERT'
export const OPEN_REVIEWER_CHANGE_STATUS_ALERT = 'OPEN_REVIEWER_CHANGE_STATUS_ALERT'
export const START_LOADING_TEMPLATE_DETAIL = 'START_LOADING_TEMPLATE_DETAIL'
export const STOP_LOADING_TEMPLATE_DETAIL = 'STOP_LOADING_TEMPLATE_DETAIL'
export const SET_ERROR_POSTING_COMMENT = 'SET_ERROR_POSTING_COMMENT'
export const SET_SELECTED_TEMPLATE_STATE = 'SET_SELECTED_TEMPLATE_STATE'
export const SET_IS_LOADING_CHANGE_TEMPLATE_STATUS = 'SET_IS_LOADING_CHANGE_TEMPLATE_STATUS'
export const CLEAN_UP_TEMPLATE_DETAIL = 'CLEAN_UP_TEMPLATE_DETAIL'
export const UPDATE_RECOMMENDED_FIELD_VALUE = 'UPDATE_RECOMMENDED_FIELD_VALUE'
export const UPDATE_EDITION_FIELD_VALUE = 'UPDATE_EDITION_FIELD_VALUE'
export const SET_IS_PROCESSING_MODIFICATION = 'SET_IS_PROCESSING_MODIFICATION'
export const SET_IS_POST_RECOMMEND_MODIFICATION_SUCCESS = 'SET_IS_POST_RECOMMEND_MODIFICATION_SUCCESS'
export const SET_IS_PRINT_BUTTON_DISABLED = 'SET_IS_PRINT_BUTTON_DISABLED'
export const SET_IS_LOADING_INSTRUCTIONS = 'SET_IS_LOADING_INSTRUCTIONS'
export const OPEN_COMMENTS_DRAWER = 'OPEN_COMMENTS_DRAWER'
export const CLOSE_COMMENTS_DRAWER = 'CLOSE_COMMENTS_DRAWER'
export const SET_COMMENT_TO_HIGHLIGHT = 'SET_COMMENT_TO_HIGHLIGHT'
export const SET_IS_ASSIGN_REVIEWERS_SUCCESS = 'SET_IS_ASSIGN_REVIEWERS_SUCCESS'
export const SET_IS_UNASSIGN_REVIEWERS_SUCCESS = 'SET_IS_UNASSIGN_REVIEWERS_SUCCESS'
export const SET_IS_ASSIGN_CONTACTS_SUCCESS = 'SET_IS_ASSIGN_CONTACTS_SUCCESS'
export const OPEN_POINT_OF_CONTACT_MODAL = 'OPEN_POINT_OF_CONTACT_MODAL'
export const CLOSE_POINT_OF_CONTACT_MODAL = 'CLOSE_ASSIGN_REVIEWER_MODAL'
export const SET_TEMPLATE_PLANS = 'SET_TEMPLATE_PLANS'
export const SET_IS_OPERATION_STATUS_UPDATE_SUCCESS = 'SET_IS_OPERATION_STATUS_UPDATE_SUCCESS'
export const SET_BEGIN_OPERATION_REVIEW_SUCCESS = 'SET_BEGIN_OPERATION_REVIEW_SUCCESS'
export const CLEAN_UP_TEMPLATE_PLANS = 'CLEAN_UP_TEMPLATE_PLANS'
export const RECEIVE_SELECTED_PLAN_PARENT = 'RECEIVE_SELECTED_PLAN_PARENT'
export const SET_IS_LOADING_PRIORITY = 'SET_IS_LOADING_PRIORITY'
