import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const Alert = ({ color, className }) => {
  return (
    <svg className={`${icon} ${className}`} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <path
        d="M13,14 L11,14 L11,10 L13,10 L13,14 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z M1,21 L23,21 L12,2 L1,21 Z"
        id="icon-alert"
        fill={color}
        strokeWidth="1"
        fillRule="evenodd"
      />
    </svg>
  )
}

Alert.defaultProps = {
  color: 'currentColor',
  className: ''
}

Alert.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}
