import { defineMessages } from 'react-intl'

export const SelectedAdvisoriesListMessages = defineMessages({
  selectedAdvisories: {
    id: 'SelectedAdvisoriesList.selectedLocalConstraints',
    defaultMessage: 'Selected Local Constraints'
  },
  active: {
    id: 'SelectedAdvisoriesList.active',
    defaultMessage: 'Active'
  },
  inactive: {
    id: 'SelectedAdvisoriesList.inactive',
    defaultMessage: 'Inactive'
  },
  inactiveAdvisoriesParagraph1: {
    id: 'SelectedAdvisoriesList.inactiveAdvisoriesParagraph1',
    defaultMessage: 'There are {inactiveAdvisoriesLength} advisories outside of the selected range.'
  },
  inactiveAdvisoriesParagraph2: {
    id: 'SelectedAdvisoriesList.inactiveAdvisoriesParagraph2',
    defaultMessage: 'Inactive advisories are turned off on your map.'
  },
  inactiveAdvisoriesParagraph3: {
    id: 'SelectedAdvisoriesList.inactiveAdvisoriesParagraph3',
    defaultMessage: 'Turn on '
  },
  inactiveAdvisoriesParagraph4: {
    id: 'SelectedAdvisoriesList.inactiveAdvisoriesParagraph4',
    defaultMessage: 'Show Inactive Advisories'
  },
  inactiveAdvisoriesParagraph5: {
    id: 'SelectedAdvisoriesList.inactiveAdvisoriesParagraph5',
    defaultMessage: ' to view.'
  }
})
