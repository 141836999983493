import React, { ErrorInfo } from "react"
import PropTypes from 'prop-types'
import settings from '../settings'

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '75%',
        padding: '0 3em 3em 3em'
    },
    title: {
        fontSize: '5em',
        textAlign: 'center'
    },
    errorDetail: {
        fontFamily: "monospace",
    },
    errorHeader: {
        fontWeight: "bold"
    }
}

type ErrorBoundaryState = {
    error: Error | null; 
    errorInfo: ErrorInfo | null;
}

type ErrorBoundaryProps = {
    children: React.ReactNode
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static propTypes = {
        children: PropTypes.node.isRequired
    }
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo
        })
        // You can also log error messages to an error reporting service here
        settings.firebaseAnalytics.appException(error, errorInfo.componentStack)
    }


    render() {

        if (this.state.errorInfo) {
            // Error path
            return (
                <div style={styles.wrapper}>
                    <div style={styles.paper}>
                        <h1 style={styles.title}>Something went wrong.</h1>
                        <p>Sorry, we encountered an unexpected error: </p>
                        <pre style={styles.errorHeader}>
                            {this.state.error && this.state.error.toString()}
                        </pre>
                        <pre style={styles.errorDetail}>
                            {this.state.errorInfo.componentStack}
                        </pre>
                        <br />
                        <a href="/">Back to Home</a>
                    </div>
                </div >
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary