import React from 'react'
import { privacyPolicyLink, termsAndConditionsLink } from "./common"

const privacyPolicyContent = () => (
  <>
    <p>
      {`Wenn Sie unsere App weiter benutzen und auf "AKZEPT" klicken, stimmen Sie den `}
      <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
        Datenschutzrichtlinien
      </a>
      {' und '}
      <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
        Allgemeinen Geschäftsbedingungen
      </a>
      {` von skyguide zu. Sie können Ihre Cookie-Einstellungen jederzeit ändern; allerdings funktionieren Teile der Website und der Dienstleistungen ohne Cookies nicht richtig. `}
      {`Bitte verwenden Sie die App von skyguide nicht, wenn Sie mit den Richtlinien oder Bestimmungen nicht einverstanden sind.`}
    </p>
    <p>
      {`Um Ihre Drohne zu betreiben, müssen Sie alle geltenden Vorschriften über den Betrieb von Drohnen umfassend erfüllen. `}
      {`Inhalte, Informationen und Daten werden ohne Gewähr bereitgestellt. `}
      {`Soweit unter anwendbarem Recht gestattet, übernehmen skyguide und die Drittparteien keine Haftung.`}
    </p>
  </>
)

export default privacyPolicyContent
