import React from 'react'

const DewPoint = () => (
  <g fillRule="evenodd">
    <path d="M90.5698138,54.9684226 L62.5911151,6.60074062 C62.0713703,5.57907788 61.0367429,5 60.0021528,5 C58.9675627,5 57.9279986,5.58458852 57.4131905,6.60074062 L29.5613286,54.8257616 L29.4301769,54.9732759 C23.2126883,67.0637977 23.6012813,81.9250204 30.2073629,94.747729 C36.6822556,107.422881 47.9520754,115 59.9978379,115 C72.0436003,115 83.3134202,107.422182 89.7883128,94.747729 C96.3992441,81.9250204 96.7878371,66.9176401 90.5703609,54.9690799 L90.5698138,54.9684226 Z" />
    <path
      fill="#FFF"
      d="M61.6870922,102.896775 C59.875316,102.896775 58.5783479,101.437996 58.5783479,99.4001817 C58.5783479,97.3623668 59.875316,95.9035879 61.6870922,95.9035879 C69.458953,95.9035879 75.9338456,88.6208825 75.9338456,79.7325412 C75.9338456,77.6947263 77.2308137,76.2359474 79.0425899,76.2359474 C80.8543661,76.2359474 82.1513342,77.6947263 82.1513342,79.7325412 C82.0201825,92.5552497 72.8251013,102.896775 61.6870922,102.896775 Z"
    />
  </g>
)

export default DewPoint
