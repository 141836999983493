import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Router } from "react-router-dom"
import { History } from "history"

import { Routes } from './App.routes'
import { create } from 'jss'
import { createGenerateClassName, jssPreset, StylesProvider, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import theme from './muiTheme'

import { IntlProvider } from 'react-intl'
import { translationMessages } from './i18n'
import {
  translationMessages as airmapIntlTranslationMessages,
  customTranslationMessages,
} from 'translations/translations.constants'

import { AirmapIntlProvider, airmapIntl } from 'libs/airmap-intl'
import AppErrorBoundary from './App.error'
import { AnyAction, Store } from '@reduxjs/toolkit'
import { SUPPORTED_TRANSLATIONS } from '@env'

airmapIntl.setSupportedTranslations(SUPPORTED_TRANSLATIONS)
airmapIntl.setTranslationMessages(airmapIntlTranslationMessages, customTranslationMessages)

const language = airmapIntl.getAvailableLanguageLocaleFromNavigator()
const messages = translationMessages[language  as keyof typeof translationMessages]
const customMessages = customTranslationMessages[language as keyof typeof customTranslationMessages] || {}

const styleNode = document.createComment('material-ui-styles')
document.head.insertBefore(styleNode, document.head.firstChild)
const generateClassName = createGenerateClassName({
  productionPrefix: 'WebApp-'
})
const jss = create({ ...jssPreset(), insertionPoint: 'material-ui-styles' })

// FixMe: Better types for Redux
type AppComponentProps = {
  history: History<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<any, AnyAction>;
}

// todo MuiThemeProvider is deprecated with react hooks, we can remove it and just leave ThemeProvider eventually
export function AppComponent({ history, store }: AppComponentProps) {
  return (
    <AppErrorBoundary>
      <StylesProvider generateClassName={generateClassName} jss={jss}>
        <AirmapIntlProvider lang={language}>
          <IntlProvider locale={language} messages={{ ...messages, ...customMessages }}>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                  <Provider store={store}>
                    <Router history={history}>
                      <Routes />
                    </Router>
                  </Provider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </IntlProvider>
        </AirmapIntlProvider>
      </StylesProvider>
    </AppErrorBoundary>
  )
}

AppComponent.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object
}
