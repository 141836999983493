import { airmapAuth } from '..'

export function canCreate(resourceName) {
  return airmapAuth.can(buildCreatePermission(resourceName))
}

export function canRead(resourceName) {
  return airmapAuth.can(buildReadPermission(resourceName))
}

export function canUpdate(resourceName) {
  return airmapAuth.can(buildUpdatePermission(resourceName))
}

export function canDelete(resourceName) {
  return airmapAuth.can(buildDeletePermission(resourceName))
}

export function canSubmit(resourceName) {
  return airmapAuth.can(buildSubmitPermission(resourceName))
}

export function canSurrender(resourceName) {
  return airmapAuth.can(buildSurrenderPermission(resourceName))
}

export function can(permission) {
  return airmapAuth.can(permission)
}

export function buildCRUDPermissions(resourceName) {
  return [
    buildCreatePermission(resourceName),
    buildReadPermission(resourceName),
    buildUpdatePermission(resourceName),
    buildDeletePermission(resourceName)
  ]
}

export function buildCreatePermission(resourceName) {
  return `create_${resourceName}`
}

export function buildReadPermission(resourceName) {
  return `read_${resourceName}`
}

export function buildUpdatePermission(resourceName) {
  return `update_${resourceName}`
}

export function buildDeletePermission(resourceName) {
  return `delete_${resourceName}`
}

export function buildSubmitPermission(resourceName) {
  return `submit_${resourceName}`
}

export function buildSurrenderPermission(resourceName) {
  return `surrender_${resourceName}`
}

export const removeUriUnsupportedCharsParams = uri => {
  const unsupportedCharactersRegex = /[|`%^{}/\\]/g

  const splittedUrl = uri.split('?')
  let sanitizedUri = ''

  if (splittedUrl.length > 1 && uri.match(unsupportedCharactersRegex)) {
    const queryParams = splittedUrl[1].split('&')
    const sanitizedQueryParams = queryParams.filter(param => !param.match(unsupportedCharactersRegex)).join('&')

    sanitizedUri = `${splittedUrl[0]}?${sanitizedQueryParams}`
  }

  return sanitizedUri || uri
}
