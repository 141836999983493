import React from 'react'

export const SkyguideLogo = () => (
  <svg
    id="skyguide-logo"
    version="2.0"
    preserveAspectRatio="xMinYMin meet"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 153.01 48.46"
  >
    <polygon
      fill="#2850a0"
      points="31.83 32.31 35.34 32.31 35.34 24.73 42.29 32.31 46.62 32.31 39.13 24.09 45.26 16.16 40.99 16.15 35.33 23.51 35.34 10.97 31.83 10.97 31.83 32.31"
    />
    <path
      fill="#2850a0"
      d="M19.87,27.58a2.34,2.34,0,0,0,.87,1.47,3.75,3.75,0,0,0,2.15.55,4.37,4.37,0,0,0,2.49-.52,1.61,1.61,0,0,0,.75-1.34,1.38,1.38,0,0,0-.78-1.3,6.84,6.84,0,0,0-1.78-.61l-2.24-.49a6.14,6.14,0,0,1-4-2.2,4.77,4.77,0,0,1-.24-4.39,4.34,4.34,0,0,1,1.18-1.5,6,6,0,0,1,1.9-1,8.31,8.31,0,0,1,2.62-.38,9.13,9.13,0,0,1,2.64.35,6.16,6.16,0,0,1,2,1,5.3,5.3,0,0,1,1.34,1.49,6.75,6.75,0,0,1,.76,1.9H25.66l-.09-.19a2.48,2.48,0,0,0-.88-1,3.35,3.35,0,0,0-2-.51,3.67,3.67,0,0,0-2,.45,1.46,1.46,0,0,0-.66,1.26,1.39,1.39,0,0,0,.79,1.3,7.59,7.59,0,0,0,1.76.64c.7.18,1.45.35,2.25.52a12.35,12.35,0,0,1,2.23.71A4.42,4.42,0,0,1,29,25.26a4.42,4.42,0,0,1,.61,2.48,4.56,4.56,0,0,1-.41,1.92A4.31,4.31,0,0,1,28,31.2a6.28,6.28,0,0,1-2.09,1,10.2,10.2,0,0,1-3,.38,8.44,8.44,0,0,1-2.67-.38,6.4,6.4,0,0,1-2-1,5.85,5.85,0,0,1-1.4-1.58,6,6,0,0,1-.76-2Z"
    />
    <path
      fill="#2850a0"
      d="M132,32.22A7.25,7.25,0,0,0,134,31.14a6.69,6.69,0,0,0,1.49-1.61,7.67,7.67,0,0,0,1-2h-3.81a5.5,5.5,0,0,1-1.33,1.31,3.47,3.47,0,0,1-2,.52,3.83,3.83,0,0,1-2.91-1.14,4.6,4.6,0,0,1-1.2-3h11.43c0-.12,0-.27.06-.43s0-.3.06-.49a5.53,5.53,0,0,0,0-.61,9.35,9.35,0,0,0-.5-3,7.44,7.44,0,0,0-1.48-2.53,7.21,7.21,0,0,0-2.39-1.72,7.79,7.79,0,0,0-3.25-.64,8.4,8.4,0,0,0-3.11.56A6.9,6.9,0,0,0,123.62,18a7.45,7.45,0,0,0-1.55,2.63,10.52,10.52,0,0,0-.55,3.56,10.62,10.62,0,0,0,.55,3.57,7.41,7.41,0,0,0,1.55,2.62,6.84,6.84,0,0,0,2.44,1.63,9.45,9.45,0,0,0,5.89.17m-6.77-9.67A4.72,4.72,0,0,1,126.52,20a3.7,3.7,0,0,1,2.62-.94,3.62,3.62,0,0,1,2.73,1,4.47,4.47,0,0,1,1.23,2.49Z"
    />
    <path
      fill="#2850a0"
      d="M113.55,31.93a6.26,6.26,0,0,0,1.91-1.6h.15l.46,2H119V11h-3.5v7h-.15a5.91,5.91,0,0,0-.66-.71,4.38,4.38,0,0,0-1-.7,5.59,5.59,0,0,0-1.32-.52,6.18,6.18,0,0,0-1.59-.2,7,7,0,0,0-2.83.55,6.12,6.12,0,0,0-2.21,1.6,7.43,7.43,0,0,0-1.45,2.62,11.69,11.69,0,0,0-.52,3.61,11.64,11.64,0,0,0,.52,3.61,7.43,7.43,0,0,0,1.45,2.62,6,6,0,0,0,2.21,1.6,7,7,0,0,0,2.83.55,5.66,5.66,0,0,0,2.82-.68m-3.81-2.84a3.59,3.59,0,0,1-1.31-1,4.51,4.51,0,0,1-.88-1.61,7.15,7.15,0,0,1-.32-2.29,7.13,7.13,0,0,1,.32-2.28,4.65,4.65,0,0,1,.88-1.62,3.59,3.59,0,0,1,1.31-1,4.16,4.16,0,0,1,3.2,0,3.5,3.5,0,0,1,1.31,1,4.66,4.66,0,0,1,.89,1.62,7.42,7.42,0,0,1,.32,2.28,7.45,7.45,0,0,1-.32,2.29,4.52,4.52,0,0,1-.89,1.61,3.5,3.5,0,0,1-1.31,1,4.16,4.16,0,0,1-3.2,0"
    />
    <path
      fill="#2850a0"
      d="M97.81,32.31h3.51V16.15H97.81Zm3.34-18.59a2.08,2.08,0,0,0,.62-1.53,2,2,0,0,0-.62-1.52,2.19,2.19,0,0,0-1.6-.61,2.15,2.15,0,0,0-1.59.61,2.07,2.07,0,0,0-.61,1.52A2.11,2.11,0,0,0,98,13.72a2.19,2.19,0,0,0,1.59.61,2.23,2.23,0,0,0,1.6-.61"
    />
    <path
      fill="#2850a0"
      d="M89.26,32.05a4.45,4.45,0,0,0,1.88-1.72h.15l.46,2h2.89V16.15h-3.5v9.54a4.13,4.13,0,0,1-.25,1.45,3.29,3.29,0,0,1-.7,1.19,3.08,3.08,0,0,1-1.11.79,3.45,3.45,0,0,1-1.45.29,3.22,3.22,0,0,1-2.42-1,3.71,3.71,0,0,1-.93-2.69V16.15H80.77v9.91a7.68,7.68,0,0,0,.45,2.7,6.12,6.12,0,0,0,1.21,2,5.36,5.36,0,0,0,1.88,1.33,5.92,5.92,0,0,0,2.41.47,5.72,5.72,0,0,0,2.54-.56"
    />
    <path
      fill="#2850a0"
      d="M75.91,36.68a6.21,6.21,0,0,0,1.89-4.83V16.15H74.9l-.46,2h-.15a6.19,6.19,0,0,0-1.9-1.6,5.61,5.61,0,0,0-2.82-.69,7.43,7.43,0,0,0-2.75.5,6,6,0,0,0-2.16,1.5,7,7,0,0,0-1.43,2.43,9.8,9.8,0,0,0-.52,3.34A9.8,9.8,0,0,0,63.23,27a7,7,0,0,0,1.43,2.44,5.86,5.86,0,0,0,2.16,1.49,7.23,7.23,0,0,0,2.75.5,5.58,5.58,0,0,0,2.8-.67,5.44,5.44,0,0,0,1.77-1.46h.15v2.59a3,3,0,0,1-1.06,2.49,4.53,4.53,0,0,1-2.9.86A5.3,5.3,0,0,1,68,34.73a2.46,2.46,0,0,1-1.31-1.51H63A7.31,7.31,0,0,0,65.47,37a7.68,7.68,0,0,0,4.86,1.38,8,8,0,0,0,5.58-1.72M67.34,27a4.73,4.73,0,0,1-1.13-3.38,4.77,4.77,0,0,1,1.12-3.38,3.73,3.73,0,0,1,2.85-1.19,4.26,4.26,0,0,1,1.6.3,3.93,3.93,0,0,1,1.31.9A4.35,4.35,0,0,1,74,21.69a5.24,5.24,0,0,1,.32,1.93A5.3,5.3,0,0,1,74,25.56,4.29,4.29,0,0,1,73.1,27a3.42,3.42,0,0,1-1.29.9,4.26,4.26,0,0,1-1.6.3A3.76,3.76,0,0,1,67.34,27"
    />
    <polygon
      fill="#2850a0"
      points="58.78 16.15 54.36 27.58 54.21 27.58 49.79 16.15 45.98 16.15 52.53 32.31 50.09 38.4 53.75 38.4 62.59 16.15 58.78 16.15"
    />
  </svg>
)
