import { inputTypes, requirementTypes } from '../constants/scopes.constants'

const PLACEHOLDER_VALUE = 'placeholder'

export const getCustomScopeForAttachedFiles = attachedFileFields => ({
  details: {
    fields: attachedFileFields
  },
  hasCustomUI: true,
  type: inputTypes.FILE,
  id: 'additional_files_group',
  inputValue: PLACEHOLDER_VALUE,
  locked: false,
  requirement: { type: requirementTypes.OPTIONAL },
  technical_name: 'additional_files_group',
  value: { reference: PLACEHOLDER_VALUE }
})
