import React, { useState } from 'react'
import { Close } from 'modules/icons/components/Close/Close.component'
import { IconButton } from 'ui-styling/components/buttons/IconButton/IconButton.component'
import settings from 'settings'
import { possibleEnvironments } from 'modules/core/constants/environment.constants'
import styles from '../EnvironmentIndicators.module.scss'
import bannerStyles from './EnvironmentIndicatorBanner.module.scss'

const environment = settings.appEnv
const officialEnvironment = Object.values(possibleEnvironments).find(possibleEnvironment =>
  environment.includes(possibleEnvironment)
)

const EnvironmentIndicatorBanner = () => {
  const [showBanner, setShowBanner] = useState(true)
  return (
    showBanner ? (
      <div className={`${bannerStyles.banner_wrapper} ${styles[`colors___${officialEnvironment}_variant`] || styles.colors___default}`}>
        <h2 className={bannerStyles.banner_title}>{officialEnvironment}</h2>
        <IconButton
          onClick={() => setShowBanner(false)}
          icon={<Close className={bannerStyles.banner_close_icon} />}
          id="close-environment-indicator-banner"
          className={styles[`colors___${officialEnvironment}_variant`] || bannerStyles.colors___default}
        />
      </div>
    ) : null
  )
}

export default EnvironmentIndicatorBanner
