export const mapboxGeocoderStyles = {
  url: 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css'
}
export const colors = {
  red: '#eb6853',
  orange: '#fc4c02',
  yellow: '#fce444',
  green: '#6cc04a',
  teal: '#88dbdf',
  blue: '#333f48',
  grey: '#A9ACB0',
  light_grey: '#f8f8f8',
  black: '#363e47',
  navy: '#333e47',
  white: '#ffffff',
  light_teal: '#E7F8F9',
  purple: '#800080',
  dark_black: '#000'
}
export const MeasurementTypes = {
  IMPERIAL: 'imperial',
  METRIC: 'metric'
}
export const LocalStorageKeys = {
  MEASUREMENT_TYPE: 'measurement_type',
  IS_FLIGHT_PUBLIC: 'is_flight_public'
}
