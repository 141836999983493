import { defineMessages } from 'react-intl'

export const AdvisoriesFilterMessages = defineMessages({
  filterAdvisoriesTitle: {
    id: 'AdvisoriesFilter.filterAdvisoriesTitle',
    defaultMessage: 'Filter Advisories'
  },
  timeRangeTab: {
    id: 'AdvisoriesFilter.timeRangeTab',
    defaultMessage: 'Time Range'
  },
  dateRangeTab: {
    id: 'AdvisoriesFilter.dateRangeTab',
    defaultMessage: 'Date Range'
  },
  showInactiveAdvisories: {
    id: 'AdvisoriesFilter.showInactiveAdvisories',
    defaultMessage: 'Show Inactive Advisories and Airspace Layers'
  },
  disclaimer: {
    id: 'AdvisoriesFilter.disclaimer',
    defaultMessage: 'Disclaimer'
  },
  disclaimerBody: {
    id: 'AdvisoriesFilter.disclaimerBody',
    defaultMessage:
      'The advisories and airspaces you see are what is currently scheduled. Always check for new NOTAMS and advisories before you intend to fly.'
  },
  reset: {
    id: 'AdvisoriesFilter.reset',
    defaultMessage: 'Reset'
  },
  close: {
    id: 'AdvisoriesFilter.close',
    defaultMessage: 'Close'
  },
  apply: {
    id: 'AdvisoriesFilter.apply',
    defaultMessage: 'Apply'
  }
})
