import { translationOptions, translationMessages, languageCodes } from '../../translations/translations.constants'

import { NODE_ENV } from '@env'
const isDevelopment = NODE_ENV === 'development'

export default class AirmapIntl {
  constructor() {
    this.translationMessages = {}

    this.supportedTranslations = []

    this.availableTranslations = []

    this.browserLanguage = this.getAvailableLanguageLocaleFromNavigator()

    this.userSelectionLanguage = ''

    this.defaultBrowserLanguage = languageCodes.en

    this.referenceDelimiters = {
      start: '{',
      end: '}'
    }

    this.languageChangedEvent = new Event('languageChanged')
  }

  static parseLanguageSelection(language) {
    return language.toLowerCase().split(/[_-]+/)[0]
  }

  getAvailableLanguageLocaleFromNavigator() {
    const language = (navigator.languages && navigator.languages[0]) || navigator.language || ''
    return this.formatToAvailableLanguageCode(language)
  }

  replaceMessageReferences(message, references) {
    const refRegex = new RegExp(
      Object.keys(references)
        .map(r => this.getTextToReplace(r))
        .join('|'),
      'gi'
    )

    const newMessage = message.replace(refRegex, matched => references[this.removeReferenceDelimiters(matched)])

    const hasMissingReferences = this.hasReferences(newMessage)

    if (hasMissingReferences && isDevelopment) {
      console.warn(`Some references where not replaced. Result message: ${newMessage}`)
    }

    return newMessage
  }

  setUserLanguage(language) {
    this.userSelectionLanguage = language
    window.dispatchEvent(this.languageChangedEvent)
  }

  setTranslationMessages(translationMessages, customTranslationMessages) {
    const mergedTranslationMessages = Object.keys(translationMessages).reduce((languageMessages, language) => {
      const messages = translationMessages[language]
      const customMessages = customTranslationMessages[language] || {}

      return { ...languageMessages, [language]: { ...messages, ...customMessages } }
    }, {})

    this.translationMessages = mergedTranslationMessages
  }

  setSupportedTranslations(supportedTranslations) {
    this.supportedTranslations = this.mapSupportedTranslationListToOptions(supportedTranslations)
  }

  setAvailableTranslations(availableTranslations) {
    this.availableTranslations = availableTranslations
      .map(availableTranslation =>
        this.supportedTranslations.find(translation => translation.value === availableTranslation.toLowerCase())
      )
      .filter(supportedTranslation => supportedTranslation)
  }

  get referenceRegex() {
    const { start, end } = this.referenceDelimiters
    const text = '(.*?)'

    return new RegExp(`${start}${text}${end}`)
  }

  getTextToReplace(key) {
    const { start, end } = this.referenceDelimiters

    return `${start}${key}${end}`
  }

  hasReferences(message) {
    return this.referenceRegex.test(message)
  }

  removeReferenceDelimiters(reference) {
    const sliceLength = this.referenceDelimiters.start.length
    return reference.substr(sliceLength).slice(0, -sliceLength)
  }

  translateMessage(messageObject, references) {
    if (!messageObject) {
      console.warn('No translation object available')
      return ''
    }
    const { id: messageId, defaultMessage } = messageObject
    const languageToShow = this.userSelectionLanguage || airmapIntl.getAvailableLanguageLocaleFromNavigator()
    let translatedMessage = this.translationMessages[languageToShow]?.[messageId]

    if (typeof translatedMessage !== 'string') {
      translatedMessage = defaultMessage

      if (isDevelopment) {
        console.warn(
          `Translated message does not exist for ${messageId} in "${languageToShow}" language, using default message "${defaultMessage}" as fallback`
        )
      }
    }

    return references ? this.replaceMessageReferences(translatedMessage, references) : translatedMessage
  }

  mapSupportedTranslationListToOptions = translationString => {
    let translationOptionsResult = [translationOptions[languageCodes.en]]

    if (translationString) {
      const translationCodes = translationString.split(',')
      if (!translationCodes.includes(languageCodes.en)) {
        translationCodes.push(languageCodes.en)
      }

      translationOptionsResult = translationCodes
        .map(languageCode => {
          return translationOptions[languageCode.toLowerCase().trim()]
        })
        .filter(languageCode => languageCode)
    }

    return translationOptionsResult
  }

  /**
   * Checks if translation exists for a given language code and return the best suitable language code
   * Sets 'en' by default when non-existing translations
   */
  formatToAvailableLanguageCode = languageCode => {
    let formattedLanguage = languageCodes.en

    const languageLowerCase = languageCode.toLowerCase()
    const doesLanguageIncludeRegionCode = languageLowerCase.includes('-')
    const languageCodeWithoutRegion = doesLanguageIncludeRegionCode
      ? languageLowerCase.split(/[_-]+/)[0]
      : languageLowerCase

    if (this.isExistingTranslation(languageLowerCase)) {
      formattedLanguage = languageLowerCase
    } else if (doesLanguageIncludeRegionCode && this.isExistingTranslation(languageCodeWithoutRegion)) {
      formattedLanguage = languageCodeWithoutRegion
    } else {
      const availableTranslationOption = this.availableTranslations.find(translationOption =>
        translationOption.value.startsWith(languageCodeWithoutRegion)
      )

      if (availableTranslationOption) {
        formattedLanguage = availableTranslationOption.value
      }
    }

    return formattedLanguage
  }

  isExistingTranslation = languageCode => {
    const isTranslationMessage = Boolean(translationMessages[languageCode])
    const isAvailableTranslation = Boolean(
      this.availableTranslations.find(translation => translation.value === languageCode)
    )

    return isTranslationMessage && isAvailableTranslation
  }
}

export const airmapIntl = new AirmapIntl()
