export const PURGE_OPERATION_CREATION = 'PURGE_OPERATION_CREATION'
export const SET_CURRENT_CREATION_SCOPES = 'SET_CURRENT_CREATION_SCOPES'
export const SET_CURRENT_OPERATION_NAME = 'SET_CURRENT_OPERATION_NAME'
export const SET_IS_CREATION_FINISHED = 'SET_IS_CREATION_FINISHED'
export const SET_IS_FROM_EVALUATION_PROCESS = 'SET_IS_FROM_EVALUATION_PROCESS'
export const SET_IS_PROCESSING_CREATION = 'SET_IS_PROCESSING_CREATION'
export const SET_JUST_CREATED_OPERATION = 'SET_JUST_CREATED_OPERATION'
export const SET_OPERATION_GEOMETRY = 'SET_OPERATION_GEOMETRY'
export const SET_OPERATION_ID = 'SET_OPERATION_ID'
export const SET_OPERATION_TYPE = 'SET_OPERATION_TYPE'
export const SET_PARENT_OPERATION_REFERENCE_ID = 'SET_PARENT_OPERATION_REFERENCE_ID'
export const UPDATE_SCOPE_FIELD_VALUE = 'UPDATE_SCOPE_FIELD_VALUE'
export const SET_OPERATION_WORKFLOW = 'SET_OPERATION_WORKFLOW'
export const SET_OPERATION_ERROR = 'SET_OPERATION_ERROR'
export const SET_IS_LATE_ANNOUNCEMENT_ENABLED = 'SET_IS_LATE_ANNOUNCEMENT_ENABLED'
