export const SET_CLASSIFIED_LAYERS = 'SET_CLASSIFIED_LAYERS'
export const SET_MAP_JURISDICTIONS = 'SET_MAP_JURISDICTIONS'
export const SET_SELECTED_ADVISORY = 'SET_SELECTED_ADVISORY'
export const SET_CLICKED_ADVISORIES = 'SET_CLICKED_ADVISORIES'
export const CLEAR_SELECTED_ADVISORY = 'CLEAR_SELECTED_ADVISORY'
export const CLEAR_CLICKED_ADVISORIES = 'CLEAR_CLICKED_ADVISORIES'
export const SET_IS_FETCHING_CLICKED_ADVISORIES = 'SET_IS_FETCHING_CLICKED_ADVISORIES'
export const SET_CLICKED_ADVISORIES_FETCHED = 'SET_CLICKED_ADVISORIES_FETCHED'
export const SET_ADVISORIES = 'SET_ADVISORIES'
export const CLEAR_ADVISORIES = 'CLEAR_ADVISORIES'
export const SET_MAP_BOUNDS_GEOMETRY = 'SET_MAP_BOUNDS_GEOMETRY'
export const SET_VIEWPORT_COORDINATES = 'SET_VIEWPORT_COORDINATES'
export const SET_SELECTED_RULESETS = 'SET_SELECTED_RULESETS'
export const SET_IS_MAP_PARTIALLY_OVER_UNAVAILABLE_JURISDICTION = 'SET_IS_MAP_PARTIALLY_OVER_UNAVAILABLE_JURISDICTION'
export const SET_IS_FETCHING_RULESET_INFORMATION = 'SET_IS_FETCHING_RULESET_INFORMATION'
export const SET_RULESET_INFORMATION = 'SET_RULESET_INFORMATION'
export const SET_APPLIED_ADVISORIES_FILTER = 'SET_APPLIED_ADVISORIES_FILTER'
export const SHOW_INACTIVE_ADVISORIES = 'SHOW_INACTIVE_ADVISORIES'
export const HIDE_INACTIVE_ADVISORIES = 'HIDE_INACTIVE_ADVISORIES'
export const SET_LOCATION_NAME = 'SET_LOCATION_NAME'
export const SET_LOCATION_WEATHER = 'SET_LOCATION_WEATHER'
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL'
export const SET_HIGHLIGHTED_LAYER = 'SET_HIGHLIGHTED_LAYER'
export const CLEAR_HIGHLIGHTED_LAYER = 'CLEAR_HIGHLIGHTED_LAYER'
