import { CoreMessages } from 'modules/core/core.messages'

export const SFOCoreMessages = {
  ...CoreMessages,
  beginReview: {
    id: 'Core.beginReview',
    defaultMessage: 'Begin Review',
  },
  confirmBeginReview: {
    id: 'Core.confirmBeginReview',
    defaultMessage: 'Are you sure you want to start the review of the request ?',
  },
  copyRequestSuccessMessage: {
    id: 'Core.copyRequestSuccessMessage',
    defaultMessage: 'Request has been copied successfully',
  },
  beginReviewSuccess: {
    id: 'Core.beginReviewSuccess',
    defaultMessage: 'The review was successfully begun',
  },
  confirmRequestStatus: {
    id: 'Core.confirmRequestStatus',
    defaultMessage:
      'Are you sure you want to change the request status from {selectedTemplateState} to {selectedReviewStatePendingConfirm}?',
  },
  confirmRequestVoting: {
    id: 'Core.confirmRequestVoting',
    defaultMessage: 'Are you sure you want to vote as {selectedReviewVotePendingConfirm}?',
  },
  changeStatusSuccess: {
    id: 'Core.changeStatusSuccess',
    defaultMessage: 'The status of the request was successfully changed',
  },
  request: {
    id: 'Core.request',
    defaultMessage: 'REQUEST',
  },
  log: {
    id: 'Core.log',
    defaultMessage: 'LOG',
  },
  messages: {
    id: 'Core.messages',
    defaultMessage: 'MESSAGES',
  },
  accepted: {
    id: 'Core.accepted',
    defaultMessage: 'Accepted',
  },
  accepted_plan: {
    id: 'Core.accepted_plan',
    defaultMessage: '',
  },
  terminated: {
    id: 'Core.terminated',
    defaultMessage: 'Terminated',
  },
  submitted: {
    id: 'Core.submitted',
    defaultMessage: 'Submitted',
  },
  committed: {
    id: 'Core.committed',
    defaultMessage: 'Committed',
  },
  review_in_progress: {
    id: 'Core.review_in_progress',
    defaultMessage: 'In Review',
  },
  review_in_progress_plan: {
    id: 'Core.review_in_progress_plan',
    defaultMessage: '',
  },
  work_in_progress: {
    id: 'Core.work_in_progress',
    defaultMessage: 'In Progress',
  },
  approvedUILabel: {
    id: 'Core.approvedUILabel',
    defaultMessage: 'Approved',
  },
  submittedUILabel: {
    id: 'Core.submittedUILabel',
    defaultMessage: 'Acknowledged',
  },
  submitted_plan: {
    id: 'Core.submitted_plan',
    defaultMessage: '',
  },
  accepted_template: {
    id: 'Core.accepted_template',
    defaultMessage: '',
  },
  rejected: {
    id: 'Core.rejected',
    defaultMessage: 'Rejected',
  },
  completed: {
    id: 'Core.completed',
    defaultMessage: 'Completed',
  },
  rescinded: {
    id: 'Core.rescinded',
    defaultMessage: 'Rescinded',
  },
  under_consultation: {
    id: 'Core.under_consultation',
    defaultMessage: 'In Consultation',
  },
  under_consultation_rejected: {
    id: 'Core.under_consultation_rejected',
    defaultMessage: 'Consultation Rejected',
  },
  under_consultation_accepted: {
    id: 'Core.under_consultation_accepted',
    defaultMessage: 'Consultation Approved',
  },
  expired: {
    id: 'Core.expired',
    defaultMessage: 'Expired',
  },
  canceled: {
    id: 'Core.canceled',
    defaultMessage: 'Canceled',
  },
  canceled_plan: {
    id: 'Core.canceled_plan',
    defaultMessage: '',
  },
  date_range: {
    id: 'Core.date_range',
    defaultMessage: 'Request Period',
  },
  date_range_plan: {
    id: 'Core.date_range_plan',
    defaultMessage: 'Activity Period',
  },
  activity: {
    id: 'Core.activity',
    defaultMessage: 'ACTIVITY',
  },
  activities: {
    id: 'Core.activities',
    defaultMessage: 'activities',
  },
  open: {
    id: 'Core.open',
    defaultMessage: 'Open',
  },
  reviewer: {
    id: 'Core.reviewer',
    defaultMessage: 'Reviewer',
  },
  operator: {
    id: 'Core.operator',
    defaultMessage: 'Operator',
  },
  authority: {
    id: 'Core.authority',
    defaultMessage: 'Authority',
  },
  createConsultation: {
    id: 'Core.createConsultation',
    defaultMessage: 'Create Consultation',
  },
  notFoundConsultants: {
    id: 'Core.notFoundConsultants',
    defaultMessage: "We couldn't match your search with the consultant's name",
  },
  role: {
    id: 'Core.role',
    defaultMessage: 'Role',
  },
  authority_params: {
    id: 'Core.authority_params',
    defaultMessage: 'Roles',
  },
  contact: {
    id: 'Core.contact',
    defaultMessage: 'In Contact',
  },
  observer: {
    id: 'Core.observer',
    defaultMessage: 'Informational',
  },
  monitor: {
    id: 'Core.monitor',
    defaultMessage: 'Impacted',
  },
  planUpdateSuccess: {
    id: 'Core.planUpdateSuccess',
    defaultMessage: 'Activity was successfully updated',
  },
  planUpdateFailure: {
    id: 'Core.planUpdateFailure',
    defaultMessage: "We couldn't update the activity, please try again later",
  },
  confirmOperationCancelation: {
    id: 'Core.confirmOperationCancelation',
    defaultMessage: 'Are you sure you want to cancel this activity?',
  },
  closed: {
    id: 'Core.closed',
    defaultMessage: 'Closed',
  },
  close: {
    id: 'Core.close',
    defaultMessage: 'Close',
  },
  postpone: {
    id: 'Core.postpone',
    defaultMessage: 'Postpone',
  },
  internal_instruction: {
    id: 'Core.internal_instruction',
    defaultMessage: 'Point of Contact',
  },
  external_instruction: {
    id: 'Core.external_instruction',
    defaultMessage: 'Operator',
  },
  date_to_error: {
    id: 'Core.date_to_error',
    defaultMessage: 'The date must be the same or after the Date From date.',
  },
  max_duration_error: {
    id: 'Core.max_duration_error',
    defaultMessage: 'The duration must be between the limit of time set',
  },
  activity_limit_24_hours_error: {
    id: 'Core.activity_limit_24_hours_error',
    defaultMessage: 'The activity limit is 24 hrs',
  },
  cancelledUILabel: {
    id: 'Core.cancelledUILabel',
    defaultMessage: 'Canceled by Operator',
  },
  confirmTerminateActivity: {
    id: 'Core.confirmTerminateActivity',
    defaultMessage: 'Are you sure about terminate the activity?',
  },
  dashboard: {
    id: 'Core.dashboard',
    defaultMessage: 'Dashboard',
  },
  total: {
    id: 'Core.total',
    defaultMessage: 'Total',
  },
  map: {
    id: 'Core.map',
    defaultMessage: 'Map',
  },
  activitiesTitle: {
    id: 'Core.activitiesTitle',
    defaultMessage: 'Activities',
  },
  requests: {
    id: 'Core.requests',
    defaultMessage: 'Requests',
  },
  read_state: {
    id: 'Core.read_state',
    defaultMessage: 'Read State',
  },
  assigned_to: {
    id: 'Core.assigned_to',
    defaultMessage: 'Assigned To',
  },
  read: {
    id: 'Core.read',
    defaultMessage: 'Read',
  },
  unread: {
    id: 'Core.unread',
    defaultMessage: 'Unread',
  },
  operationPrintTitle: {
    id: 'Core.operationPrintTitle',
    defaultMessage: 'DRONE OPERATION STATEMENT - {companyName}',
  },
  me: {
    id: 'Core.me',
    defaultMessage: 'Me',
  },
  unassigned: {
    id: 'Core.unassigned',
    defaultMessage: 'Unassigned',
  },
  acceptedUILabel: {
    id: 'Core.acceptedUILabel',
    defaultMessage: 'Accept',
  },
  info: {
    id: 'Core.info',
    defaultMessage: 'Info',
  },
  hide: {
    id: 'Core.hide',
    defaultMessage: 'Hide',
  },
  unhide: {
    id: 'Core.unhide',
    defaultMessage: 'Unhide',
  },
  sfoAppTitle: {
    id: 'Core.sfoAppTitle',
    defaultMessage: 'AirMap Web App',
  },
  aircraft_category: {
    id: 'Core.aircraft_category',
    defaultMessage: 'Aircraft Category',
  },
  overrideImpactedDecision: {
    id: 'Core.overrideImpactedDecision',
    defaultMessage: 'You will override an incomplete Impacted consultation, ensure you want to proceed.',
  },
  finalConsultationDecision: {
    id: 'Core.finalConsultationDecision',
    defaultMessage: 'Impacted consultation vote will be overriden, ensure you want to proceed.',
  },
  start_review: {
    id: 'Core.start_review',
    defaultMessage: 'Acknowledge',
  },
  start_review_template: {
    id: 'Core.start_review_template',
    defaultMessage: '',
  },
  accept: {
    id: 'Core.accept',
    defaultMessage: 'Accept',
  },
  accept_template: {
    id: 'Core.accept_template',
    defaultMessage: '',
  },
  accept_plan: {
    id: 'Core.accept_plan',
    defaultMessage: '',
  },
  accept_consult: {
    id: 'Core.accept_consult',
    defaultMessage: 'Accept',
  },
  accept_consult_plan: {
    id: 'Core.accept_consult_plan',
    defaultMessage: '',
  },
  accept_consult_plan_status_header: {
    id: 'Core.accept_consult_plan_status_header',
    defaultMessage: '',
  },
  deny_consult: {
    id: 'Core.deny_consult',
    defaultMessage: 'Deny',
  },
  deny_consult_plan: {
    id: 'Core.deny_consult_plan',
    defaultMessage: '',
  },
  deny_consult_plan_status_header: {
    id: 'Core.deny_consult_plan_status_header',
    defaultMessage: '',
  },
  preference: {
    id: 'Core.preference',
    defaultMessage: 'Preference',
  },
  phone_number_error: {
    id: 'Core.phone_number_error',
    defaultMessage: "The phone number doesn't have the correct format",
  },
  flight_level: {
    id: 'Core.flight_level',
    defaultMessage: '',
  },
} as const
