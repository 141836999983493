import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './Loader.module.scss'

export const Loader = ({ size, className }) => {
  return (
    <div className={`${styles.loader__wrapper} ${className}`}>
      <CircularProgress classes={{ svg: styles.loader }} size={size} />
    </div>
  )
}

Loader.defaultProps = {
  size: 40
}

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
}
