import { appVersion } from '../../../app-version'
import { skyguidePrivacyPolicy } from '../shared/privacy-policy/skyguide.privacy-policy'
import { SkyguideLogo } from '../shared/skyguide.logo'
import { languageCodes } from '../../../../translations/translations.constants'
import { timezoneNames } from 'libs/airmap-date/constants/timezone.constants'
import { termsAndConditionsLink, privacyPolicyLink } from '../shared/privacy-policy/common'
import { TextLink, TextLinkWording } from "../../airmap/shared/config.types";
import { ReactNode } from "react";
import { PaletteOptions } from '@material-ui/core'

type LanguageCodesKeys = typeof languageCodes[keyof typeof languageCodes]

export type SkyguideSfoWhiteLabelConfig = {
  defaultUserClient: [string],
  Settings: {
    SocialMedia: TextLink,
    AppVersion: TextLink,
    Faq: TextLink,
    UserManual: TextLinkWording & {
      LinksPerLanguage: { [key: LanguageCodesKeys]: string }
    },
    Email: {
      Text: string,
      Email: string
    },
    Phone: {
      Text: string
    },
    PrivacyPolicy: TextLink,
    TermsAndConditions: TextLink,
    Support: TextLink,
    CompanyName: string,
    SupportCompanyName: string,
    SupportCompanyContact: string
  },
  PolicyContent: () => ReactNode,
  Logo: () => ReactNode,
  Palette: PaletteOptions,
  MapSettings: {
    geocoderBoundingBox: [number, number, number, number],
    availableJurisdictionUUIDs: string[]
  },
  staticTimezoneName: string
}

export const skyguideSfoWhiteLabelConfig: SkyguideSfoWhiteLabelConfig = {
  defaultUserClient: ['skyguide'],
  Settings: {
    SocialMedia: {
      Text: 'Follow @skyguide',
      Link: 'https://twitter.com/skyguide'
    },
    AppVersion: appVersion,
    Faq: {
      Text: 'FAQ',
      Link: 'https://www.skyguide.ch/services/special-flights'
    },
    UserManual: {
      Text: 'User Manual',
      wording: 'userManual',
      Link: '',
      LinksPerLanguage: {
        [languageCodes.en]: `https://api.skyguide.ch/wp-content/uploads/2021/12/SFO-Tool-Operator-User-Guide-EN.pdf`,
        [languageCodes.fr]: `https://api.skyguide.ch/wp-content/uploads/2021/12/SFO-Tool-Operator-User-Guide-FR.pdf`,
        [languageCodes.frCH]: `https://api.skyguide.ch/wp-content/uploads/2021/12/SFO-Tool-Operator-User-Guide-FR.pdf`,
        [languageCodes.de]: `https://api.skyguide.ch/wp-content/uploads/2021/12/SFO-Tool-Operator-User-Guide-DE.pdf`
      }
    },
    Email: {
      Text: 'specialflight@skyguide.ch',
      Email: 'specialflight@skyguide.ch'
    },
    Phone: {
      Text: '+41 43 931 62 36'
    },
    PrivacyPolicy: {
      Text: 'Privacy Policy',
      Link: privacyPolicyLink
    },
    TermsAndConditions: {
      Text: 'Terms and Conditions',
      Link: termsAndConditionsLink
    },
    Support: {
      Text: 'Support',
      Link: 'https://www.skyguide.ch/swiss-u-space/support'
    },
    CompanyName: 'Skyguide',
    SupportCompanyName: 'Skyguide',
    SupportCompanyContact: 'uspace-support@skyguide.ch'
  },
  PolicyContent: skyguidePrivacyPolicy,
  Logo: SkyguideLogo,
  Palette: {
    primary: {
      main: '#0057a2',
      light: '#A3B1DE',
      dark: '#002f73',
      lightText: '#959a9e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#05477F',
      light: '#05477F',
      dark: '#05477F',
      contrastText: '#fff',
    },
    surface: '#fff',
    tables: {
      main: '#abafb2',
    },
  },
  MapSettings: {
    geocoderBoundingBox: [6.02260949059, 45.7769477403, 10.4427014502, 47.8308275417],
    availableJurisdictionUUIDs: ['135e96b9-101f-4465-a78e-98247a1f3d58']
  },
  staticTimezoneName: timezoneNames.CET
}
