export const timestampFormatting = {
  DATE: 'YYYY-MM-DD',
  DATE_SLASHED: 'YYYY/MM/DD',
  DATE_SLASHED_MONTH_FIRST: 'MM/DD/YYYY',
  TIME: 'hh:mm',
  TIME_AM_PM: 'hh:mm A',
  TIME_0_23_HOURS: 'HH:mm',
  TIME_0_23_HOURS_SECONDS: 'HH:mm:ss',
  TIME_0_23_HOURS_TIMEZONE: 'HH:mm Z',
  TIME_0_23_HOURS_TIMEZONE_2: 'HH:mm ZZ',
  TIME_1_24_HOURS: 'kk:mm',
  MONTH_YEAR: 'MMMM YYYY',
  MONTH_DAY: 'MMMM D',
  MONT_DAY_YEAR: 'MMM D, YYYY',
  LOCALE_DATE: 'L',
  LOCALE_TIME: 'LT',
  UNIVERSAL_TIME: 'UTC',
  ZULU_TIME: 'Z',
  DAY_SHORT: 'ddd',
  DATE_TIME_WITHOUT_TIMEZONE: 'YYYY-MM-DDTHH:mm:ss',
  DATE_TIME: 'YYYY-MM-DDTHH:mm:ss Z',
  DATE_DAY_FIRST: 'DD-MM-YYYY'
}
