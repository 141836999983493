import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker as MuiDatePicker } from '@material-ui/pickers'
import { date_picker_wrapper, title, date_picker } from './DatePicker.module.scss'
import { formatInputLabel } from './DatePicker.helpers'
import moment from 'moment'
import { airmapIntl } from 'libs/airmap-intl'
import { DatePickerMessages } from './DatePicker.messages'

export const DatePicker = ({ handleOnChange, value, minDate, id, customTitle }) => {
  return (
    <div className={date_picker_wrapper} id={`sel-wrapper-${id}-range-picker`}>
      <h2 className={title}>{customTitle || airmapIntl.translateMessage(DatePickerMessages.date)}</h2>
      <MuiDatePicker
        disablePast
        variant="outlined"
        value={value}
        onChange={handleOnChange}
        fullWidth
        minDate={minDate || moment()}
        labelFunc={formatInputLabel({
          todayLabel: airmapIntl.translateMessage(DatePickerMessages.today),
          tomorrowLabel: airmapIntl.translateMessage(DatePickerMessages.tomorrow)
        })}
        className={date_picker}
      />
    </div>
  )
}

DatePicker.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  minDate: PropTypes.object,
  customTitle: PropTypes.string
}
