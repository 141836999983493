import React from 'react'

const Thunderstorm = () => (
  <path
    fillRule="evenodd"
    strokeWidth="2"
    d="M86.3360041,27.4192832 C83.4626678,27.4192832 80.5893308,27.7626594 77.8933309,28.6327004 C71.7800016,15.9370615 58.3000018,7.58002736 43.7439987,7.58002736 C22.8973292,7.58002736 6,23.939119 6,44.121751 C6,60.1258659 16.9613365,74.4014995 32.7826622,79.0950804 C34.2229253,79.4407769 35.655999,78.7517048 36.1999919,77.354999 C36.5546656,75.9606126 35.8429217,74.5731876 34.4026586,74.0465228 C20.7333399,70.0512941 11.3919998,57.8707196 11.3919998,44.121751 C11.3919998,26.8926183 25.9480029,12.8002736 43.7439987,12.8002736 C56.3253316,12.8002736 67.8306608,19.93229 73.0453242,30.8994473 L74.1213272,32.9829056 C74.6629241,34.198643 76.0959976,34.7229877 77.358924,34.3796115 L79.6906642,33.6812585 C81.842671,32.9829056 84.1839973,32.6395294 86.5253237,32.6395294 C98.7495862,32.6395294 108.634919,42.2099809 108.634919,54.0448591 C108.634919,65.8727769 98.5626635,75.4432287 86.3360041,75.4432287 C84.8957413,75.4432287 83.6400042,76.656646 83.6400042,78.0533519 C83.6400042,79.4500578 84.8957413,80.6634744 86.3360041,80.6634744 C101.436001,80.6634744 113.839996,68.6545882 113.839996,54.0355786 C113.839996,39.4281696 101.436001,27.4192832 86.3360041,27.4192832 Z M72.4463816,76.95834 L62.8460344,77.7878815 L67.5828502,51.7851698 L43.1333969,87.9461414 L52.7373054,87.1194799 L47.9424544,112.531411 L72.4463816,76.95834 Z"
  />
)

export default Thunderstorm
