import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const Close = ({ color, className }) => {
  return (
    <svg
      className={`${icon} ${className}`}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        fill={color}
        points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
      />
    </svg>
  )
}

Close.defaultProps = {
  color: 'currentColor',
  className: ''
}

Close.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}
