import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const ChevronRight = ({ color, className }) => {
  return (
    <svg className={`${icon} ${className}`} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon id="path-1" points="9.59 16.58 14.17 12 9.59 7.41 11 6 17 12 11 18" fill={color} />
    </svg>
  )
}

ChevronRight.defaultProps = {
  color: 'currentColor',
  className: ''
}

ChevronRight.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}
