import React from 'react'

const ScatteredThunderstormsDay = () => (
  <path
    fillRule="evenodd"
    d="M86.945111,31.6136186 C84.0142221,31.6136186 81.0833331,31.9770791 78.3333331,32.8980093 C72.0975559,19.4597953 58.3475556,10.6139535 43.5,10.6139535 C22.2357778,10.6139535 5,27.9298977 5,49.2930233 C5,66.2332277 16.1808889,81.3438511 32.3191111,86.3119628 C33.7882222,86.6778788 35.25,85.9485022 35.8048889,84.4701022 C36.1666667,82.9941584 35.4406667,81.5255817 33.9715556,80.9681117 C20.0284444,76.7392 10.5,63.8461766 10.5,49.2930233 C10.5,31.0561488 25.3475556,16.1395349 43.5,16.1395349 C56.3333333,16.1395349 68.069111,23.688707 73.3882221,35.2973396 L74.4857779,37.5026604 C75.0382221,38.789507 76.5,39.3445209 77.7882221,38.9810604 L80.1666669,38.2418604 C82.3617779,37.5026604 84.75,37.1392 87.1382221,37.1392 C99.6073331,37.1392 109.690667,47.2694326 109.690667,59.7965394 C109.690667,72.3162788 99.4166669,82.4465117 86.945111,82.4465117 C85.476,82.4465117 84.195111,83.7309022 84.195111,85.2093022 C84.195111,86.6877022 85.476,87.9720927 86.945111,87.9720927 C102.347556,87.9720927 115,75.2608 115,59.7867161 C115,44.3249116 102.347556,31.6136186 86.945111,31.6136186 Z M66.3279572,79.3618903 C65.2304014,79.3618903 64.4946234,79.642337 64.4946234,80.060677 L64.4946234,98.927911 C64.4946234,99.346251 65.2304014,99.626697 66.3279572,99.626697 C67.4255124,99.626697 68.1612903,99.346251 68.1612903,98.927911 L68.1612903,80.060677 C68.1612903,79.642337 67.4255124,79.3618903 66.3279572,79.3618903 Z M74.8440863,86.4586645 C73.7465304,86.4586645 73.0107525,86.7391112 73.0107525,87.1574512 L73.0107525,106.024685 C73.0107525,106.443025 73.7465304,106.723471 74.8440863,106.723471 C75.9416414,106.723471 76.6774194,106.443025 76.6774194,106.024685 L76.6774194,87.1574512 C76.6774194,86.7391112 75.9416414,86.4586645 74.8440863,86.4586645 Z M58.1549453,90.5081668 L53.1268815,90.9590107 L55.6077306,76.8269126 L42.8026301,96.4798781 L47.8325589,96.0305997 L45.3213148,109.841617 L58.1549453,90.5081668 Z"
  />
)

export default ScatteredThunderstormsDay
