import * as types from './operation-creation.types'
import { updateScopeFieldValue, validateUpdatedScopes } from '../../core/store/core.helpers'

const initialState = {
  currentCreationScopes: [],
  currentOperationName: '',
  operationId: '',
  isProcessingCreation: false,
  isFromEvaluationProcess: false,
  isCreationFinished: false,
  operationGeometry: {},
  justCreatedOperation: false,
  parentOperationReferenceId: '',
  operationType: '',
  operationWorkflow: '',
  currentUploadedFiles: {},
  operationError: undefined,
  operationErrorDetails: undefined,
  isLateAnnouncementEnabled: false
}

export const operationCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_CREATION_SCOPES:
      return {
        ...state,
        currentCreationScopes: action.scopes
      }
    case types.SET_OPERATION_ID:
      return {
        ...state,
        operationId: action.operationId
      }
    case types.SET_IS_PROCESSING_CREATION:
      return {
        ...state,
        isProcessingCreation: action.status
      }
    case types.SET_JUST_CREATED_OPERATION:
      return {
        ...state,
        justCreatedOperation: action.status
      }
    case types.SET_IS_CREATION_FINISHED:
      return {
        ...state,
        justCreatedOperation: false,
        isCreationFinished: true
      }
    case types.SET_IS_FROM_EVALUATION_PROCESS:
      return {
        ...state,
        isFromEvaluationProcess: action.status
      }
    case types.UPDATE_SCOPE_FIELD_VALUE:
      return {
        ...state,
        currentCreationScopes: validateUpdatedScopes(updateScopeFieldValue(state.currentCreationScopes, action))
      }
    case types.SET_OPERATION_GEOMETRY:
      return {
        ...state,
        operationGeometry: action.geometry
      }
    case types.SET_PARENT_OPERATION_REFERENCE_ID:
      return {
        ...state,
        parentOperationReferenceId: action.referenceId
      }
    case types.SET_OPERATION_TYPE:
      return {
        ...state,
        operationType: action.operationType
      }
    case types.SET_CURRENT_OPERATION_NAME:
      return {
        ...state,
        currentOperationName: action.name
      }
    case types.SET_OPERATION_WORKFLOW:
      return {
        ...state,
        operationWorkflow: action.operationWorkflow
      }
    case types.SET_OPERATION_ERROR:
      return {
        ...state,
        operationError: action.operationError,
        operationErrorDetails: action.operationErrorDetails
      }
    case types.PURGE_OPERATION_CREATION:
      return initialState
    case types.SET_IS_LATE_ANNOUNCEMENT_ENABLED:
      return {
        ...state,
        isLateAnnouncementEnabled: action.allowLateAnnouncement
      }
    default:
      return state
  }
}
