import React from 'react'

const ScatteredSnowShowersDay = () => (
  <g fillRule="evenodd">
    <path
      strokeWidth="2"
      d="M86.945111,32.6136186 C84.0142221,32.6136186 81.0833331,32.9770791 78.3333331,33.8980093 C72.0975559,20.4597953 58.3475556,11.6139535 43.5,11.6139535 C22.2357778,11.6139535 5,28.9298977 5,50.2930233 C5,67.2332277 16.1808889,82.3438511 32.3191111,87.3119628 C33.7882222,87.6778788 35.25,86.9485022 35.8048889,85.4701022 C36.1666667,83.9941584 35.4406667,82.5255817 33.9715556,81.9681117 C20.0284444,77.7392 10.5,64.8461766 10.5,50.2930233 C10.5,32.0561488 25.3475556,17.1395349 43.5,17.1395349 C56.3333333,17.1395349 68.069111,24.688707 73.3882221,36.2973396 L74.4857779,38.5026604 C75.0382221,39.789507 76.5,40.3445209 77.7882221,39.9810604 L80.1666669,39.2418604 C82.3617779,38.5026604 84.75,38.1392 87.1382221,38.1392 C99.6073331,38.1392 109.690667,48.2694326 109.690667,60.7965394 C109.690667,73.3162788 99.4166669,83.4465117 86.945111,83.4465117 C85.476,83.4465117 84.195111,84.7309022 84.195111,86.2093022 C84.195111,87.6877022 85.476,88.9720927 86.945111,88.9720927 C102.347556,88.9720927 115,76.2608 115,60.7867161 C115,45.3249116 102.347556,32.6136186 86.945111,32.6136186 L86.945111,32.6136186 Z"
    />
    <polygon points="81.069 102.692 80.593 101.869 79.035 102.77 76.2 101.133 79.035 99.497 80.592 100.396 81.067 99.575 79.984 98.949 81.636 97.995 81.161 97.173 79.507 98.127 79.507 96.872 78.558 96.872 78.558 98.676 75.72 100.315 75.72 97.037 77.283 96.135 76.809 95.314 75.72 95.942 75.72 94.032 74.771 94.032 74.771 95.939 73.687 95.314 73.214 96.136 74.771 97.035 74.771 100.308 71.936 98.671 71.936 96.872 70.986 96.872 70.986 98.123 69.336 97.171 68.862 97.992 70.514 98.946 69.429 99.573 69.904 100.396 71.464 99.494 74.301 101.133 71.464 102.772 69.901 101.871 69.428 102.694 70.514 103.321 68.863 104.275 69.338 105.096 70.986 104.143 70.986 105.394 71.936 105.394 71.936 103.595 74.771 101.959 74.771 105.233 73.215 106.13 73.689 106.952 74.771 106.328 74.771 108.234 75.72 108.234 75.72 106.326 76.806 106.953 77.281 106.13 75.72 105.23 75.72 101.952 78.558 103.591 78.558 105.394 79.507 105.394 79.507 104.139 81.161 105.093 81.634 104.272 79.984 103.318" />
    <polygon points="65.75 98.534 64.919 97.095 62.193 98.67 57.237 95.809 62.193 92.948 64.917 94.519 65.747 93.083 63.854 91.989 66.742 90.321 65.912 88.884 63.019 90.552 63.019 88.358 61.36 88.358 61.36 91.511 56.397 94.377 56.397 88.645 59.13 87.068 58.301 85.632 56.397 86.73 56.397 83.391 54.738 83.391 54.738 86.726 52.843 85.632 52.014 87.071 54.738 88.643 54.738 94.365 49.781 91.504 49.781 88.358 48.12 88.358 48.12 90.545 45.235 88.879 44.405 90.316 47.294 91.984 45.397 93.08 46.227 94.519 48.955 92.943 53.917 95.809 48.955 98.675 46.222 97.098 45.394 98.537 47.294 99.633 44.407 101.301 45.237 102.738 48.12 101.072 48.12 103.26 49.781 103.26 49.781 100.114 54.738 97.252 54.738 102.977 52.017 104.546 52.845 105.983 54.738 104.891 54.738 108.226 56.397 108.226 56.397 104.889 58.297 105.985 59.127 104.546 56.397 102.972 56.397 97.24 61.36 100.106 61.36 103.26 63.019 103.26 63.019 101.065 65.912 102.733 66.739 101.297 63.854 99.628" />
  </g>
)

export default ScatteredSnowShowersDay
