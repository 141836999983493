import { formatKeys } from './formatKeys.constants'
import { localizedTimeClient, localizedTimeServer } from './commons.date-format.constants'

export const fr = {
  [formatKeys.MONTH_DAY]: 'D MMMM',
  [formatKeys.MONTH_DAY_YEAR]: 'D MMM, YYYY',
  [formatKeys.FULL_MONTH_DAY_YEAR]: 'D MMMM, YYYY',
  [formatKeys.TIME]: 'HH:mm',
  [formatKeys.TIME_WITH_TZ]: 'HH:mm Z',
  [formatKeys.DATE]: 'DD-MM-YYYY',
  [formatKeys.DATE_TIME]: 'Do MMMM YYYY, HH:mm',
  [formatKeys.DATE_TIME_WITHOUT_YEAR]: 'D MMMM, HH:mm',
  [formatKeys.DATE_SLASHED]: 'DD/MM/YYYY',
  [formatKeys.DATE_SLASHED_TIME]: 'DD/MM/YYYY, HH:mm',
  [formatKeys.LOCALIZED_DATE_TIME_SERVER]: (date, time) => `${date}, ${localizedTimeServer(time)}`,
  [formatKeys.LOCALIZED_DATE_TIME_CLIENT]: (date, time) => `(${date}, ${localizedTimeClient(time)})`,
  [formatKeys.DATE_TIME_DASHED]: 'DD-MM-YYYY - HH:mm'
}
