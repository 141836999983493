import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { popover, popoverWrapper, popoverDisplayed, dropUp, portalMode } from './Popover.module.scss'
import { Portal } from '../Portal/Portal.component'

export const Popover = ({
  open,
  handleOnClose,
  openElement,
  header,
  content,
  footer,
  className,
  classNameBody,
  isDropUp,
  handleOnMouseLeave,
  shouldExecuteCloseHandler,
  id,
  portalModeEnabled,
  closeOnScroll
}) => {
  const popoverButton = useRef(null)
  const popoverDOMContentPositionData = popoverButton.current ? popoverButton.current.getBoundingClientRect() : {}
  const popoverContentPosition = {
    top: `${popoverDOMContentPositionData.y + popoverDOMContentPositionData.height}px`,
    left: `${popoverDOMContentPositionData.x}px`
  }

  const popoverContainsInteraction = event => {
    const dialog = document.querySelector('[role=dialog]')

    const doesWrapperContainInteraction = popoverButton.current && popoverButton.current.contains(event.target)
    const doesDialogContainInteraction = dialog && dialog.contains(event.target)

    return doesWrapperContainInteraction || doesDialogContainInteraction
  }

  useEffect(
    function closePopover() {
      function checkOutsideClick(event) {
        const popoverContainsClick = popoverContainsInteraction(event)

        if (!popoverContainsClick) {
          handleOnClose()
        }
      }

      function closePopoverOnScroll(event) {
        const isScrollingInInput = event.target.nodeName === 'INPUT'

        const popoverContainsScroll = popoverContainsInteraction(event)

        if (!isScrollingInInput && !popoverContainsScroll) {
          handleOnClose()
        }
      }

      if (shouldExecuteCloseHandler) {
        if (closeOnScroll) {
          document.addEventListener('scroll', closePopoverOnScroll, true)
        }
        document.addEventListener('mousedown', checkOutsideClick)

        return function removeListeners() {
          if (closeOnScroll) {
            document.removeEventListener('scroll', closePopoverOnScroll, true)
          }
          document.removeEventListener('mousedown', checkOutsideClick)
        }
      }
    },
    [handleOnClose, shouldExecuteCloseHandler, closeOnScroll]
  )

  const popoverContent = () => (
    <div
      className={`${popover} ${open ? popoverDisplayed : ''} ${isDropUp ? dropUp : ''} ${classNameBody} ${portalModeEnabled ? portalMode : ''
        }`}
      style={portalModeEnabled ? popoverContentPosition : {}}
    >
      {header}
      {content}
      {footer}
    </div>
  )

  return (
    <div
      className={`popover-wrapper ${popoverWrapper} ${className}`}
      onMouseLeave={handleOnMouseLeave}
      ref={popoverButton}
    >
      {openElement}
      {portalModeEnabled ? <Portal id={`popover-element-${id}`}>{popoverContent()}</Portal> : popoverContent()}
    </div>
  )
}

Popover.defaultProps = {
  header: null,
  footer: null,
  className: '',
  id: '',
  classNameBody: '',
  isDropUp: false,
  shouldExecuteCloseHandler: true,
  handleOnClose: () => { },
  handleOnMouseLeave: () => { },
  portalModeEnabled: false,
  closeOnScroll: true
}

Popover.propTypes = {
  open: PropTypes.bool.isRequired,
  openElement: PropTypes.node.isRequired,
  header: PropTypes.node,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
  handleOnClose: PropTypes.func,
  handleOnMouseLeave: PropTypes.func,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  isDropUp: PropTypes.bool,
  shouldExecuteCloseHandler: PropTypes.bool,
  id: PropTypes.string,
  portalModeEnabled: PropTypes.bool,
  closeOnScroll: PropTypes.bool
}
