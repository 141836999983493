import moment from 'moment'
import mtz from 'moment-timezone'
import { commons, localizedTimeClient, localizedTimeServer } from './constants/commons.date-format.constants'
import { en } from './constants/en.date-format.constants'
import { fr } from './constants/fr.date-format.constants'
import { de } from './constants/de.date-format.constants'
import { formatKeys } from './constants/formatKeys.constants'
import { timezoneNames } from './constants/timezone.constants'

export class AirmapDate {
  constructor() {
    this.defaultLocale = 'en'
    this.locale = ''
    this.commons = commons
    this.dateFormats = { en, fr, de }
    this.dateProvider = moment
    this.timezoneProvider = mtz
    this.staticTimezone = ''
  }

  // FIXME: this shouldn't by like that, we need to improve the custom formats that has decorations
  static removeDecorators(dateTime) {
    return dateTime.replace(/\(/g, '').replace(/\)/g, '')
  }

  get languageDateFormats() {
    return { ...this.commons, ...this.dateFormats[this.locale || this.defaultLocale] }
  }

  setLocale(locale) {
    this.locale = locale
  }

  setStaticTimezone(timezoneName) {
    this.staticTimezone = timezoneName

    this.dateProvider.tz.setDefault(timezoneName)
  }

  getFormat(dateFormatKey) {
    return this.languageDateFormats[dateFormatKey]
  }

  getDate({ dateFormatKey, date = new Date(), inputDateFormat = undefined, useUtc = false }) {
    const dateInstance = inputDateFormat ? this.dateProvider(date, inputDateFormat) : this.dateProvider(date)

    const dateFormat = this.languageDateFormats[dateFormatKey] || dateFormatKey

    const formattedDate = useUtc ? dateInstance.utc().format(dateFormat) : dateInstance.format(dateFormat)

    return formattedDate
  }

  getDateObject(date = new Date()) {
    return this.dateProvider(date)
  }

  getLocalDateTime({
    dateTime,
    clientDateTimeFormat = formatKeys.DATE_TIME_DASHED,
    includeTimeZone = true,
    showUtc = false
  }) {
    const timezone = this.getClientTimezone()
    const clientDate = moment.tz(dateTime, timezone.name)

    const clientDateTime = clientDate.format(this.getFormat(clientDateTimeFormat))

    const utcTime = moment.utc(dateTime).format(this.getFormat(formatKeys.TIME_023_HOURS))

    const localDateTime = includeTimeZone ? localizedTimeClient(clientDateTime) : clientDateTime
    return showUtc ? `${localDateTime} (${localizedTimeServer(utcTime)})` : localDateTime
  }

  isTimeFormatAmPm(formatKey) {
    return this.languageDateFormats[formatKey] === commons[formatKeys.TIME_AM_PM]
  }

  getDateByFormatFunction(dateFormatKey) {
    const formatFn = this.languageDateFormats[dateFormatKey]
    const parameters = Array.from(arguments).slice(1)

    return formatFn(...parameters)
  }

  formatDurationInSeconds(seconds) {
    return this.dateProvider(new Date())
      .hours(0)
      .minutes(0)
      .seconds(seconds)
      .format('H [h], m [min]')
  }

  formatLocalizedDateTime({ dateTime, showMonth = true, dateFromProvider }) {
    const serverDate = moment.tz(dateFromProvider || dateTime, timezoneNames.UTC)
    const serverDateTime = serverDate.format(this.getFormat(formatKeys.TIME_023_HOURS))
    const serverDateDay = showMonth ? serverDate.format(this.getFormat(formatKeys.MONTH_DAY)) : ''
    const severLocalizedDateTime = this.getDateByFormatFunction(
      formatKeys.LOCALIZED_DATE_TIME_SERVER,
      serverDateDay,
      serverDateTime
    )

    const timezone = this.getClientTimezone()
    const clientDate = moment.tz(dateFromProvider || dateTime, timezone.name)
    const clientTime = clientDate.format(this.getFormat(formatKeys.TIME_023_HOURS))

    const clientDay = showMonth ? clientDate.format(this.getFormat(formatKeys.MONTH_DAY)) : ''
    const clientLocalizedDateTime = this.getDateByFormatFunction(
      formatKeys.LOCALIZED_DATE_TIME_CLIENT,
      clientDay,
      clientTime
    )

    return [severLocalizedDateTime, clientLocalizedDateTime]
  }

  formatLocalizedTime(time, includeTimeZone = true) {
    const serverTime = this.dateProvider(time, formatKeys.TIME_023_HOURS).utcOffset(0)
    const serverFormattedTime = serverTime.format(this.getFormat(formatKeys.TIME_023_HOURS))
    const severLocalizedTime = includeTimeZone
      ? this.getDateByFormatFunction(formatKeys.LOCALIZED_TIME_SERVER, serverFormattedTime)
      : serverFormattedTime

    const timezone = this.getClientTimezone()
    const clientTime = moment.tz(time, timezone.name)
    const clientFormattedTime = clientTime.format(this.getFormat(formatKeys.TIME_023_HOURS))
    const clientLocalizedTime = includeTimeZone
      ? this.getDateByFormatFunction(formatKeys.LOCALIZED_TIME_CLIENT, clientFormattedTime)
      : clientFormattedTime

    return [severLocalizedTime, clientLocalizedTime]
  }

  getClientTimezone() {
    return this.timezoneProvider.tz.zone(this.staticTimezone || moment.tz.guess(true))
  }

  joinDateAndTime(dateToJoin, timeToJoin, setUtc = true) {
    const { years, months, date } = this.dateProvider(dateToJoin).toObject()
    const { hours, minutes, seconds, milliseconds } = this.dateProvider(timeToJoin).toObject()

    const dateTimeData = {
      years,
      months,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    }
    return setUtc
      ? this.dateProvider()
          .utc()
          .set(dateTimeData)
      : this.dateProvider().set(dateTimeData)
  }

  setDateTimeUnit({ dateTime, unit = 'date', value = 1 }) {
    return this.getDateObject(dateTime)
      .set(unit, value)
      .format()
  }

  getDiffBetweenDates({ fromDate, toDate = this.getDateObject(), unit = 'hours' }) {
    return this.dateProvider(fromDate).diff(this.dateProvider(toDate), unit)
  }

  getDuration({ fromDate, toDate, unit }) {
    const difference = this.getDiffBetweenDates({ fromDate, toDate, unit })
    return this.dateProvider.duration(difference)
  }

  formatInlineDate({ date }) {
    const isInlineFormat = typeof date === 'string' ? date.match(/d|h|s/g) : ''
    let formattedDate = date

    if (isInlineFormat) {
      const separatedTimesBySpaces = date
        .replace(/\+/g, ' +')
        .replace(/-/g, ' -')
        .trim()
      const separatedTimes = separatedTimesBySpaces.split(' ')

      const dateInstance = separatedTimes.reduce((finalDate, time) => {
        const timeUnit = time[time.length - 1]
        const timeWithoutUnit = time.replace(/d|h|s/g, '')

        return finalDate.add(Number(timeWithoutUnit), timeUnit)
      }, this.dateProvider())

      return dateInstance.format()
    }

    return formattedDate
  }

  getServerAndLocalDateTimeFormatted({
    dateTime,
    inputDateFormat,
    serverDateFormat = formatKeys.DATE_TIME_DASHED,
    clientDateFormat = formatKeys.DATE_TIME_DASHED,
    serverDateTimeDecorator,
    clientDateTimeDecorator
  }) {
    const dateObject = inputDateFormat ? this.dateProvider(dateTime, inputDateFormat) : this.dateProvider(dateTime)

    const serverDate = this.dateProvider.tz(dateObject, timezoneNames.UTC)
    const serverLocalizedDateRaw = this.dateProvider(serverDate).format(this.getFormat(serverDateFormat))
    const serverLocalizedDateFormatted = serverDateTimeDecorator
      ? serverDateTimeDecorator(serverLocalizedDateRaw)
      : serverLocalizedDateRaw

    const timezone = this.getClientTimezone()
    const clientDate = this.dateProvider.tz(dateObject, timezone.name)
    const clientLocalizedDateRaw = this.dateProvider(clientDate).format(this.getFormat(clientDateFormat))
    const clientLocalizedDateFormatted = clientDateTimeDecorator
      ? clientDateTimeDecorator(clientLocalizedDateRaw)
      : clientLocalizedDateRaw

    return [serverLocalizedDateFormatted, clientLocalizedDateFormatted]
  }

  getDateTimeInPastByDays({ dateTime, days = 0 }) {
    return this.getDateObject(dateTime)
      .subtract(days, 'days')
      .format()
  }

  isDateTimeBefore({ fromDate, toDate = this.getDateObject() }) {
    return this.getDateObject(fromDate).isBefore(this.getDateObject(toDate))
  }
}

export const airmapDate = new AirmapDate()
