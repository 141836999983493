import React from 'react'
import PropTypes from 'prop-types'
import { icon } from '../Icons.module.scss'

export const ArrowNext = ({ color, className }) => {
  return (
    <svg
      className={`${icon} ${className}`}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        id="path-1"
        points="19.92 10.92 19.92 12.92 7.92 12.92 13.42 18.42 12 19.84 4.08 11.92 12 4 13.42 5.42 7.92 10.92"
        fill={color}
        transform="translate(12.000000, 11.920000) rotate(180.000000) translate(-12.000000, -11.920000) "
      />
    </svg>
  )
}

ArrowNext.defaultProps = {
  color: 'currentColor'
}

ArrowNext.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}
