/*
 * Considerations about env variables in the app
 *
 * Environment variables are centralized in this file so we can use a common approach
 * on how to define and then load them.
 *
 * There are 2 types of env variables to consider:
 * 1. env variables that are replaced at compile time (such as MODE_ENV, or SHOW_WEBAPP).
 * 2. env variables that are loaded when we start the docker image, from the k8s config. Those are
 *    replaced in the .env.k8s file by @k8s{ENV_VARIABLE_NAME}
 *
 * For case #2 we have to make sure that the variables that are replaced with the @k8s notation
 * are not optimised and replaced by the minifier. To do so, we assign them using the `let` notation.
 *
 * See: https://jira-skyguide.atlassian.net/browse/SUSI-614
 *
 */

/* eslint-disable prefer-const */
export let AIRMAP_API_KEY = import.meta.env.AIRMAP_API_KEY
export let AIRMAP_SERVICES_REGISTRY_URL = import.meta.env.AIRMAP_SERVICES_REGISTRY_URL
export let API_HOST = import.meta.env.API_HOST
export let APP_ENV = import.meta.env.APP_ENV
export let APP_INSIGHTS_CONNECTION = import.meta.env.APP_INSIGHTS_CONNECTION
export let APP_MAINTENANCE_MODE = import.meta.env.APP_MAINTENANCE_MODE
export let APP_MAINTENANCE_MODE_URL = import.meta.env.APP_MAINTENANCE_MODE_URL
export let AUTH0_CLIENT_ID = import.meta.env.AUTH0_CLIENT_ID
export let AUTH_HOST = import.meta.env.AUTH_HOST
export let AUTH_REALM = import.meta.env.AUTH_REALM
export let CDN_HOST = import.meta.env.CDN_HOST
export let FILE_EXTENSIONS_SUPPORTED = import.meta.env.FILE_EXTENSIONS_SUPPORTED
export let FIREBASE_CONFIG = import.meta.env.FIREBASE_CONFIG
export let MAPBOX_ACCESS_TOKEN = import.meta.env.MAPBOX_ACCESS_TOKEN
export let MAPBOX_DRAW_STYLES_URL = import.meta.env.MAPBOX_DRAW_STYLES_URL
export let MAPBOX_GEOCODER_URL = import.meta.env.MAPBOX_GEOCODER_URL
export let MAPBOX_USERNAME = import.meta.env.MAPBOX_USERNAME
export let MAP_STYLES_VERSION = import.meta.env.MAP_STYLES_VERSION
export let NODE_ENV = import.meta.env.NODE_ENV
export let PHRASE_TRANSLATIONS_PROJECT_NAME = import.meta.env.PHRASE_TRANSLATIONS_PROJECT_NAME
export let SFO_AUTH_CLIENT_ID = import.meta.env.SFO_AUTH_CLIENT_ID
export let SFO_TIMELINE_MAX_RECORDS = import.meta.env.SFO_TIMELINE_MAX_RECORDS
export let SFO_CUSTOM_MAP_COORDINATES = import.meta.env.SFO_CUSTOM_MAP_COORDINATES
export let SHOW_DECONFLICTION = import.meta.env.SHOW_DECONFLICTION
export let SHOW_FLIGHT_PLANNING = import.meta.env.SHOW_FLIGHT_PLANNING
export let SHOW_GLOBALS_LAYERS = import.meta.env.SHOW_GLOBALS_LAYERS
export let SUPPORTED_TRANSLATIONS = import.meta.env.SUPPORTED_TRANSLATIONS
export let WEB_APP_PUBLIC_PATH = import.meta.env.WEB_APP_PUBLIC_PATH
export let WHITE_LABEL_ENV = import.meta.env.WHITE_LABEL_ENV
export let WS_OPERATIONS = import.meta.env.WS_OPERATIONS
export let WS_SYSTEM = import.meta.env.WS_SYSTEM
/*
 Experimental env values for Paleo (temporary until a better handling of urls construction)
 This is introduced to support the multi-tenancy / multi-authority sfo and the new DNS structure
 used fo swissdroneportal.ch which brings the environment at the first sub level of the domain name.
 */
export let USE_EXPERIMENTAL_URLS = import.meta.env.USE_EXPERIMENTAL_URLS
export let EXPERIMENTAL_API_HOST = import.meta.env.EXPERIMENTAL_API_HOST
export let EXPERIMENTAL_AUTH_HOST = import.meta.env.EXPERIMENTAL_AUTH_HOST
