
import settings from 'settings'
import { colors } from 'settings/constants'
import green from '@material-ui/core/colors/green'
import { createTheme } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";

const {
  sfoWhiteLabelConfig: { Palette }
} = settings

export const palette: PaletteOptions = {
  primary: Palette.primary,
  secondary: Palette.secondary,
  surface: Palette.surface,
  tables: Palette.tables
}

const theme = createTheme({
  palette,
  defaultColors: colors,
  rulesPlugin: {
    green: '#66CC33',
    yellow: '#F9E547',
    red: '#D0011B',
    blue: '#407EC9',
    navy: '#333F48',
    light_blue: '#F3FBFB',
    gray: '#999999',
    light_gray: '#E1E1E1',
    dark_orange: '#f6a517',
    border_gray: '#e4e7eb',
    neutral1: '#1f2b34',
    neutral1_opacity_05: 'rgba(31, 42, 51, 0.5)',
    neutral2: '#333f48',
    neutral2_opacity_02: 'rgba(51, 63, 72, 0.2)',
    neutral3: '#848f98',
    neutral3_opacity_05: 'rgba(132, 143, 152, 0.5)',
    neutral4: '#dadde1',
    neutral5: '#f5f6f7',
    advisories: {
      orange: '#ff9800',
      orange_opacity_02: 'rgba(255, 152, 0, 0.2)',
      red: '#e53935',
      red_opacity_02: 'rgba(229, 57, 53, 0.2)',
      green: '#03a97c',
      green_opacity_02: 'rgba(3, 169, 124, 0.2)',
      yellow: '#ffee58',
      yellow_opacity_02: 'rgba(255, 238, 88, 0.2)'
    }
  },
  connectionStatus: {
    operational: '#65c444',
    partial: '#72a6a4',
    critical: '#d9705b',
    unknown: '#ffff00'
  },
  typography: {
    fontFamily: 'Overpass, Roboto, sans-serif'
    // Roboto and sans-serif fonts are set as default in case Overpass is not being imported.
    // It's necessary, because WebApp and SFO are using the same MuiTheme
  },
  sfo: {
    blue: '#348087',
    light_gray: '#E1E1E1',
    medium_gray: '#bcbfc2',
    gray: '#8f9599',
    white: '#FFFFFF',
    green: 'green',
    red: 'red',
    section_background_light_gray: '#f5f6f7',
    active_item_light_gray: '#F4F5F5',
    turquoise: '#43a6aa',
    dark_black: 'rgb(31, 43, 52)',
    black_opacity_012: 'rgba(0, 0, 0, 0.12)',
    black_opacity_014: 'rgba(0, 0, 0, 0.14)',
    black_opacity_016: 'rgba(0, 0, 0, 0.16)',
    black_opacity_2: 'rgba(0, 0, 0, 0.2)',
    black_opacity_27: 'rgba(0, 0, 0, 0.27)',
    black_opacity_87: 'rgba(0, 0, 0, 0.87)',
    black_opacity_08: 'rgba(0, 0, 0, 0.08)',
    green_opacity_30: 'rgba(0, 128, 0, 0.3)',
    white_opacity_90: 'rgba(255, 255, 255, 0.9)',
    red_opacity_30: 'rgba(255, 0, 0, 0.3)',
    dark_red_opacity: 'rgb(189, 54, 53)',
    dark_green_opacity: 'rgb(11, 87, 11)',
    snackbar_error_red: '#d32f2f',
    muiGreen: green,
    warning_pink: '#FCEBEA',
    accept_primary_green: 'rgb(8, 117, 240)',
    neutral_gray: 'rgb(132, 143, 152)',
    success_green: 'rgb(19, 167, 125)',
    danger_red: 'rgb(229, 58, 55)',
    muted_light_gray: 'rgb(165, 173, 179)',
    primary_blue: 'rgb(28, 121, 236)',
    accept_primary_green_opacity_10: 'rgba(8, 117, 240, 0.1)',
    neutral_gray_opacity_10: 'rgba(132, 143, 152, 0.1)',
    success_green_opacity_10: 'rgba(19, 167, 125, 0.1)',
    danger_red_opacity_10: 'rgba(229, 58, 55, 0.1)',
    muted_light_gray_opacity_10: 'rgba(165, 173, 179, 0.1)',
    neutral_gray_opacity_20: 'rgba(132, 143, 152, 0.2)',
    primary_blue_opacity_10: 'rgba(28, 121, 236, 0.1)',
    primary_blue_opacity_20: 'rgba(28, 121, 236, 0.2)',
    flight_error_maroon: 'rgba(109, 0, 18,.7)'
  },
  templateStatus: {
    pending: {
      backgroundColor: '#dadde1',
      color: '#878f94'
    },
    approved: {
      backgroundColor: '#03a97c',
      color: '#ffffff'
    },
    rejected: {
      backgroundColor: '#fb5968',
      color: '#ffffff'
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:hover:not($disabled):before': {
          borderBottom: `1px solid ${palette?.primary && "main" in palette.primary && palette.primary.main}`
        }
      }
    },
    MuiInputBase: {
      root: {
        disabled: {},
        '&$disabled': {
          backgroundColor: colors.light_grey
        }
      }
    },
    MuiDataTableHeadCell: {
      root: {
        color: palette.tables?.main,
        fontSize: '1em',
        padding: '10px 20px'
      }
    },
    MuiDataTableBodyCell: {
      root: {
        whiteSpace: 'nowrap',
        padding: '10px 20px'
      }
    },
    MuiTableCell: {
      paddingNone: {
        padding: '0 !important'
      }
    },
    MuiTableSortLabel: {
      root: {
        alignItems: 'flex-start'
      }
    }
  } as Overrides
})

export default theme
