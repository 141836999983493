import { REFRESH_OPERATION_LIST } from './template-list.types'
import { LIST_OPERATION_CHANGE_EVENT } from '../constants/template-list.constants'
import { TemplateListAction } from './template-list.actions'
import { Middleware } from '@reduxjs/toolkit'

const listOperationChangeMiddleware: Middleware = () => (next) => (action: TemplateListAction) => {
  if (action.type === REFRESH_OPERATION_LIST) {
    window.dispatchEvent(LIST_OPERATION_CHANGE_EVENT)
  }
  return next(action)
}

export const templateListMiddlewares = [listOperationChangeMiddleware]
