import { Converter } from 'showdown'
import DOMPurify from 'dompurify'

import { escapeMarkdownCharacters, unescapeMarkdownCharacters } from '../helpers/markdown-converter.helpers'

class ConverterService {
  constructor() {
    this.converter = new Converter()
    this.sanitizer = DOMPurify
  }

  makeHtml = markdownText => {
    const htmlText = this.converter.makeHtml(markdownText)
    // FIXME: this is used to temporarily fix a bug with Showdown converter where it doesn't escape MD characters inside spans
    const unescapedHtmlText = unescapeMarkdownCharacters(htmlText)
    const sanitizedHtml = this.sanitizer.sanitize(unescapedHtmlText)
    return sanitizedHtml
  }

  makeMarkdown = htmlText => {
    const sanitizedHtml = this.sanitizer.sanitize(htmlText)
    // FIXME: this is used to temporarily fix a bug with Showdown converter where it doesn't escape MD characters inside spans
    const escapedAndSanitizedHtml = escapeMarkdownCharacters(sanitizedHtml)
    const sanitizedMarkdown = this.converter.makeMarkdown(escapedAndSanitizedHtml)
    return sanitizedMarkdown
  }

  makeText = htmlText => {
    const temporalDocument = new DOMParser().parseFromString(htmlText, 'text/html')

    return temporalDocument.body.textContent
  }
}

export const converterService = new ConverterService()
