import React from 'react'
import { privacyPolicyLink, termsAndConditionsLink } from "./common"

const privacyPolicyContent = () => (
  <>
    <p>
      {`To continue using our app and clicking “ACCEPT", you indicate you agree to skyguide's `}
      <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      {' and '}
      <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>
      {`. You can change your cookie settings at any time but parts of the site and services will not function correctly without them. `}
      {`If you do not agree with the policies or terms, please do not make use of the skyguide app.`}
    </p>
    <p>
      {`By operating your drone, you have to fully comply with any applicable regulations regarding the operation of a drone. `}
      {`Any content, information and data are provided without any warranty of any kind. `}
      {`To the extent permitted by applicable law, skyguide and its third party providers disclaim any liability.`}
    </p>
  </>
)

export default privacyPolicyContent
