import { configureStore } from "@reduxjs/toolkit"
import reducers from './App.reducers'
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import settings from 'settings'

// TODO: we should move the middlewares in a way to be registered on App.{id}.middleware
import { languageMiddleware } from 'projects/SFO/middlewares/language.middleware'
import { measurementMiddleware } from 'projects/SFO/middlewares/measurement.middleware'
import { appSFOMiddlewares } from 'projects/SFO/App.sfo.middlewares'

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory({ basename: settings.root }) });


export default (initialState = {}) => {
  const middlewares = [
    routerMiddleware,
    languageMiddleware,
    measurementMiddleware,
    ...appSFOMiddlewares,
  ]

  const store = configureStore({ reducer: reducers(routerReducer), preloadedState: initialState, middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(middlewares) })

  const history = createReduxHistory(store);

  return { store, history }
}

