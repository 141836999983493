export const formatKeys = {
  DATE_SLASHED: 'DATE_SLASHED',
  DATE: 'DATE',
  TIME: 'TIME',
  TIME_WITH_TZ: 'TIME_WITH_TZ',
  TIME_AM_PM: 'TIME_AM_PM',
  TIME_023_HOURS: 'TIME_023_HOURS',
  TIME_124_HOURS: 'TIME_124_HOURS',
  LOCALE_TIME: 'LOCALE_TIME',
  MONTH_YEAR: 'MONTH_YEAR',
  MONTH_DAY: 'MONTH_DAY',
  MONTH_DAY_YEAR: 'MONTH_DAY_YEAR',
  FULL_MONTH_DAY_YEAR: 'FULL_MONTH_DAY_YEAR',
  DATE_TIME: 'DATE_TIME',
  DATE_TIME_DASHED: 'DATE_TIME_DASHED',
  DEFAULT_DATE_TIME: 'DEFAULT_DATE_TIME',
  DATE_TIME_WITHOUT_TIMEZONE: 'DATE_TIME_WITHOUT_TIMEZONE',
  DATE_TIME_WITHOUT_YEAR: 'DATE_TIME_WITHOUT_YEAR',
  DATE_SLASHED_TIME: 'DATE_SLASHED_TIME',
  LOCALIZED_DATE_TIME_SERVER: 'LOCALIZED_DATE_TIME_SERVER',
  LOCALIZED_DATE_TIME_CLIENT: 'LOCALIZED_DATE_TIME_CLIENT',
  LOCALIZED_TIME_SERVER: 'LOCALIZED_TIME_SERVER',
  LOCALIZED_TIME_CLIENT: 'LOCALIZED_TIME_CLIENT'
}
