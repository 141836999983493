import moment from 'moment'
import { airmapIntl } from 'libs/airmap-intl'
import { SFOCoreMessages } from '../core.messages'

import {
  inputTypes,
  inputIDs,
  scopesIDs,
  fieldsWithoutName,
  fieldsWithInformation,
  inputTechnicalNames,
} from '../../core/constants/scopes.constants'
import { requirementTypes } from '../constants/scopes.constants'
import { getCustomScopeForAttachedFiles } from '../helpers/fields-grouping.helpers'
import { roleAccessibility } from '../../auth/services/roles.service'
import { isValidPhoneNumber } from '../helpers/scopes.helpers'
const {
  DATE_FROM,
  DATE_TO,
  ALTITUDE_MINIMUM,
  ALTITUDE_MAXIMUM,
  END_TIME,
  START_TIME,
  ACTIVITY_START_TIME,
  ACTIVITY_END_TIME,
} = inputIDs
const {
  STRING,
  STRING_ENUMERATION,
  MULTILINE_STRING,
  MULTIPLE_STRING_ENUMERATION,
  DYNAMIC_STRING_ENUMERATION,
  DYNAMIC_MULTIPLE_STRING_ENUMERATION,
  BOOLEAN,
  INTEGER,
  FLOATING_POINT,
  DATE,
  DATETIME,
  TIME,
  GROUP,
  DURATION,
  PHONE_NUMBER,
} = inputTypes

const createDefaultFieldValues = (field) => {
  const {
    details: { values, minimum, fields, default: defaultFieldValue },
    type: fieldType,
  } = field

  const isString = [STRING, MULTILINE_STRING].includes(fieldType)
  const isBoolean = BOOLEAN === fieldType
  const hasMinimumValue = [INTEGER, FLOATING_POINT, DATE, DATETIME, DURATION].includes(fieldType)
  const isNumberType = [INTEGER, FLOATING_POINT, DURATION].includes(fieldType)
  const isTypeGroup = fieldType === GROUP
  const isTimeStamp = fieldType === DATE || fieldType === DATETIME || fieldType === TIME
  const isEnumerator = [STRING_ENUMERATION, DYNAMIC_STRING_ENUMERATION].includes(fieldType)
  const isMultiEnumerator = [DYNAMIC_MULTIPLE_STRING_ENUMERATION, MULTIPLE_STRING_ENUMERATION].includes(fieldType)

  let inputValue
  let groupedFields

  if (isString) {
    inputValue = values || ''
  } else if (isBoolean) {
    inputValue = values || false
  } else if (isEnumerator) {
    inputValue = ''
  } else if (isMultiEnumerator) {
    inputValue = []
  } else if (isNumberType && hasMinimumValue) {
    inputValue = defaultFieldValue || minimum || 0
  } else if (isTimeStamp) {
    inputValue = defaultFieldValue || minimum || moment().format()
  } else if (isTypeGroup) {
    groupedFields = fields.map(createDefaultFieldValues)
  }

  if (groupedFields) {
    return { ...field, details: { ...field.details, fields: groupedFields } }
  } else {
    return { ...field, inputValue }
  }
}

export const formatScopes = (scopes) =>
  scopes
    .filter((scopes) => Boolean(scopes.fields))
    .map((scope) => ({
      ...scope,
      fields: scope.fields.map(createDefaultFieldValues),
    }))

const setFieldValueById = ({ fields, fieldId, value }, options) =>
  fields.map((field) => {
    const matchFieldId = fieldId === field.id || fieldId === field.field_descriptor_id
    const isGroupType = field.type === GROUP
    if (matchFieldId) {
      return {
        ...field,
        ...options,
        value: value === undefined ? field.value : value,
        inputValue: value === undefined ? field.inputValue : value,
      }
    } else if (isGroupType) {
      return {
        ...field,
        value: setFieldValueById(
          {
            fields: field.details.fields,
            fieldId,
            value,
          },
          options
        ),
        details: {
          ...field.details,
          fields: setFieldValueById(
            {
              fields: field.details.fields,
              fieldId,
              value,
            },
            options
          ),
        },
      }
    }

    return field
  })

export const updateScopeFieldValue = (scopes, { scopeId, fieldId, value }, options = {}) =>
  scopes.map((scope) => {
    const matchScopeId = scopeId === scope.id || scopeId === scope.scope_descriptor_id
    if (matchScopeId) {
      return {
        ...scope,
        fields: setFieldValueById({ fields: scope.fields, fieldId, value }, options),
      }
    }
    return scope
  })

export const validateUpdatedScopes = (updatedScopes) =>
  updatedScopes.map((scope) =>
    scope.id === scopesIDs.SCHEDULE ||
    scope.id === scopesIDs.EVALUATION ||
    scope.scope_descriptor_id === scopesIDs.SCHEDULE ||
    scope.scope_descriptor_id === scopesIDs.APPLICANT
      ? {
          ...scope,
          fields: validateFields(scope.fields),
        }
      : scope
  )

const isDurationbetweenTimes = (dateFrom, dateTo, maxLimitDuration) => {
  const limitDuration = moment.duration(dateTo.diff(dateFrom)).asSeconds()

  return Boolean(maxLimitDuration <= limitDuration)
}

function validateFields(fields) {
  return fields.map((field) => {
    if (field.id === DATE_TO || field.field_descriptor_id === inputIDs.ACTIVITY_END_TIME) {
      let isMore24hours = false
      let errorMessage = ''

      const dateToValue = field.inputValue

      const dateFromField = fields.find(
        (field) => field.id === DATE_FROM || field.field_descriptor_id === inputIDs.ACTIVITY_START_TIME
      )
      const dateFromValue = dateFromField.inputValue
      const isDateToAfterDateFrom = dateFromValue ? moment(dateToValue).isSameOrAfter(dateFromValue) : true

      if (field.id != DATE_TO)
        isMore24hours = moment.duration(moment(dateToValue).diff(moment(dateFromValue))).asHours() > 24

      if (!isDateToAfterDateFrom) errorMessage = airmapIntl.translateMessage(SFOCoreMessages.date_to_error)
      else if (isMore24hours) errorMessage = airmapIntl.translateMessage(SFOCoreMessages.activity_limit_24_hours_error)

      return {
        ...field,
        errorMessage: errorMessage,
        hasValidation: true,
      }
    } else if (field.id === ALTITUDE_MINIMUM) {
      const notEmpty = field.inputValue.toString().length > 0
      const altitudeMinimumValue = Number(field.inputValue)
      const altitudeMaximumField = fields.find((field) => field.id === ALTITUDE_MAXIMUM)
      const altitudeMaximumValue = altitudeMaximumField && Number(altitudeMaximumField.inputValue)

      const isAltitudeMinimumValidated = altitudeMaximumValue
        ? notEmpty && altitudeMinimumValue <= altitudeMaximumValue && altitudeMinimumValue >= 0
        : false

      return {
        ...field,
        hasValidation: true,
        validation: isAltitudeMinimumValidated,
      }
    } else if (field.id === inputIDs.DURATION || field.field_descriptor_id === inputIDs.DURATION) {
      const startTimeField = fields.find((field) => [field.id, field.field_descriptor_id].includes(START_TIME))
      const endTimeField = fields.find((field) => [field.id, field.field_descriptor_id].includes(END_TIME))

      const activityStartTimeField = fields.find((field) => field.field_descriptor_id === ACTIVITY_START_TIME)
      const activityEndTimeField = fields.find((field) => field.field_descriptor_id === ACTIVITY_END_TIME)

      const maxLimitDuration = fields.find((field) => [field.id, field.field_descriptor_id].includes(inputIDs.DURATION))
      const maxLimitDurationValue = maxLimitDuration.inputValue

      const hasActivityFields = activityStartTimeField && activityEndTimeField

      let isMaxLimitValidated = false

      if (hasActivityFields) {
        const startTimeValue = moment(activityStartTimeField.inputValue)
        const endTimeValue = moment(activityEndTimeField.inputValue)

        isMaxLimitValidated = isDurationbetweenTimes(startTimeValue, endTimeValue, maxLimitDurationValue)
      } else {
        const startTimeValue = moment(startTimeField.inputValue)
        const endTimeValue = moment(endTimeField.inputValue)

        endTimeValue.set({ year: startTimeValue.year(), month: startTimeValue.month(), date: startTimeValue.date() })
        if (
          startTimeValue.hour() > endTimeValue.hour() ||
          (startTimeValue.hour() == endTimeValue.hour() && startTimeValue.minute() > endTimeValue.minute())
        )
          endTimeValue.add(1, 'day')

        isMaxLimitValidated = isDurationbetweenTimes(startTimeValue, endTimeValue, maxLimitDurationValue)
      }

      return {
        ...field,
        hasValidation: true,
        errorMessage: isMaxLimitValidated ? '' : airmapIntl.translateMessage(SFOCoreMessages.max_duration_error),
      }
    } else if (field.type === PHONE_NUMBER) {
      const isNotValidPhoneNumber = field.inputValue.length >= 3 ? !isValidPhoneNumber(field.inputValue) : false

      return {
        ...field,
        errorMessage: isNotValidPhoneNumber ? airmapIntl.translateMessage(SFOCoreMessages.phone_number_error) : '',
        hasValidation: true,
      }
    } else if (field.type === GROUP) {
      return {
        ...field,
        details: {
          ...field.details,
          fields: validateFields(field.details.fields),
        },
      }
    } else {
      return field
    }
  })
}

export function applyDefaultFieldsValidations({
  inputValue,
  type,
  details: { minimum, maximum, fields },
  hasValidation,
  validation,
}) {
  const isIntegerType = type === INTEGER
  const isFloatingPointType = type === FLOATING_POINT
  const isGroupType = type === GROUP
  const isBooleanType = type === BOOLEAN

  if (isIntegerType || isFloatingPointType) {
    const notEmpty = inputValue.toString().length > 0
    const hasMinimum = typeof minimum != 'undefined'
    const isMinimumValid = !hasMinimum || Number(inputValue) >= minimum
    const isMaximumValid = !maximum || Number(inputValue) <= maximum
    const isValueValid = hasMinimum || Number(inputValue) > 0

    const isFieldValid = notEmpty && isValueValid && isMinimumValid && isMaximumValid

    return hasValidation ? validation : isFieldValid
  } else if (isBooleanType) {
    return typeof inputValue === 'boolean'
  } else if (isGroupType) {
    return fields.every(applyDefaultFieldsValidations)
  } else {
    return Boolean(inputValue)
  }
}

export const tagScopesDisabled = (scopes, status) =>
  scopes.map((scope) => ({ ...scope, fields: tagFieldsIsDisabled(scope.fields, status) }))

const tagFieldsIsDisabled = (fields, status) =>
  fields.map((field) => {
    if (field.type === GROUP)
      return {
        ...field,
        details: {
          ...field.details,
          fields: tagFieldsIsDisabled(field.details.fields, status),
        },
      }
    return { ...field, disabled: status }
  })

export const getNotIncludedFieldsFromScopesArr = (scopes) =>
  scopes.map((scope) => ({
    ...scope,
    field_descriptors: scope.field_descriptors.filter((field) => !field.included),
  }))

export const getOptionalFieldsFromScopesArr = (scopes) => {
  scopes = scopes || []

  return scopes.map((scope) => ({
    ...scope,
    field_descriptors: scope.field_descriptors.filter((field) => field.requirement_type === requirementTypes.OPTIONAL),
  }))
}

export const getCompatibleFields = (scopes) => {
  scopes = scopes || []

  return scopes.map((scope) => ({
    ...scope,
    field_descriptors: scope.field_descriptors.filter(
      (field) => field.requirement_type !== requirementTypes.INCOMPATIBLE
    ),
  }))
}

export const getFilteredCompatibleFields = (scopes, operationType) => {
  scopes = scopes || []

  return scopes.map((scope) => ({
    ...scope,
    fields: scope.fields.filter((field) => {
      const fieldConditions = field?.requirement?.conditions
      if (fieldConditions?.length > 1) {
        const isCompatible = fieldConditions.reduce((isCurrentlyCompatible, condition) => {
          if (condition.type === 'contextual' && condition.result === 'incompatible') {
            return isCurrentlyCompatible && condition.value?.context !== operationType
          }
          return isCurrentlyCompatible
        }, true)
        return isCompatible
      }
      return true
    }),
  }))
}

const getFieldsById = (idsToSearch, fields) => {
  return fields.reduce((fieldsFound, field) => {
    const isSearched = idsToSearch.includes(field.id)

    if (field.type === 'group' && !isSearched) {
      const fieldsMatched = getFieldsById(idsToSearch, field.details.fields)

      return fieldsMatched.length ? [...fieldsFound, ...fieldsMatched] : fieldsFound
    }

    return isSearched ? [...fieldsFound, field] : fieldsFound
  }, [])
}

export const getScopeFieldsById = (idsToSearch, scopes, shouldKeepScopes = false) => {
  return scopes.reduce((filteredData, scope) => {
    const newFieldsFound = getFieldsById(idsToSearch, scope.fields)
    const hasFieldFound = Boolean(newFieldsFound.length)

    if (!hasFieldFound) {
      return filteredData
    }

    return shouldKeepScopes
      ? [...filteredData, { ...scope, fields: newFieldsFound }]
      : [...filteredData, ...newFieldsFound]
  }, [])
}

const getParsedFields = (fields) => {
  const newFieldTypes = roleAccessibility.roleConfiguration.customFieldTypes

  return Object.keys(newFieldTypes).length
    ? fields.map((field) => {
        const newFieldType = newFieldTypes[field.field_descriptor_id]
        return newFieldType ? { ...field, type: newFieldType } : field
      })
    : fields
}

const getFieldsByTechnicalName = (technicalNamesToSearch, fields, scopeIdFromField) => {
  return fields.reduce((fieldsFound, field) => {
    const isSearched = technicalNamesToSearch.some((technicalName) => field.technical_name?.includes(technicalName))
    const shouldHideName = fieldsWithoutName.includes(field.technical_name)
    const shouldHideInfo = fieldsWithInformation.includes(field.technical_name)
    const formattedField = { ...field, field_descriptor_id: field.field_descriptor_id || field.id }

    if (formattedField.type === inputTypes.GROUP && !isSearched) {
      const fieldsMatched = getFieldsByTechnicalName(
        technicalNamesToSearch,
        formattedField.details.fields,
        scopeIdFromField
      )

      return fieldsMatched.length ? [...fieldsFound, ...fieldsMatched] : fieldsFound
    }

    const infoField = !shouldHideInfo ? { question: '' } : {}
    const nameField = shouldHideName ? { name: '', requirement: { type: requirementTypes.OPTIONAL } } : {}

    return isSearched
      ? [...fieldsFound, { ...formattedField, ...infoField, ...nameField, scopeIdFromField }]
      : fieldsFound
  }, [])
}

export const getScopeFieldsByTechnicalName = (
  technicalNamesToSearch,
  scopes,
  shouldKeepScopes = false,
  hasStyledFileFields = false
) => {
  return scopes.reduce((filteredData, scope) => {
    const newFieldsFound = getFieldsByTechnicalName(technicalNamesToSearch, scope.fields, scope.id)
    const hasFieldFound = Boolean(newFieldsFound.length)

    const isAttachedFiles = technicalNamesToSearch.includes(inputTechnicalNames.ADDITIONAL_FILES)

    if (!hasFieldFound) {
      return filteredData
    }

    const parsedFields = getParsedFields(newFieldsFound)

    if (isAttachedFiles && hasStyledFileFields) {
      return shouldKeepScopes
        ? [...filteredData, { ...scope, fields: parsedFields }]
        : [...filteredData, { ...getCustomScopeForAttachedFiles(parsedFields) }]
    }

    return shouldKeepScopes ? [...filteredData, { ...scope, fields: parsedFields }] : [...filteredData, ...parsedFields]
  }, [])
}
